import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const PageBannerStyled = styled.div`
  padding: 2em;

  .ant-alert-with-description {
    align-items: center !important;
  }

  .ant-alert-action {
    display: flex;
    align-items: center; /* Vertically center the button */
    height: 100%; /* Ensure the action container takes full height */
  }

  .action-container {
    width: 100%; /* Ensure the button takes full width of its container */
    padding: 1em;
  }
`

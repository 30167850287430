import {
  CloudUploadOutlined,
  FileAddOutlined,
  PlusOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Modal,
  Pagination,
  Typography,
  Divider,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { isValid } from 'date-fns'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'

import {
  useGetMeQuery,
  useGetProductsQuery,
  useGetUploadedDocumentsQuery,
} from '../../graphql/_generated-hooks'
import { isLimitReached, getDistributorSlug } from '../../utils'
import { DEMO_UPLOAD_PAYLOAD } from '../../config/default'
import { PageTitle } from '../../components/PageTitle'
import { MaterialsTable } from '../../components/MaterialsTable'

import { MaterialServiceFormDrawer } from '../../components/MaterialServiceFormDrawer'
import { MaterialFormDrawer } from '../../components/MaterialFormDrawer'
import { DistributorCreateFormDrawer } from '../../components/DistributorCreateFormDrawer'
import { CategoryCreateFormDrawer } from '../../components/CategoryCreateFormDrawer'

import { UploadEmptyState } from '../../components/UploadEmptyState'

import { SkeletonLoader } from '../../components/SkeletonLoader'
import { RecentUploadsTable } from '../../components/RecentUploadsTable'
import { LockableButton } from '../../components/LockableButton'
import { CategoryTableFilter } from '../../components/CategoryTableFilter'
import { TableFilterButton } from '../../components/TableFilterButton'
import { MaterialsViewStyled } from './styles'
import { on } from 'events'

const { Search } = Input
const defaultPageSize = 15
const lockReason =
  'You have reached your material limit on this plan. Upgrade to create more materials.'

export interface MaterialsViewProps {
  me?: any
}

export interface ItemSchema {
  name?: string
  type?: string
  sku?: string
  quantity?: number
  price?: number
  total?: 0
}
export interface MaterialFormSchema {
  /** if `id` exist, we are editing */
  id?: string
  title?: string
  number?: string
  customer?: string
  items?: ItemSchema[]
}

const viewTabs = ['materials', 'lists']
let defaultActiveTabKey = viewTabs[0]

const formatUploadResponse = (
  { id, analyzed_output, file_url, mime_type }: any,
  sas_token: string,
  matchedExistingDistributor: any,
  matchedExistingProducts: any = {},
  IS_DEMO: boolean
) => {
  const analyzedDataItems = analyzed_output[0].items
  const analyzedDataVendorName = analyzed_output[0].vendorName
  const analyzedDataInvoiceDate = analyzed_output[0].invoiceDate?.content
  const analyzedInvoiceId = analyzed_output[0].invoiceId?.value || ''
  const analyzedPOId = analyzed_output[0].purchaseOrder?.value || ''

  return {
    id,
    file_url: `${file_url}?${sas_token}`,
    mime_type,
    invoice_number: analyzedInvoiceId,
    purchase_order_number: analyzedPOId,
    vendor_slug: getDistributorSlug(analyzedDataVendorName?.value),
    vendor: analyzedDataVendorName.value,
    date: isValid(new Date(analyzedDataInvoiceDate))
      ? analyzedDataInvoiceDate
      : new Date(),
    items: analyzedDataItems?.values,
    existingDistributor: matchedExistingDistributor?.id,
    matchedExistingProducts,
    IS_DEMO,
  }
}

export const MaterialsView = React.forwardRef(
  ({ me, ...props }: MaterialsViewProps, onboardingStepRef: any) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const categoryParams = searchParams.getAll('category')
    const searchProductParam = searchParams.get('search')
    const sortFieldParam = searchParams.get('sortField')
    const sortOrderParam = searchParams.get('sortOrder')

    const [createMaterials, setCreateMaterials] = React.useState(false)
    const [createMaterial, setCreateMaterial] = React.useState(false)
    const [createDistributor, setCreateDistributor] = React.useState(false)
    const [createCategory, setCreateCategory] = React.useState(false)
    const [createdDistributor, setCreatedDistributor] = React.useState(null)
    const [createdCategory, setCreatedCategory] = React.useState(null)
    const [processingUpload, setProcessingUpload] = React.useState(false)
    const [showUploadModal, setShowUploadModal] = React.useState(false)
    const [showImportModal, setShowImportModal] = React.useState(false)
    const [uploadedData, setUploadedData] = React.useState<any>(null)
    const [searchQuery, setSearchQuery] =
      React.useState<any>(searchProductParam)
    const [filterQuery, setFilterQuery] = React.useState<any>(
      categoryParams.length
        ? [
            {
              field: 'category',
              value: categoryParams[0],
              label: categoryParams[1],
            },
          ]
        : []
    )
    const [sortQuery, setSortQuery] = React.useState<{
      field?: string | null
      order?: string | null
    }>(
      sortFieldParam
        ? {
            field: sortFieldParam,
            order: sortOrderParam,
          }
        : {}
    )
    const [productData, setProductData] = React.useState<any>([])
    const [currentPagination, setCurrentPagination] = React.useState<any>(1)
    const [totalProducts, setTotalProducts] = React.useState<any>(0)

    const navigate = useNavigate()
    const onboardingStepRefUpload = React.useRef(null)
    const onboardingStepRefCreate = React.useRef(null)
    const materialUsage = useAppStore((state: any) => state.materials)
    const activePlan = useAppStore((state: any) => state.plan)

    React.useImperativeHandle(onboardingStepRef, () => ({
      get upload() {
        return onboardingStepRefUpload.current
      },
      get create() {
        return onboardingStepRefCreate.current
      },
    }))

    // Get Products
    const {
      data: { products = { data: [], count: 0 } } = {
        products: { data: [], count: 0 },
      },
      loading: isLoadingProducts,
      refetch: refetchProducts,
      updateQuery: updateProducts,
    } = useGetProductsQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        // @ts-ignore
        input: {
          limit: defaultPageSize,
          ...(searchQuery && {
            search: searchQuery,
          }),
          ...(filterQuery.length && {
            filter: {
              field: 'category',
              value: filterQuery[0].value,
            },
          }),
          ...(sortFieldParam && {
            sort: {
              field: sortFieldParam,
              order: 'ascend' == sortOrderParam ? 'desc' : 'asc',
            },
          }),
          // offset: 0,
        },
      },
      onCompleted: ({ products }) => {
        setProductData(products?.data || [])
        setTotalProducts(products?.count || 0)
      },
    })

    // Get Documents
    const {
      data: { uploadedDocuments = { data: [], count: 0 } } = {
        uploadedDocuments: { data: [], count: 0 },
      },
      loading: isLoadingDocuments,
      updateQuery: updateDocumentsQuery,
    } = useGetUploadedDocumentsQuery({
      variables: {
        input: {
          limit: 5,
          // offset: 0,
        },
      },
    })

    // Get Product Lists
    // TODO refactor lists into their own gql file on api -- key clashing since it is the same file
    // const {
    //   data: { productLists = [] } = { productLists: [] },
    //   loading: isLoadingProductLists,
    //   refetch: refetchProductLists,
    //   updateQuery: updateProductLists,
    // } = useGetProductListsQuery({
    //   variables: {},
    // })

    const onFileUploaderChange = (data: any) => {
      const { status, response } = data.file

      switch (status) {
        case 'uploading':
          setProcessingUpload(true)
          break
        case 'done':
          setProcessingUpload(false)
          handleUploadModalClose() // Close modal
          // updateDocumentsQuery(({ uploadedDocuments }: any) => {
          //   const { count, data } = uploadedDocuments

          //   console.log(
          //     'count ',
          //     count,
          //     'data',
          //     data,
          //     'uploa d ',
          //     response.uploadedDocument
          //   )
          //   return {
          //     uploadedDocuments: {
          //       count: count + 1,
          //       data: [response.uploadedDocument, ...data],
          //     },
          //   }
          // })
          setUploadedData(
            formatUploadResponse(
              response.uploadedDocument,
              response.sas_token,
              response.matchedExistingDistributor,
              response.matchedExistingProducts,
              response.IS_DEMO
            )
          )
          setCreateMaterials(true) // Open Drawer
          break
        case 'error':
          setProcessingUpload(false)
          break
        default:
          break
      }

      // Get whole new object
      // Pull out alayzed_output
      // Set it to state
      // OPTIONAL: set query param for upload id to support reload
      // OPTIONAL: on reload, query for file upload
      // Open modal
      // On modal close, reset state
      // console.log('file', status, response)
    }

    const handleAddNewMaterial = () => {
      setCreateMaterial(true)
    }
    const handleMaterialFormClose = () => {
      setCreateMaterials(false)
      setCreateMaterial(false)
    }
    const handleDistributorFormClose = () => {
      setCreateDistributor(false)
    }
    const handleCategoryFormClose = () => {
      setCreateCategory(false)
    }

    const handleUploadModalOpen = () => {
      setShowUploadModal(true)
    }
    const handleUploadModalClose = () => {
      setShowUploadModal(false)
    }

    const handleImportModalOpen = () => {
      setShowImportModal(true)
    }
    const handleImportModalClose = () => {
      setShowImportModal(false)
    }

    const onMaterialServiceSave = () => {
      setCreateMaterial(false)
      setCreatedDistributor(null)
      refetchProducts()
    }
    const onDistributorSave = (distributor: any) => {
      setCreatedDistributor(distributor)
      setCreateDistributor(false)
    }
    const onCategorySave = (category: any) => {
      setCreatedCategory(category)
      setCreateCategory(false)
    }

    const onSearchFilter = (
      search?: string,
      filter?: { field: string; value: string; label?: string },
      sort?: { field: string; order: string }
    ) => {
      const newParams: any = {}

      setSearchQuery(search)
      setFilterQuery(filter && filter.value ? [filter] : [])
      setCurrentPagination(1)
      setSortQuery({ field: 'description', order: null })

      // Add the search parameter if it exists
      if (search) {
        newParams.search = search
      }

      // Add the filter parameter if it exists and has a value
      if (filter && filter.value) {
        newParams[filter.field] = [filter.value, filter.label]
      }

      // Set the new search parameters
      setSearchParams(newParams)
    }
    const onSort = (sort: { field: string; order: string }) => {
      const { field, order } = sort
      setSortQuery(sort)
      setCurrentPagination(1)

      // TODO set limit/offset back to 0
      refetchProducts({
        // @ts-ignore
        input: {
          limit: defaultPageSize,
          ...(searchQuery && {
            search: searchQuery,
          }),
          ...(filterQuery[0] && {
            filter: {
              field: 'category',
              value: filterQuery[0].value,
            },
          }),
          ...(order && {
            sort: {
              field,
              order: 'ascend' == order ? 'desc' : 'asc',
            },
          }),
        },
      })
    }
    const onPageChange = (page: number, pageSize?: number) => {
      setCurrentPagination(page)
      refetchProducts({
        input: {
          limit: pageSize,
          offset: page * pageSize! - pageSize!,
          ...(searchQuery && {
            search: searchQuery,
          }),
          ...(filterQuery[0] && {
            filter: {
              field: 'category',
              value: filterQuery[0].value,
            },
          }),
          ...(sortQuery && {
            sort: {
              field: sortQuery.field,
              order: 'ascend' == sortQuery.order ? 'desc' : 'asc',
            },
          }),
        },
      })
    }

    const handleRowClick = (row: any, event: any) => {
      event.preventDefault()
      navigate(`/materials/${row.id}`, {})
    }

    const handleSampleUploadClick = () => {
      setProcessingUpload(true)
      setTimeout(() => {
        setProcessingUpload(false)
        onFileUploaderChange(DEMO_UPLOAD_PAYLOAD)
      }, 1500)
    }

    const handleShouldLockCreateMaterial = () => {
      return isLimitReached(
        materialUsage,
        me?.organization?.materialLimit,
        activePlan
      )
    }

    return (
      <MaterialsViewStyled>
        <Content style={{ padding: '2em' }}>
          <Row align='middle'>
            <Col flex='1 0'>
              <PageTitle
                title={`Materials`}
                subtitle='Manage materials, services, and other frequently used items.'
              />
            </Col>
            <Col flex='1 0' style={{ textAlign: 'right' }}>
              <Space>
                <span ref={onboardingStepRefCreate}>
                  <LockableButton
                    locked={handleShouldLockCreateMaterial()}
                    onClick={handleAddNewMaterial}
                    reason={lockReason}
                    data-testid='add-material-button'
                  >
                    <PlusOutlined />
                    Add Item
                  </LockableButton>
                </span>
                <span ref={onboardingStepRefUpload}>
                  <LockableButton
                    locked={handleShouldLockCreateMaterial()}
                    onClick={handleUploadModalOpen}
                    reason={lockReason}
                    data-testid='upload-material-button'
                  >
                    <FileAddOutlined />
                    Scan Invoice/Receipt
                  </LockableButton>
                </span>
                <span>
                  <LockableButton
                    locked={handleShouldLockCreateMaterial()}
                    onClick={handleImportModalOpen}
                    reason={lockReason}
                    data-testid='import-material-button'
                  >
                    <CloudUploadOutlined />
                  </LockableButton>
                </span>
              </Space>
            </Col>
          </Row>
        </Content>
        <Content style={{ padding: '0 2em 2em 2em' }}>
          {/* {products === null ||
            (products?.data?.length === 0 &&
              !isLoadingProducts &&
              !searchQuery && (
                <UploadEmptyState
                  onUploadFileChange={onFileUploaderChange}
                  fileProcessing={processingUpload}
                />
              ))} */}

          <Row justify={'end'} style={{ marginBottom: '1em' }}>
            <Col span={12} offset={12}>
              <Space style={{ float: 'right' }}>
                <Search
                  id='material-search-input'
                  placeholder='Search'
                  defaultValue={searchQuery}
                  onSearch={(value) => {
                    onSearchFilter(value, filterQuery[0])
                  }}
                  onChange={debounce((e) => {
                    const value = e.target.value

                    if (value && value.length > 1) {
                      onSearchFilter(value, filterQuery[0])
                    } else if (value === '') {
                      onSearchFilter('', filterQuery[0])
                    }
                  }, 300)}
                  allowClear={true}
                  style={{ width: '350px' }}
                />

                <TableFilterButton
                  activeFilterCount={filterQuery.length}
                  content={
                    <>
                      <Typography.Text type='secondary'>
                        Category
                      </Typography.Text>
                      <CategoryTableFilter
                        width={300}
                        initialValue={{
                          value: categoryParams[0],
                          label: categoryParams[1],
                        }}
                        onChange={(changeValue: any = {}) => {
                          if (changeValue.value) {
                            onSearchFilter(searchQuery, {
                              field: 'category',
                              value: changeValue.value,
                              label: changeValue.label,
                            })
                          } else {
                            onSearchFilter(searchQuery)
                          }
                        }}
                      />
                    </>
                  }
                />
              </Space>
            </Col>
          </Row>
          <Row style={{ marginBottom: '1em' }}>
            <Col span={24}>
              <MaterialsTable
                data={productData}
                tableProps={{ loading: isLoadingProducts }}
                onMaterialClick={handleRowClick}
                onMaterialDelete={() => {}}
                onAddToList={() => {}}
                onChange={debounce((pagination, filters, sorter) => {
                  onSort(sorter)
                }, 400)}
                selectedId={''}
                controlledSort={sortQuery}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Pagination
                data-testid='materials-pagination'
                defaultCurrent={1}
                defaultPageSize={defaultPageSize}
                current={currentPagination}
                showSizeChanger={false}
                total={totalProducts}
                onChange={onPageChange}
                style={{ float: 'right' }}
              />
            </Col>
          </Row>
        </Content>

        <MaterialServiceFormDrawer
          open={createMaterials}
          onClose={handleMaterialFormClose}
          onMaterialServiceSave={onMaterialServiceSave}
          newCategory={createdCategory}
          onCreateNewCategory={() => {
            setCreateCategory(true)
          }}
          prefillData={uploadedData}
        />

        <MaterialFormDrawer
          open={createMaterial}
          mode='create'
          newDistributor={createdDistributor}
          newCategory={createdCategory}
          onCreateNewDistributor={() => {
            setCreateDistributor(true)
          }}
          onCreateNewCategory={() => {
            setCreateCategory(true)
          }}
          onSave={onMaterialServiceSave}
          onClose={handleMaterialFormClose}
        />

        {/* Order matters, this needs to stack on top of the Material create form */}
        <DistributorCreateFormDrawer
          open={createDistributor}
          mode='create'
          width={425}
          onSave={onDistributorSave}
          onClose={handleDistributorFormClose}
        />
        <CategoryCreateFormDrawer
          open={createCategory}
          mode='create'
          width={425}
          onSave={onCategorySave}
          onClose={handleCategoryFormClose}
        />

        {/* TODO move to own component */}
        <Modal
          open={showUploadModal}
          onCancel={handleUploadModalClose}
          footer={[]}
          width={700}
        >
          <div style={{ padding: '2em 1em' }}>
            {/* {!me?.organization?.livemode && (
              <Alert
                type='warning'
                message='Demo upload'
                description='You can add materials to your catalog easily by uploading an invoice or receipt from your distributor. Try it out by clicking the demo file on the right.'
                action={
                  <Space
                    direction='vertical'
                    style={{ textAlign: 'center', padding: '1em' }}
                  >
                    <Button
                      type='primary'
                      icon={<FilePdfOutlined size={32} />}
                      size='large'
                      onClick={handleSampleUploadClick}
                    />
                    <a onClick={handleSampleUploadClick}>sample.pdf</a>
                  </Space>
                }
              />
            )} */}
            <UploadEmptyState
              onUploadFileChange={onFileUploaderChange}
              fileProcessing={processingUpload}
            />
            {uploadedDocuments?.data?.length != 0 && (
              <RecentUploadsTable
                items={uploadedDocuments?.data}
                sas_token={me?.sas_token}
              />
            )}
          </div>
        </Modal>

        <Modal
          open={showImportModal}
          onCancel={handleImportModalClose}
          title='Import Material Catalog'
          footer={[]}
        >
          <div style={{ marginTop: '2em' }}>
            <Typography.Text>
              Import your existing material list from a spreadsheet or CSV file
              by emailing us at{' '}
              <a href='mailto:support@getvoltpro.com'>support@getvoltpro.com</a>
            </Typography.Text>
          </div>
        </Modal>
      </MaterialsViewStyled>
    )
  }
)

MaterialsView.displayName = 'MaterialsView'

import { Tag, Badge } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { ServiceItemBadgeStyled } from './styles'
import { capitalizeString } from '../../utils'

export interface ServiceItemBadgeProps {
  label?: string
  margin?: string
}

export const ServiceItemBadge = ({
  label = 'Labor',
  margin = '0',
  ...props
}: ServiceItemBadgeProps) => {
  return (
    <ServiceItemBadgeStyled>
      <Badge
        {...props}
        count={label}
        color={theme.colors.background}
        style={{
          margin: margin,
          borderRadius: 0,
          color: theme.colors.accent,
        }}
      />
    </ServiceItemBadgeStyled>
  )
}

ServiceItemBadge.displayName = 'ServiceItemBadge'

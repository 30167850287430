import { ClerkProvider } from '@clerk/clerk-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { theme } from '../styles/themes/default'

export const ClerkAuthProvider = ({ children }: any) => {
  const navigate = useNavigate()

  if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error('Missing Publishable Key')
  }
  const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
      appearance={{
        variables: {
          colorPrimary: theme.colors.accent,
          colorDanger: theme.colors.danger,
          colorSuccess: theme.colors.success,
          colorWarning: theme.colors.warning,
        },
      }}
    >
      {children}
    </ClerkProvider>
  )
}

import { Tag } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { LoadingOverlayStyled } from './styles'
export interface LoadingOverlayProps {
  status?: string
}

export const LoadingOverlay = ({ status = 'Default' }: LoadingOverlayProps) => {
  return (
    <LoadingOverlayStyled>
      <div className='loading-overlay-content'>
        <LoadingOutlined style={{ fontSize: 54 }} />
        <h1>Processing...</h1>
      </div>
    </LoadingOverlayStyled>
  )
}

LoadingOverlay.displayName = 'LoadingOverlay'

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const EstimatesOrderSheetsViewStyled = styled.div`
  font-size: ${rem(16)};

  .hidden-print {
    color: #ccc;

    table .ant-btn {
      color: #ccc;
    }
  }

  @media print {
    .ant-layout {
      padding: 0 !important;
    }
    .ant-layout-content {
      padding: 0 !important;
    }
    .ant-table-cell {
      background: #fff !important;
    }
    .ant-table-column-sorter {
      display: none;
    }
    .full-page-div {
      min-height: 9.5in; /* Adjust based on the actual page size and required margins */
      width: 100%;
      page-break-after: always; /* Ensures a page break after the div if there is more content */
      page-break-before: avoid; /* Avoids breaking the page right before the div */
      page-break-inside: avoid; /* Avoids breaking inside the div */
    }
  }
`

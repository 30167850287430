import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'
import * as React from 'react'

import { useAuth } from '@clerk/clerk-react'
import { FullpageAppLoader } from '../components/FullpageAppLoader'
import { memoryStore } from '../utils'

// TODO .onrender is temp until we set up domains
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  fetch,
})

const cache = new InMemoryCache()

let Client: any

export const AuthApolloProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { isLoaded, userId, sessionId, getToken } = useAuth()

  const authLink = setContext(async (_, { headers }) => {
    // const token = ''
    // Template is important to add organization data to the token
    const token = await getToken({ template: 'volt-jwt-template' })

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  React.useEffect(() => {
    Client = new ApolloClient({
      cache,
      link: authLink.concat(httpLink),
    })
  }, [sessionId, isLoaded])

  return Client ? (
    <ApolloProvider client={Client}>{children}</ApolloProvider>
  ) : (
    <FullpageAppLoader />
  )
}

AuthApolloProvider.displayName = 'AuthApolloProvider'

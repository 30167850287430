import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const PriceHistoryChartStyled = styled.div`
  font-size: ${rem(16)};
  width: 100%;
  height: 300px;

  > span.ant-tag {
    text-align: center;
    min-width: 75px;
  }
`

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  SelectProps,
  Select,
  Divider,
  Spin,
  Space,
  Button,
  Typography,
} from 'antd'
import React, { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { CurrencySelectStyled } from './styles'
import { MAIN_CURRENCIES, OTHER_CURRENCIES } from '../../config/default'

export interface CurrencySelectProps<ValueType = any> {
  onChange?: (currency: string) => void
  defaultCurrency?: string
}

export const CurrencySelect = <
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any
>({
  onChange,
  defaultCurrency,
  ...props
}: CurrencySelectProps<ValueType>) => {
  return (
    <CurrencySelectStyled>
      <Select
        {...props}
        defaultActiveFirstOption={true}
        style={{ width: '100%' }}
        onChange={onChange}
        showSearch
        defaultValue={defaultCurrency}
        data-testid='currency-select'
        options={[
          {
            label: 'Currency',
            options: [
              ...MAIN_CURRENCIES.map(({ currency }) => ({
                label: currency,
                value: currency,
              })),
            ],
          },
          {
            label: 'Other',
            options: [
              ...OTHER_CURRENCIES.map(({ currency }) => ({
                label: currency,
                value: currency,
              })),
            ],
          },
        ]}
      />
    </CurrencySelectStyled>
  )
}

CurrencySelect.displayName = 'CurrencySelect'

import { Card, Typography, Space } from 'antd'
import * as React from 'react'

import { CustomerDetailFragment } from '../../graphql/_generated-hooks'
import { CustomerDetailCardStyled } from './styles'
import { AddressDisplay } from '../AddressDisplay/AddressDisplay'

const { Text } = Typography

// TODO properly type partial of full Invoice object
export interface CustomerDetailCardProps {
  customer: CustomerDetailFragment
  extra?: React.ReactNode
  hideBorder?: boolean
  hideTitle?: boolean
}

export const CustomerDetailCard = ({
  customer = {},
  extra = <></>,
  hideTitle = false,
  hideBorder = false,
  ...props
}: CustomerDetailCardProps | any) => {
  const { name } = customer

  return (
    <CustomerDetailCardStyled {...props}>
      <Card
        title={name}
        size='small'
        extra={extra}
        bordered={!hideBorder}
        data-testid='customer-detail-card'
      >
        <AddressDisplay address={customer} />
      </Card>
    </CustomerDetailCardStyled>
  )
}

CustomerDetailCard.displayName = 'CustomerDetailCard'

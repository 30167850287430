import { Tag, Badge } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { StatusTagStyled } from './styles'
import { capitalizeString } from '../../utils'

export interface StatusTagProps {
  status?: string
}

const defaultColor = theme.colors.lightGrey
const colorMap = {
  draft: theme.colors.yellow,
  open: theme.colors.blue,
  paid: theme.colors.green,
  uncollectible: theme.colors.red,
  void: theme.colors.red,

  // Price freshness TODO: Abstract
  new: theme.colors.green,
  recent: theme.colors.yellow,
  stale: theme.colors.red,
  none: theme.colors.lightGrey,
}

export const StatusTag = ({ status = 'Default' }: StatusTagProps) => {
  return (
    <StatusTagStyled>
      {/* <Tag color={colorMap[status as keyof typeof colorMap] || defaultColor}>
        {status?.toUpperCase()}
      </Tag> */}
      <Badge
        color={colorMap[status as keyof typeof colorMap] || defaultColor}
        text={capitalizeString(status)}
      />
    </StatusTagStyled>
  )
}

StatusTag.displayName = 'StatusTag'

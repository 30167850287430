import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Popover, Space, Typography } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { WastageIconStyled } from './styles'
import { roundNumber } from '../../utils'

export interface WastageIconProps {
  percent?: number
  amount?: number
  coverageCapacity?: any
}

export const WastageIcon = ({
  percent = 0,
  amount = 0,
  coverageCapacity,
}: WastageIconProps) => {
  return (
    <WastageIconStyled>
      <Popover
        title='Wastage'
        content={
          <Space direction='vertical'>
            {coverageCapacity && (
              <Typography.Text>
                Coverage capacity: {coverageCapacity}
              </Typography.Text>
            )}
            <Typography.Text>
              Expected wastage quantity: {amount} (~
              {roundNumber(percent * 100, 2)}% of total)
            </Typography.Text>
          </Space>
        }
      >
        <ExclamationCircleOutlined />
      </Popover>
    </WastageIconStyled>
  )
}

WastageIcon.displayName = 'WastageIcon'

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const AnimatedComponentStyled = styled.div<{ size?: string }>`
  color: ${(props) => props.theme.colors.textPrimary};
  width: ${(props) => (props.size == 'sm' ? '50px' : '175px')};
  height: 135px;
  margin-top: 25px;

  display: flex;

  align-items: center;
  justify-content: center;

  img {
    width: 30px;
    height: 30px;
  }
`

import { create } from 'zustand'
import { createUsageSlice } from './usageSlice'
import { estimateFormSlice } from './estimateFormSlice'
import { estimateMaterialListSlice } from './estimateMaterialListSlice'

export const useAppStore = create((...a) => ({
  // @ts-ignore
  ...createUsageSlice(...a),
  // @ts-ignore
  ...estimateFormSlice(...a),
  // @ts-ignore
  // ...estimateMaterialListSlice(...a),
}))

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components'
import { useGetDistributorsQuery } from '../../graphql/_generated-hooks'
import { Button, Form, Typography, message } from 'antd'
import type { FormInstance } from 'antd/es/form'
import React, { useMemo, useRef, useState } from 'react'

import { DistributorSearchSelectStyled } from './styles'
import { SearchSelect } from '../SearchSelect'

export interface DistributorSearchSelectProps {
  formItemProps?: any
  recentlyCreatedDistributor?: any[]
  onAddClick?: () => void
  onValuesChange?: (changedValues: any, values: any) => void
  style?: React.CSSProperties
}

export interface DistributorSearchSelectSchema {
  id?: string
  name: string
  email?: string
  phone?: string
  description?: string
  address_city?: string
  address_country?: string
  address_line1?: string
  address_line2?: string
  address_postal_code?: string
  address_state?: string
}

export const DistributorSearchSelect = ({
  formItemProps = { label: 'Vendor', name: 'distributor' },
  recentlyCreatedDistributor = [],
  onAddClick = () => {},
  onValuesChange = () => {},
  ...props
}: DistributorSearchSelectProps) => {
  const {
    data: { distributors = [] } = { distributors: [] },
    loading: isLoadingDistributors,
    refetch: refetchDistributors,
    updateQuery: updateDistributors,
  } = useGetDistributorsQuery({
    fetchPolicy: 'no-cache',
    variables: { input: { search: '' } },
  })

  const handleFetchDistributors = async (value: string): Promise<any> => {
    return refetchDistributors({ input: { search: value } }).then(
      ({ data }: any) => {
        if (!data?.distributors) return []
        return data.distributors.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      }
    )
  }

  return (
    <DistributorSearchSelectStyled {...props}>
      <Form.Item {...formItemProps}>
        <SearchSelect
          labelInValue
          placeholder='Select vendor'
          fetchOptions={handleFetchDistributors}
          createButtonVisible={true}
          createButtonText='New vendor'
          createdOptions={recentlyCreatedDistributor}
          initialOptions={distributors?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }))}
          onAddClick={onAddClick}
          data-testid='distributor-search-select'
        />
      </Form.Item>
    </DistributorSearchSelectStyled>
  )
}

DistributorSearchSelect.displayName = 'DistributorSearchSelect'

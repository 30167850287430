import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const EstimatesFormStyled = styled.div`
  font-size: ${rem(16)};

  .form-footer-container {
    padding: 2em 0;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }

  .amount-input {
    width: 100%;
  }

  .text-center {
    text-align: center;
  }

  .totals-component {
    text-align: right;
  }

  .content-container {
    padding: 2em;
    background: #fff;
  }

  .totals-input-row {
    padding: 1em 0;
    margin-bottom: 1em;
  }

  .list-header {
    opacity: 0.5;
  }

  .estimates-form .simple-form-input-styles {
    .ant-input-number,
    .ant-input,
    .ant-select,
    .ant-picker,
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      border-radius: 0;
      border: none !important;
      border-bottom: 1px solid #d9d9d9 !important;
      outline: none;

      &:focus,
      &:hover,
      &:active,
      &:visited,
      &:target,
      &.ant-picker-focused,
      &.ant-input-affix-wrapper-focused,
      &.ant-input-focused,
      &.ant-select-focused,
      &.ant-input-number-focused {
        border-radius: 0px;
        outline: none;
        box-shadow: none;
      }
    }
    .ant-input-group {
      &:hover {
        border-radius: 0px;
      }
    }
    .ant-input-borderless {
      border-radius: 0 !important;
    }
    .ant-select-selector {
      border: none !important;
    }
    .ant-input-affix-wrapper > input.ant-input {
      border: none !important;
    }
    .ant-input-group-addon {
      opacity: 0.5;
      color: ${(props) => props.theme.colors.accent};
    }
  }
`

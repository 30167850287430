export const theme = {
  borderRadius: 5,
  boxShadow: 'none',
  colors: {
    background: '#fafafa',
    primary: '#343546', // '#1d7a8d'
    secondary: '#A5A5A5', //'#40a9c5',
    accent: '#faac14',
    checkbox: '#FFD08A',
    white: '#FFFFFF',
    yellow: '#faac14',
    pink: '#ffa6ff',
    red: '#FF3B1F',
    blue: '#0566ff',
    green: '#1CC712',
    purple: '#9b6cfe',
    aqua: '#09e6ff',
    textPrimary: '#343546',
    grey: '#707985',
    lightGrey: '#A5A5A5',
    border: '#d9d9d9',
    error: '#FF3B1F',
    danger: '#FF3B1F',
    success: '#1CC712',
    warning: '#faac14',
  },
}

import { Tooltip, Tag } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { DemoItemTagStyled } from './styles'

export interface DemoItemTagProps {
  tooltipProps?: any
}
export const DemoItemTag = ({ tooltipProps }: DemoItemTagProps) => {
  return (
    <DemoItemTagStyled>
      <Tooltip title={'This is demonstration data'} {...tooltipProps}>
        <Tag color={theme.colors.yellow}>DEMO</Tag>
      </Tooltip>
    </DemoItemTagStyled>
  )
}

DemoItemTag.displayName = 'DemoItemTag'

import { Button } from 'antd'
import * as React from 'react'

import { RichTextEditorButtonStyled } from './styles'
export interface RichTextEditorButtonProps {
  icon?: any
  isActive?: boolean
  isDisabled?: boolean
  onClick?: () => void
}

export const RichTextEditorButton = ({
  icon,
  isActive,
  isDisabled,
  onClick = () => {},
  ...props
}: RichTextEditorButtonProps) => {
  return (
    <RichTextEditorButtonStyled>
      <Button
        {...props}
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        className={isActive ? ' active' : ''}
        disabled={isDisabled}
        icon={icon}
      />
    </RichTextEditorButtonStyled>
  )
}

RichTextEditorButton.displayName = 'RichTextEditorButton'

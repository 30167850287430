import { Award } from 'react-feather'
import { UserOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  message,
  Space,
  Spin,
  Flex,
  Typography,
  Upload,
} from 'antd'
import debounce from 'lodash/debounce'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import { buildRedirectString } from '../../utils'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { PageTitle } from '../../components/PageTitle'
import { StripePricingTable } from '../../components/StripePricingTable'
import { useAppStore } from '../../stores/appStore'
import { theme } from '../../styles/themes/default'
import {
  useGetMeQuery,
  useGetUserPortalSessionQuery,
  useGetUserPortalSessionLazyQuery,
  useGetUserCustomerSessionQuery,
  useGetUserCustomerSessionLazyQuery,
  useUpdateMeMutation,
} from '../../graphql/_generated-hooks'
import { PreferenceBillingViewStyled } from './styles'

export interface PreferenceBillingViewProps {
  me?: any
  redirectObj?: any
}

export interface UserProfileFormSchema {}

// const ProfilePictureUpload = ({
//   me,
//   value,
//   onChange,
// }: {
//   value?: string
//   onChange?: (fileUrl: string) => void
//   me: any
// }) => {
//   return (
//     <PresignedUpload value={value} onChange={onChange}>
//       {({ isUploading }) => (
//         <Space>
//           <UserAvatar size={64} user={me} loading={isUploading} />

//           {!isUploading && <Button type='link'>Update profile image</Button>}
//         </Space>
//       )}
//     </PresignedUpload>
//   )
// }

export const PreferenceBillingView = ({
  me,
  redirectObj,
}: PreferenceBillingViewProps) => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const [redirectUrl, setRedirectUrl] = React.useState('')
  const [showSuccessModal, setShowSuccessModal] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  // const [manageBillingUrl, setManageBillingUrl] = React.useState<any>('')
  // const [userCustomerSession, setUserCustomerSession] = React.useState<any>('')
  const activePlan = useAppStore((state: any) => state.plan)
  const setPlan = useAppStore((state: any) => state.setPlan)

  const [
    getUserPortalSession,
    { data: userPortalSession, loading: loadingPortalSession },
  ] = useGetUserPortalSessionLazyQuery()

  const [
    getUserCustomerSession,
    { data: userCustomerSession, loading: loadingSession },
  ] = useGetUserCustomerSessionLazyQuery()

  const handleGetSession = React.useCallback(
    debounce(async (activePlan) => {
      if (activePlan) {
        getUserPortalSession()
      } else {
        getUserCustomerSession()
      }
    }, 100),
    []
  )

  React.useEffect(() => {
    if (redirectObj) {
      setRedirectUrl(buildRedirectString(redirectObj))
    }

    handleGetSession(activePlan)
  }, [me, redirectObj, activePlan])

  React.useEffect(() => {
    if (
      searchParams &&
      searchParams.get('checkout_session') &&
      searchParams.get('checkout_session')?.startsWith('cs_')
    ) {
      setPlan('basic_plan')
    }
    if (searchParams && searchParams.get('success')) {
      setShowSuccessModal(true)
    }
  }, [])

  const handleOnSuccessModalClose = () => {
    setShowSuccessModal(false)
    const params = Object.fromEntries(searchParams.entries())
    delete params['success']
    setSearchParams(params)
  }

  return (
    <PreferenceBillingViewStyled>
      {contextHolder}
      <AnimatedComponent>
        <Content style={{ padding: '2em' }}>
          <PageTitle
            title='Membership'
            subtitle='Update subscription and other payment settings.'
          />

          <Card style={{ marginTop: '2em', maxWidth: '800px' }}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                {!activePlan && (
                  <Spin spinning={loadingSession}>
                    <StripePricingTable
                      // email={me?.email}
                      clientSecret={
                        userCustomerSession?.userCustomerSession?.client_secret
                      }
                    />
                  </Spin>
                )}

                {activePlan && (
                  <Spin spinning={loadingPortalSession}>
                    <Card>
                      <Flex align='center' justify='space-between'>
                        <Space direction='vertical'>
                          <Typography.Text
                            style={{ marginTop: '0.5em' }}
                            type='secondary'
                          >
                            You are subscribed to the:
                          </Typography.Text>
                          <Typography.Title level={4} style={{ marginTop: 0 }}>
                            Basic plan
                          </Typography.Title>
                        </Space>
                        <Button
                          type='primary'
                          href={userPortalSession?.userPortalSession?.url}
                        >
                          Manage Subscription
                        </Button>
                      </Flex>
                    </Card>
                  </Spin>
                )}
              </Col>
            </Row>
          </Card>
        </Content>
      </AnimatedComponent>
      <Modal
        title={'Success'}
        open={showSuccessModal}
        onCancel={handleOnSuccessModalClose}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ type: 'primary' }}
        cancelText={'Get estimating'}
      >
        <img
          width={520}
          style={{
            marginLeft: '-24px',
            marginBottom: '1em',
            marginTop: '0.5em',
          }}
          alt='payment_complete_1.png'
          src='https://voltappstorage01.blob.core.windows.net/public-assets/app/payment_complete_2.png'
        />
        <Typography.Text>
          You&#39;re have successfully subscribed to VoltPro. You have unlocked
          unlimited estimates and material tracking. Visit this page to manage
          your subscription in the future.
        </Typography.Text>
      </Modal>
    </PreferenceBillingViewStyled>
  )
}

PreferenceBillingView.displayName = 'PreferenceBillingView'

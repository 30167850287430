import * as React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { Upload, message } from 'antd'
import { useAuth } from '@clerk/clerk-react'

import { theme } from '../../styles/themes/default'
import { UploadEmptyStateStyled } from './styles'
import { LoadingOverlay } from '../LoadingOverlay'
import { memoryStore } from '../../utils'

const { Dragger } = Upload

export interface UploadEmptyStateProps {
  multiple?: boolean
  uploadName?: string
  uploadUrl?: string
  title?: string
  content?: string
  onUploadFileChange?: (status: any) => void
  fileProcessing?: boolean
}

const { NODE_ENV, REACT_APP_API_URL } = process.env

export const UploadEmptyState = ({
  multiple = true,
  uploadName = 'invoice_upload',
  uploadUrl = `${REACT_APP_API_URL}/api/invoice/upload`,
  title = 'Drag & drop a file to upload',
  content = 'Upload historical distributor invoices from your jobs to add line items as products.',
  onUploadFileChange = () => {},
  fileProcessing = false,
}: UploadEmptyStateProps) => {
  const { sessionId, isLoaded, getToken } = useAuth()
  const [uploadHeaders, setUploadHeaders] = React.useState<any>({})

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  let props: UploadProps = {
    name: uploadName,
    multiple,
    action: uploadUrl,
    showUploadList: false,
    onChange(info) {
      const { status } = info.file

      onUploadFileChange(info)

      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList)
      // }
      // if (status === 'done') {
      //   message.success(`${info.file.name} file uploaded successfully.`)
      // } else if (status === 'error') {
      //   message.error(`${info.file.name} file upload failed.`)
      // }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    beforeUpload: async (file) => {
      // Grab the token from Clerk before uploading to get the
      // most up to date token
      await getToken({ template: 'volt-jwt-template' }).then(async (token) => {
        setUploadHeaders({
          authorization: `Bearer ${token}`,
        })

        // Hacky af - need to wait for state to be set
        await timeout(200)
      })

      return true
    },
  }

  return (
    <UploadEmptyStateStyled>
      <Dragger {...props} headers={uploadHeaders}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>{title}</p>
        {content && <p className='ant-upload-hint'>{content}</p>}
        {fileProcessing && <LoadingOverlay />}
      </Dragger>
    </UploadEmptyStateStyled>
  )
}

UploadEmptyState.displayName = 'UploadEmptyState'

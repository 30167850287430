import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const SearchSelectStyled = styled.div`
  font-size: ${rem(16)};
  width: 100%;

  display: inline-block;
`

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const PreferenceViewStyled = styled.div`
  font-size: ${rem(16)};

  .nav-item {
    color: ${(props) => props.theme.colors.textPrimary};

    &.active {
      color: ${(props) => props.theme.colors.accent};
    }

    &.logout {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  .ant-menu-title-content {
    font-size: ${rem(16)};
  }
`

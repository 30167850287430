import { Tooltip, Typography } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { getPriceAgeStatus, getPriceAge } from '../../utils'
import { PriceAgeTagStyled } from './styles'
import { StatusTag } from '../StatusTag'
export interface PriceAgeTagProps {
  showTooltip?: boolean
  tooltipProps?: any
  latestPriceDate?: number
}
export const PriceAgeTag = ({
  latestPriceDate,
  tooltipProps,
}: PriceAgeTagProps) => {
  const age = getPriceAge(latestPriceDate)
  const status = getPriceAgeStatus(latestPriceDate)

  const getTitleText = () => {
    if (!latestPriceDate) {
      return `No price data available`
    }
    if (age <= 1) {
      return `Price information added within the last day`
    } else {
      return `Price information is from ${age} days ago`
    }
  }

  return (
    <PriceAgeTagStyled>
      <Tooltip title={getTitleText()} {...tooltipProps}>
        <div>
          {/* {latestPriceDate && <StatusTag status={status || 'none'} />}
          {!latestPriceDate && (
            <Typography.Text italic>No price</Typography.Text>
          )} */}
          <StatusTag status={status} />
        </div>
      </Tooltip>
    </PriceAgeTagStyled>
  )
}

PriceAgeTag.displayName = 'PriceAgeTag'

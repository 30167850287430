import { Progress, Space, Popover, Badge } from 'antd'
import type { FormInstance } from 'antd/es/form'
import * as React from 'react'

import { TotalSummaryProfitBreakdownStyled } from './styles'
import { formatMoney, roundNumber } from '../../../utils'
import { TotalSummaryRow } from '../TotalSummaryRow'

export interface TotalSummaryProfitBreakdownProps {
  materialProfit?: number | null
  laborProfit?: number | null
  profit?: number | null
  materials?: number | null
  labor?: number | null
  cost?: number | null
  subtotal?: number | null
  currency?: string | null
}

export const TotalSummaryProfitBreakdown = ({
  materialProfit = 0,
  laborProfit = 0,
  profit = 0,
  materials = 0,
  labor = 0,
  cost = 0,
  subtotal = 0,
  currency,
  ...props
}: TotalSummaryProfitBreakdownProps) => {
  const getProfitPercentage = (profit: number = 0, subtotal: number) => {
    if (profit && subtotal && subtotal > 0) {
      return roundNumber((profit / subtotal) * 100, 2)
    } else {
      return 0
    }
  }

  return (
    <TotalSummaryProfitBreakdownStyled>
      <Popover
        title='Profit Margin Calculation'
        content={
          <div style={{ minWidth: '300px' }}>
            <TotalSummaryRow
              label='Profit'
              value={formatMoney(profit)}
              data-testid='tax-total-summary-profit-popover-profit'
            />
            <TotalSummaryRow
              label='Revenue (Subtotal)'
              value={formatMoney(subtotal)}
              data-testid='tax-total-summary-profit-popover-subtotal'
            />
            <TotalSummaryRow
              label='= Profit Margin'
              value={`${getProfitPercentage(profit || 0, subtotal || 0)}%`}
              data-testid='tax-total-summary-profit-popover-margin'
            />
          </div>
        }
      >
        <Progress
          showInfo={true}
          percent={getProfitPercentage(profit || 0, subtotal || 0)}
          // success={{ percent: 30, strokeColor: '#B7EB8F' }}
          percentPosition={{ align: 'start', type: 'inner' }}
          size={['100%', 20]}
          strokeColor='#B7EB8F'
          trailColor='#facb71'
          style={{ margin: '20px 0 10px 0' }}
        />
      </Popover>
      <Space size={'large'} align={'end'}>
        <Popover
          title='Profit Breakdown'
          content={
            <div style={{ minWidth: '200px' }}>
              <TotalSummaryRow
                label='Materials'
                value={formatMoney(materialProfit)}
                data-testid='tax-total-summary-materials-profit'
              />
              <TotalSummaryRow
                label='Labor'
                value={formatMoney(laborProfit)}
                data-testid='tax-total-summary-labor-profit'
              />
            </div>
          }
        >
          {/* https://ant.design/components/tooltip#why-does-the-warning-finddomnode-is-deprecated-some-times-appear-in-strict-mode */}
          <div>
            <Badge
              color='#B7EB8F'
              text={`Profit: ${formatMoney(profit)} ${currency && currency}`}
              className='badge-with-popover'
            />
          </div>
        </Popover>
        <Popover
          title='Cost Breakdown'
          content={
            <div style={{ minWidth: '200px' }}>
              <TotalSummaryRow
                label='Materials'
                value={formatMoney(materials)}
                data-testid='tax-total-summary-materials'
              />
              <TotalSummaryRow
                label='Labor'
                value={formatMoney(labor)}
                data-testid='tax-total-summary-labor'
              />
            </div>
          }
        >
          <div>
            <Badge
              color='#facb71'
              text={`Cost: ${formatMoney(cost)} ${currency && currency}`}
              className='badge-with-popover'
            />
          </div>
        </Popover>
      </Space>
    </TotalSummaryProfitBreakdownStyled>
  )
}

TotalSummaryProfitBreakdown.displayName = 'TotalSummaryProfitBreakdown'

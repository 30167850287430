import { LoadingOutlined } from '@ant-design/icons'
import { Avatar, Typography, Row, Space, Flex } from 'antd'
import * as React from 'react'

import { UserAvatarStyled } from './styles'
export interface UserAvatarProps {
  firstName: string
  lastName?: string
  email?: string
  secondaryText?: string | React.ReactNode
  picture?: string
  loading?: boolean
  size?: number
  maxWidth?: number
}

export const UserAvatar = ({
  firstName = '',
  lastName,
  email,
  secondaryText,
  picture,
  loading,
  size,
  maxWidth,
  ...props
}: UserAvatarProps) => {
  return (
    <UserAvatarStyled {...props}>
      {/* <Flex style={{ maxWidth }} justify='center' align='center'> */}
      <Space direction='vertical'>
        <Avatar
          size={size}
          icon={loading && <LoadingOutlined />}
          src={loading ? undefined : picture}
          className={`avatar-icon ${picture ? 'has-picture' : ''}`}
        >
          {firstName[0]}
          {lastName?.[0]}
        </Avatar>

        <div
          style={{
            maxWidth,
            textOverflow: 'ellipsis',
          }}
        >
          <Typography.Paragraph ellipsis={true} style={{ margin: 0 }}>
            {firstName} {lastName}
          </Typography.Paragraph>
          {email && (
            <Typography.Paragraph type='secondary' ellipsis={true}>
              {email}
            </Typography.Paragraph>
          )}
          {secondaryText && (
            <Typography.Paragraph type='secondary' ellipsis={true}>
              {secondaryText}
            </Typography.Paragraph>
          )}
        </div>
      </Space>
      {/* </Flex> */}
      {/* 
      {email && (
        <Typography.Paragraph
          type='secondary'
          ellipsis={true}
          style={{ marginBottom: '0' }}
        >
          {email}
        </Typography.Paragraph>
      )} */}
    </UserAvatarStyled>
  )
}

UserAvatar.displayName = 'UserAvatar'

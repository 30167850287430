import { Typography } from 'antd'
import * as React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import { theme } from '../../styles/themes/default'
import { PriceHistoryChartStyled } from './styles'
import { formatDate, formatMoney } from '../../utils'

export interface PriceHistoryChartProps {
  data?: any[]
  dataKey?: string
  dateKey?: string
}

export const PriceHistoryChart = ({
  data = [],
  dataKey = 'unit_amount',
  dateKey = 'invoicedAt',
}: PriceHistoryChartProps) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: '#fff',
            padding: '5px 10px',
            borderRadius: '5px',
            border: `1px solid ${theme.colors.border}`,
          }}
        >
          <Typography.Title
            className='label'
            level={5}
            style={{ margin: 0 }}
          >{`${formatMoney(payload[0].value)}`}</Typography.Title>
          <Typography.Text className='date'>
            {formatDate(payload[0]?.payload?.[dateKey])}
          </Typography.Text>
        </div>
      )
    }

    return null
  }

  return (
    // <PriceHistoryChartStyled>
    <span data-testid='price-chart'>
      <ResponsiveContainer width='100%' height={100}>
        <LineChart data={data}>
          <Line
            type='monotone'
            dataKey={dataKey}
            stroke={theme.colors.accent}
            strokeWidth={4}
          />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </span>
    // </PriceHistoryChartStyled>
  )
}

PriceHistoryChart.displayName = 'PriceHistoryChart'

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const EstimatesFormViewStyled = styled.div`
  font-size: ${rem(16)};

  .created-by-container {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    padding-right: 1em;
  }
`

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const CategoryTableFilterStyled = styled.div<{ filterWidth?: number }>`
  font-size: ${rem(16)};
  width: ${({ filterWidth }) => (filterWidth ? filterWidth + 'px' : 'auto')};

  > span.ant-tag {
    text-align: center;
    min-width: 75px;
  }
`

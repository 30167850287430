import * as React from 'react'
import { Heading, TextHandler, createLinkHandler } from '@remirror/react'
import { Doc, RemirrorRenderer } from '@remirror/react-renderer'

import { theme } from '../../styles/themes/default'
import { RichTextRendererStyled } from './styles'
export interface RichTextRendererProps {
  state?: {} | null
}

export const RichTextRenderer = ({ state = {} }: RichTextRendererProps) => {
  return (
    <RichTextRendererStyled>
      <RemirrorRenderer
        // @ts-ignore
        json={state}
        typeMap={{
          doc: Doc,
          text: TextHandler,
          heading: Heading,
          hardBreak: 'br',
          bulletList: 'ul',
          image: 'img',
          listItem: 'li',
          paragraph: 'p',
          orderedList: 'ol',
        }}
        markMap={{
          italic: 'em',
          strike: 's',
          bold: 'strong',
          code: 'code',
          link: createLinkHandler({ target: '_blank' }),
          underline: 'u',
        }}
      />
    </RichTextRendererStyled>
  )
}

RichTextRenderer.displayName = 'RichTextRenderer'

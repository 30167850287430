import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { SelectProps, Select, Divider, Spin, Space, Button } from 'antd'
import React, { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { SearchSelectStyled } from './styles'
import { filterArrayByObjId } from '../../utils'
import { truncate } from 'fs'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  onAddClick?: (searchString?: string) => void
  onBlurTriggered?: (searchString?: string, e?: any) => void
  initialOptions?: ValueType[]
  createdOptions?: ValueType[]
  debounceTimeout?: number
  createButtonVisible?: boolean
  createButtonText?: string
}

export const SearchSelect = <
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any
>({
  fetchOptions,
  debounceTimeout = 300,
  createButtonVisible,
  createButtonText,
  initialOptions = [],
  createdOptions = [],
  onAddClick,
  onBlurTriggered,
  ...props
}: DebounceSelectProps<ValueType>) => {
  const [fetching, setFetching] = useState(false)
  const [changeInitialValuesOnce, setChangeInitialValuesOnce] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const [searchStringValue, setSearchStringValue] = useState<string>()
  const fetchRef = useRef(0)

  React.useEffect(() => {
    if (initialOptions.length && !changeInitialValuesOnce) {
      setOptions(initialOptions)

      /**
       * Not the cleanest. If the initial options are async, we listen
       * for a single change from [] --> [...]. Then we no longer
       * update the options on initial value change and leave it
       * up to the internal fetcher.
       */
      setChangeInitialValuesOnce(true)
    }
  }, [initialOptions])

  React.useEffect(() => {
    if (createdOptions && createdOptions.length && changeInitialValuesOnce) {
      setOptions(filterArrayByObjId(options.concat(createdOptions), 'value'))
    }
  }, [createdOptions])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      setSearchStringValue(value)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <SearchSelectStyled>
      <Select
        showSearch
        allowClear
        filterOption={false}
        onSearch={debounceFetcher}
        onClear={() => debounceFetcher('')}
        notFoundContent={
          <div
            style={{ width: '100%', textAlign: 'center', padding: '2em 1em' }}
          >
            <Space>
              {fetching ? <LoadingOutlined /> : <div>Nothing here</div>}
            </Space>
          </div>
        }
        {...props}
        options={options}
        onBlur={(e) => onBlurTriggered && onBlurTriggered(searchStringValue, e)}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              {createButtonVisible && !fetching && (
                <>
                  <Divider style={{ margin: '8px 0' }} />
                  <Button
                    style={{ width: '100%' }}
                    type='text'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      onAddClick && onAddClick(searchStringValue)
                      setSearchStringValue('')
                    }}
                  >
                    {createButtonText || 'Create'}
                  </Button>
                </>
              )}
            </>
          )
        }}
      />
    </SearchSelectStyled>
  )
}

SearchSelect.displayName = 'SearchSelect'

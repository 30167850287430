import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import * as React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { AnimatedComponent } from '../AnimatedComponent'

import { BreadcrumbsStyled } from './styles'

const breadcrumbNameMap: Record<string, string> = {
  '/customers': 'Customers',
  '/projects': 'Projects',
  '/estimates': 'Estimates',
  '/materials': 'Materials',
  '/assemblies': 'Assemblies',
  '/edit': 'Edit',
  '/create': 'Create',
  '/order-sheet': 'Order Sheet',
}

export const Breadcrumbs = ({}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const pathSnippets = location.pathname.split('/').filter((i) => i)

  const extraBreadcrumbItems = pathSnippets.map((path, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    const title = breadcrumbNameMap[`/${path}`]

    return {
      path: url,
      title: (
        <AnimatedComponent
          customVariants={{
            hidden: { opacity: 0, x: -5 },
            visible: {
              opacity: 1,
              x: 0,
              transition: { delay: 0.2 },
            },
          }}
        >
          <span>{breadcrumbNameMap[url] || title || 'Details'}</span>
        </AnimatedComponent>
      ),
    }
  })

  const breadcrumbItems = [
    {
      path: '/',
      title: (
        <>
          <HomeOutlined />
        </>
      ),
    },
  ].concat(extraBreadcrumbItems)

  const itemRender = (route: any, params: any, routes: any, paths: any) => {
    return <Link to={route.path}>{route.title}</Link>
  }

  return (
    <BreadcrumbsStyled className='hidden-print'>
      <Breadcrumb itemRender={itemRender} items={breadcrumbItems} />
    </BreadcrumbsStyled>
  )
}

Breadcrumbs.displayName = 'Breadcrumbs'

import {
  CloseOutlined,
  CloseSquareOutlined,
  ToolOutlined,
  FormOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { PlusSquare, Layers } from 'react-feather'
import { Button, Table, Typography, Modal, Tooltip } from 'antd'
import * as React from 'react'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useGetProductsQuery } from '../../../../graphql/_generated-hooks'

import { theme } from '../../../../styles/themes/default'
import { MaterialDataType } from './MaterialData.types'

import { formatMoney, calculateLineItemTotal } from '../../../../utils'

import { ServiceItemBadge } from '../../../ServiceItemBadge'
import { MaterialSelectItemRender } from '../../../MaterialSelectItemRender'
import { ListButtonBar } from '../../../ListButtonBar'
import { WastageIcon } from '../../../WastageIcon'
import { FormTableRow, FormTableRowDragHandle } from './FormTableRow'
import { FormTableCell } from './FormTableCell'
import { EstimatesMaterialsFormTableStyled } from './styles'

type EditableTableProps = Parameters<typeof Table>[0]
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>

export interface EstimatesMaterialsFormTableProps {
  initialData?: MaterialDataType[] | void
  appliedAssembly?: MaterialDataType[] | void
  inlineCreatedMaterials?: {
    [key: string]: { id: string; prices: { id: string; unit_amount: number }[] }
  }
  onMaterialsChange?: (newData?: any[], row?: MaterialDataType) => void
  onCreateNewMaterialClick?: (record: MaterialDataType) => void
  onAddAssemblyClick?: () => void
  onInputError?: (error: any, record: MaterialDataType) => void
}

const getMaterialSelectItemRenderComponent = (item: any) => {
  return (
    <MaterialSelectItemRender title={item.name} subtitle={item.description} />
  )
}

export const EstimatesMaterialsFormTable = ({
  initialData = [],
  appliedAssembly = [],
  inlineCreatedMaterials,
  onMaterialsChange = () => {},
  onCreateNewMaterialClick = () => {},
  onAddAssemblyClick = () => {},
  onInputError = () => {},
}: EstimatesMaterialsFormTableProps) => {
  const [initialProductList, setInitialProductList] = React.useState<any>([])
  const [dataSource, setDataSource] =
    React.useState<MaterialDataType[]>(initialData)

  React.useEffect(() => {
    setDataSource(initialData)
  }, [initialData]) // Dependency on initialData

  React.useEffect(() => {
    if (appliedAssembly && appliedAssembly.length) {
      setDataSource([...dataSource, ...appliedAssembly])
      onMaterialsChange([...dataSource, ...appliedAssembly])
    }
  }, [appliedAssembly]) // Dependency on appliedAssembly

  React.useEffect(() => {
    if (inlineCreatedMaterials && Object.keys(inlineCreatedMaterials).length) {
      const dataSourceCopy = [...dataSource]

      for (let key in inlineCreatedMaterials) {
        const foundIndex = dataSourceCopy.findIndex(
          (material) => material.key == key
        )
        const inlineCreatedMaterial = inlineCreatedMaterials[key]
        const inlineCreatedLatestPrice = inlineCreatedMaterial.prices?.[0]

        if (foundIndex > -1) {
          dataSourceCopy[foundIndex] = {
            ...dataSourceCopy[foundIndex],
            name: {
              label: dataSourceCopy[foundIndex]?.name?.label || '',
              value: inlineCreatedMaterial.id,
            },
            productId: inlineCreatedMaterial.id, // TODO bug if no priceId, backend doesn't save product properly
            priceId: inlineCreatedLatestPrice?.id,
            ...(inlineCreatedLatestPrice?.unit_amount && {
              price: inlineCreatedLatestPrice?.unit_amount,
            }),
            custom: false,
            recentlyCreated: true,
          }
        }
      }

      setDataSource(dataSourceCopy)
      onMaterialsChange(dataSourceCopy) // Trigger save to parent form
    }
  }, [inlineCreatedMaterials])

  const {
    data: { products = { data: [] } } = { products: { data: [] } },
    refetch: refetchProducts,
  } = useGetProductsQuery({
    variables: { input: { limit: 100 } },
    onCompleted: (data) => {
      setInitialProductList(data.products?.data)
    },
  })

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        )
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        )
        const newState = arrayMove(prevState, activeIndex, overIndex)
        onMaterialsChange(newState)
        return newState
      })
    }
  }

  const handleAdd = (type: string = 'good', custom?: boolean) => {
    const newData: MaterialDataType = {
      key: Math.random().toString(36),
      quantity: 1,
      markup: 0,
      price: 0,
      total: 0,
      type,
      custom,
    }
    setDataSource([...dataSource, newData])
    onMaterialsChange([...dataSource, newData])
  }

  const handleRmove = (key: number) => {
    const newData = dataSource.filter((item: any) => item.key !== key)
    setDataSource(newData)
    onMaterialsChange(newData, {
      key,
      quantity: 0,
      price: 0,
      markup: 0,
      total: 0,
    })
  }

  const handleSave = React.useCallback(
    (row: MaterialDataType) => {
      const newData = [...dataSource]
      const index = newData.findIndex((item) => row.key === item.key)

      const item = newData[index]
      newData.splice(index, 1, {
        ...item,
        ...row,
      })

      onMaterialsChange(newData, row)
      setDataSource(newData)
    },
    [dataSource, onMaterialsChange]
  )

  const handleFetchProducts = async (value: string): Promise<any> => {
    return refetchProducts({ input: { search: value, limit: 100 } }).then(
      ({ data }: any) => {
        return data?.products?.data?.map((item: any) => ({
          value: item.id,
          data: item,
          label: getMaterialSelectItemRenderComponent(item),
        }))
      }
    )
  }
  const handleFormatOptionsList = React.useCallback(
    (data?: any) => {
      return initialProductList.map((item: any) => ({
        value: item.id,
        data: item,
        label: getMaterialSelectItemRenderComponent(item),
      }))
    },
    [initialProductList] // Only update if the initialProductList changes
  )
  const handleRemoveAll = () => {
    setDataSource([])
    onMaterialsChange([])
  }

  const handleReset = () => {
    Modal.confirm({
      title: `Remove All?`,
      content: 'Are you sure you want to remove all materials?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRemoveAll()
      },
    })
  }

  const getInfoColumnElement = (record: MaterialDataType) => {
    const {
      key,
      type,
      custom,
      recentlyCreated,
      name,
      wastage_amount,
      wastage_percentage,
      total_coverage_rate,
    } = record

    // Allow quick create button
    if (type === 'good' && custom && name?.label) {
      return (
        <Button
          type='text'
          shape='circle'
          size='middle'
          className='quick-add-button'
          onClick={(e) => {
            onCreateNewMaterialClick(record)
          }}
          icon={<PlusCircleOutlined />}
        />
      )
    } else if (wastage_amount && wastage_amount > 0) {
      return (
        <WastageIcon
          percent={wastage_percentage}
          amount={wastage_amount}
          coverageCapacity={total_coverage_rate}
        />
      )
    } else if (recentlyCreated) {
      return (
        <Tooltip title='Added to materials'>
          <CheckCircleOutlined
            style={{ color: theme.colors.success, fontSize: '16px' }}
          />
        </Tooltip>
      )
    }
  }

  const isSectionOrAssembly = (record: MaterialDataType) => {
    return record.type === 'section' || record.type === 'assembly'
  }

  const dropdownButtonItems = [
    {
      key: '1',
      icon: <PlusSquare size={14} />,
      label: 'Add Custom Material',
      onClick: () => {
        handleAdd('good', true)
      },
      'data-testid': 'list-add-custom-material-button',
    },
    {
      key: '2',
      icon: <ToolOutlined size={20} />,
      label: 'Add Custom Labor',
      onClick: () => {
        handleAdd('service', true)
      },
      'data-testid': 'list-add-labor-button',
    },
    {
      key: '3',
      icon: <FormOutlined size={20} />,
      label: 'Add Section',
      onClick: () => {
        handleAdd('section')
      },
      'data-testid': 'list-add-section-button',
    },
    {
      key: '4',
      icon: <Layers size={14} />,
      label: 'Add Assembly',
      onClick: onAddAssemblyClick,
      'data-testid': 'list-add-template-button',
    },
  ]

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean
    inputType?: 'text' | 'percent' | 'price' | 'quantity' | 'markup' | 'product'
    dataIndex?: string
  })[] = [
    {
      key: 'sort',
      dataIndex: 'sort',
      align: 'center',
      width: '5%',
      render: (_, { childNode }) => {
        return !childNode && <FormTableRowDragHandle />
      },
    },
    // Table.EXPAND_COLUMN,
    {
      title: 'Item',
      width: '30%',
      dataIndex: 'name',
      editable: true,
      className: 'editable-cell',
      inputType: 'product',
      render: (_, { name: simpleSelectObject = {}, type, childNode }) => {
        const { value, label } = simpleSelectObject
        return (
          // style={{ marginLeft: childNode ? '10px' : 0 }}
          <span>
            {label ? (
              <Typography.Text ellipsis>{label}</Typography.Text>
            ) : (
              <Typography.Text
                type='secondary'
                style={{ cursor: 'pointer', fontStyle: 'italic' }}
              >
                Click here to input an item
              </Typography.Text>
            )}

            {type === 'service' && (
              <span style={{ float: 'right' }}>
                <ServiceItemBadge />
              </span>
            )}
          </span>
        )
      },
    },
    {
      title: null,
      align: 'center',
      width: '5%',
      dataIndex: 'infoColumn',
      render: (_, record: any) => getInfoColumnElement(record),
    },
    {
      title: 'Quantity',
      width: '15%',
      dataIndex: 'quantity',
      editable: true,
      className: 'editable-cell',
      inputType: 'quantity',
    },
    {
      title: 'Price',
      width: '15%',
      dataIndex: 'price',
      editable: true,
      className: 'editable-cell',
      inputType: 'price',
      render: (_, { price }) => <span>{formatMoney(price)}</span>,
    },
    {
      title: 'Markup',
      width: '15%',
      dataIndex: 'markup',
      editable: true,
      className: 'editable-cell',
      inputType: 'markup',
      render: (_, { markup }) => <span>{markup}%</span>,
    },
    {
      title: 'Total',
      width: '15%',
      dataIndex: 'total',
      render: (_, { price, quantity, markup }) => {
        return (
          <span className='cell-padding-bottom'>
            {formatMoney(calculateLineItemTotal(price, quantity, markup))}
          </span>
        )
      },
    },
    {
      title: () =>
        dataSource.length > 0 ? (
          <Button
            type='text'
            icon={<CloseSquareOutlined />}
            onClick={handleReset}
          />
        ) : (
          <div style={{ width: '32px', height: '32px' }} />
        ),
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '5%',
      render: (_, record, index) => (
        <Button
          type='text'
          icon={<CloseOutlined />}
          onClick={() => handleRmove(record.key)}
        />
      ),
    },
  ]

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return {
        ...col,
        onCell: (record: MaterialDataType) => ({
          // TODO Refactor
          hidden:
            isSectionOrAssembly(record) &&
            col.dataIndex != 'name' &&
            col.dataIndex != 'sort' &&
            col.dataIndex != 'action' &&
            col.dataIndex != 'expand',
        }),
      }
    }

    return {
      ...col,
      onCell: (record: MaterialDataType) => {
        return {
          colSpan:
            isSectionOrAssembly(record) && col.dataIndex === 'name' ? 6 : 1,
          hidden:
            isSectionOrAssembly(record) &&
            col.dataIndex != 'name' &&
            col.dataIndex != 'expand',
          record,
          editable: col.editable,
          inputType: col.inputType,
          dataIndex: col.dataIndex,
          title: col.title,
          formattedOptionsList: handleFormatOptionsList(products),
          handleSave,
          handleFetchProducts,
          onCreateNewMaterialClick,
          onInputError,
        }
      },
    }
  })

  return (
    <EstimatesMaterialsFormTableStyled>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey='key'
            onRow={(record, index) => {
              return {
                className: record.type === 'section' ? 'section-row' : '',
              }
            }}
            components={{
              body: { row: FormTableRow, cell: FormTableCell },
            }}
            columns={columns as ColumnTypes}
            dataSource={dataSource}
            pagination={false}
            size='small'
            // virtual
            // scroll={{ y: 1500 }}
            // expandable={{
            //   defaultExpandAllRows: true,
            //   expandIconColumnIndex: 1,
            //   expandIcon: ({ expandable, expanded, onExpand, record }) => {
            //     if (!expandable) return

            //     if (expanded) {
            //       return <DownOutlined onClick={(e) => onExpand(record, e)} />
            //     } else {
            //       return <RightOutlined onClick={(e) => onExpand(record, e)} />
            //     }
            //   },
            // }}
          />

          <ListButtonBar
            onAddClick={handleAdd}
            dropdownButtonItems={dropdownButtonItems}
          />
        </SortableContext>
      </DndContext>
    </EstimatesMaterialsFormTableStyled>
  )
}

EstimatesMaterialsFormTable.displayName = 'EstimatesMaterialsFormTable'

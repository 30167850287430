import {
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Form,
  InputNumber,
  Input,
  Switch,
  message,
} from 'antd'
import * as React from 'react'

import { useGetEstimatePdfLazyQuery } from '../../graphql/_generated-hooks'

import { roundNumber } from '../../utils'
import { EstimatePDFPreview } from './EstimatePDFPreview'
import { EstimatePDFGenerateModalStyled } from './styles'

export interface EstimatePDFGenerateModalProps {
  estimateId: string
  logo?: any
  open?: boolean
  onPDFGenerated?: (pdf_url?: any) => void
  onCancel?: () => void
}

export const EstimatePDFGenerateModal = ({
  estimateId,
  logo,
  open,
  onPDFGenerated = () => {},
  onCancel = () => {},
}: EstimatePDFGenerateModalProps) => {
  const [form] = Form.useForm<any>()
  const [messageApi, contextHolder] = message.useMessage()
  const [showSections, setShowSections] = React.useState(false)
  const [showSignature, setShowSignature] = React.useState(false)
  const [getEstimatePdf, { loading: generatingPDF }] =
    useGetEstimatePdfLazyQuery()

  const handleOnOk = async () => {
    const { show_sections, show_signature } = form.getFieldsValue()

    try {
      getEstimatePdf({
        fetchPolicy: 'no-cache',
        variables: {
          id: estimateId,
          input: {
            ...(show_sections && { show_sections }),
            ...(show_signature && { show_signature }),
          },
        },
        onCompleted: (data) => {
          onPDFGenerated(data?.estimatePdf?.estimate_pdf)

          onCancel()
        },
        onError: (error) => {
          messageApi.error(
            `An error occurred: ${error.message} Please try again.`
          )
        },
      })
    } catch (error) {
      messageApi.error('An error occurred. Please try again.')
    }
  }

  const handleFormChange = (values: any) => {
    if (values.hasOwnProperty('show_sections')) {
      setShowSections(values.show_sections)
    }
    if (values.hasOwnProperty('show_signature')) {
      setShowSignature(values.show_signature)
    }
  }

  return (
    <EstimatePDFGenerateModalStyled>
      {contextHolder}
      <Modal
        title='Estimate PDF Options'
        okText='Generate'
        open={open}
        onOk={handleOnOk}
        onCancel={onCancel}
        width={800}
        confirmLoading={generatingPDF}
      >
        <Form layout='vertical' form={form} onValuesChange={handleFormChange}>
          <Row style={{ marginTop: '2em' }}>
            <Col span={12}>
              <Form.Item
                label='Breakdown Subtotal by Section'
                name='show_sections'
                valuePropName='checked'
              >
                <Switch defaultChecked={false} />
              </Form.Item>
              <Form.Item
                label='Show Signature Section'
                name='show_signature'
                valuePropName='checked'
              >
                <Switch defaultChecked={false} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <EstimatePDFPreview
                logo={logo}
                renderSections={showSections}
                renderSignature={showSignature}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </EstimatePDFGenerateModalStyled>
  )
}

EstimatePDFGenerateModal.displayName = 'EstimatePDFGenerateModal'

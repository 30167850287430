import React, { useEffect, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { H } from 'highlight.run'

type ProviderProps = {
  children: ReactNode
}
export const AnalyticsProvider: React.FC<ProviderProps> = ({
  children,
}: any) => {
  if (process.env.NODE_ENV === 'production') {
    const location = useLocation()

    H.init('kevq3llg', {
      serviceName: 'voltpro-app',
      tracingOrigins: [
        'localhost',
        'volt-app-staging.onrender.com/graphql',
        'volt-app.onrender.com/graphql',
        'app.voltpro.com/graphql',
      ],
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [
          // insert full or partial urls that you don't want to record here
          // Out of the box, Highlight will not record these URLs (they can be safely removed):
          'https://www.googleapis.com/identitytoolkit',
          'https://securetoken.googleapis.com',
        ],
      },
    })

    useEffect(() => {
      // @ts-ignore
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      })
    }, [location])
  }

  return <div>{children}</div>
}

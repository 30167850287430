import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
  ProFormDigit,
} from '@ant-design/pro-components'
import {
  useCreateTaxMutation,
  TaxCreateInput,
  TaxDetailFragment,
} from '../../graphql/_generated-hooks'
import { Button, Form, message } from 'antd'
import React, { useMemo, useRef, useState } from 'react'

import { TaxFormDrawerStyled } from './styles'

export interface TaxFormDrawerProps {
  onTaxCreated?: (taxObj: TaxDetailFragment) => void
  onClose?: () => void
  open?: boolean
}

const waitTime = (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}

export const TaxFormDrawer = ({
  onTaxCreated = () => {},
  onClose,
  open,
}: TaxFormDrawerProps) => {
  const [form] = Form.useForm<{ name: string; company: string }>()
  const [createTaxMutation] = useCreateTaxMutation()

  const handleCreateTax = async (values: TaxCreateInput) => {
    createTaxMutation({
      variables: {
        input: { ...values, inclusive: true },
      },
    }).then(({ data }: any) => {
      const {
        createTax: { data: taxObj },
      } = data
      onTaxCreated(taxObj)
      message.success('Success')
    })
  }

  return (
    <TaxFormDrawerStyled>
      <DrawerForm<TaxCreateInput>
        title='Create Tax'
        form={form}
        open={open}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          maskClosable: false,
          onClose,
          width: 515,
        }}
        submitTimeout={2000}
        onFinish={handleCreateTax}
        submitter={{
          searchConfig: {
            submitText: 'Create',
            resetText: 'Cancel',
          },
        }}
      >
        <ProForm.Group>
          <ProFormText
            name='display_name'
            width='md'
            rules={[
              {
                required: true,
              },
            ]}
            label='Name'
            placeholder='GST'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormDigit
            rules={[
              {
                required: true,
              },
            ]}
            width='md'
            name='percentage'
            label='Percentage'
            placeholder='10%'
          />
        </ProForm.Group>
      </DrawerForm>
    </TaxFormDrawerStyled>
  )
}

TaxFormDrawer.displayName = 'TaxFormDrawer'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components'
import { useGetDistributorsQuery } from '../../graphql/_generated-hooks'
import { Button, Form, Typography, message } from 'antd'
import type { FormInstance } from 'antd/es/form'
import React, { useMemo, useRef, useState } from 'react'

import { DistributorSelectOrCreateStyled } from './styles'
import { DistributorSearchSelect } from '../DistributorSearchSelect'

export interface DistributorSelectOrCreateProps {
  onFinish?: (formData: DistributorSelectOrCreateSchema) => Promise<void>
  onClose?: () => void
  onValuesChange?: (changedValues: any, values: any) => void
  showForm?: boolean
  parentForm?: FormInstance
}

export interface DistributorSelectOrCreateSchema {
  id?: string
  name: string
  email?: string
  phone?: string
  description?: string
  address_city?: string
  address_country?: string
  address_line1?: string
  address_line2?: string
  address_postal_code?: string
  address_state?: string
  selectValue?: { value: string; label: string }
}

export const DistributorSelectOrCreate = ({
  onFinish,
  onClose,
  showForm = false,
  parentForm = {} as FormInstance,
  onValuesChange = () => {},
}: DistributorSelectOrCreateProps) => {
  const [form] = Form.useForm<DistributorSelectOrCreateSchema>(parentForm)
  const [showDistributorForm, setShowDistributorForm] = React.useState(false)

  const [recentlyCreatedDistributor, setRecentlyCreatedDistributor] =
    React.useState<any[]>([])

  const {
    data: { distributors = [] } = { distributors: [] },
    loading: isLoadingDistributors,
    refetch: refetchDistributors,
    updateQuery: updateDistributors,
  } = useGetDistributorsQuery({ variables: { input: { search: '' } } })

  const handleFetchDistributors = async (value: string): Promise<any> => {
    return refetchDistributors({ input: { search: value } }).then(
      ({ data }: any) => {
        if (!data?.distributors) return []
        return data.distributors.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      }
    )
  }

  const handleNewDistributorSelectButtonClick = () => {
    setShowDistributorForm(true)
  }

  const handleCreateDistributor = async (
    values: DistributorSelectOrCreateSchema
  ) => {
    // createOptimizePriceMutation({
    //   variables: {
    //     input: values,
    //   },
    // }).then((data: any) => {
    //   console.log('DATA ', data)
    //   message.success('Success')
    //   onOptimizePriceCreated(data)
    // })
  }

  return (
    <DistributorSelectOrCreateStyled>
      {showDistributorForm && (
        <>
          <Typography.Title
            level={5}
            style={{ margin: '0 0 1em 0', display: 'inline-block' }}
          >
            New Vendor
          </Typography.Title>
          <Button
            size='small'
            type='text'
            style={{ float: 'right' }}
            onClick={() => setShowDistributorForm(false)}
          >
            Cancel
          </Button>
        </>
      )}

      <ProForm<DistributorSelectOrCreateSchema>
        form={form}
        onValuesChange={onValuesChange}
        // Setting component false will prevent form from rendering
        // should only render if no parent form exists
        component={!parentForm}
        onFinish={onFinish}
        submitter={{
          render: (props, doms) => null,
        }}
      >
        {showDistributorForm && (
          <>
            <ProForm.Group>
              <ProFormText
                name={['distributor', 'name']}
                width='xl'
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Name'
                placeholder='Company ltd.'
              />
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText
                name={['distributor', 'email']}
                width='xl'
                label='Email'
                placeholder='example@company.com'
              />
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText
                name={['distributor', 'phone']}
                width='xl'
                label='Phone'
                placeholder='555 520 4239'
              />
            </ProForm.Group>
          </>
        )}

        {!showDistributorForm && (
          <DistributorSearchSelect
            style={{ width: '380px' }}
            formItemProps={{
              label: 'Vendor',
              name: ['distributor', 'selectValue'],
              rules: [
                {
                  required: true,
                },
              ],
            }}
            onAddClick={handleNewDistributorSelectButtonClick}
          />
        )}
      </ProForm>
    </DistributorSelectOrCreateStyled>
  )
}

DistributorSelectOrCreate.displayName = 'DistributorSelectOrCreate'

import { rem, tint } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const EstimatesDetailViewStyled = styled.div`
  font-size: ${rem(16)};
  max-width: 1500px;
  min-width: 1000px;

  .selectable {
    cursor: pointer;
  }

  .selected {
    background-color: ${(props) => tint(0.9, props.theme.colors.primary)};

    &:hover td {
      background-color: ${(props) =>
        tint(0.9, props.theme.colors.primary)} !important;
    }
  }

  .content-container {
    padding: 2em;
    background: #fff;
  }
  .list-header {
    opacity: 0.5;
  }
`

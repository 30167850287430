import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const PreferenceEstimatesViewStyled = styled.div`
  font-size: ${rem(16)};

  .hex-input {
    text-transform: uppercase;
  }
`

import { Typography, Space } from 'antd'
import * as React from 'react'

import { CustomerDetailFragment } from '../../graphql/_generated-hooks'
import { AddressDisplayStyled } from './styles'

const { Text } = Typography

export interface AddressDisplayProps {
  address: CustomerDetailFragment
}

export const AddressDisplay = ({ address = {} }: AddressDisplayProps | any) => {
  const {
    address_line1,
    address_line2,
    address_city,
    address_country,
    address_state,
    address_postal_code,
  } = address

  return (
    <AddressDisplayStyled>
      <Space direction='vertical'>
        <Text data-testid='address-line1-display'>{address_line1}</Text>
        <Text data-testid='address-line2-display'>{address_line2}</Text>
        <Text data-testid='address-city-state-display'>
          {address_city && `${address_city},`} {address_state}{' '}
          {address_postal_code}
        </Text>
      </Space>
    </AddressDisplayStyled>
  )
}

AddressDisplay.displayName = 'AddressDisplay'

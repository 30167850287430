import {
  Modal,
  Row,
  Col,
  Typography,
  Form,
  Space,
  Divider,
  Input,
  message,
} from 'antd'
import * as React from 'react'
import {
  useParams,
  useSearchParams,
  redirect,
  useNavigate,
} from 'react-router-dom'

import { useDuplicateEstimateMutation } from '../../graphql/_generated-hooks'
import { setApiFormErrors, setGraphQLFormErrors } from '../../utils'

import { EstimateDuplicateModalStyled } from './styles'
export interface EstimateDuplicateModalProps {
  open?: boolean
  estimateObj?: any
  onListSelect?: (template?: any) => void
  onCancel?: () => void
  onDuplicateSuccess?: (id: string) => void
}

export const EstimateDuplicateModal = ({
  open,
  estimateObj = {},
  onListSelect = () => {},
  onCancel,
  onDuplicateSuccess = () => {},
}: EstimateDuplicateModalProps) => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()
  const [isNavigating, setIsNavigating] = React.useState(false)

  const [duplicateEstimate, { data, loading: isDuplicatingEstimate, error }] =
    useDuplicateEstimateMutation()

  const handleOnOk = async () => {
    const values = await form.validateFields()
    // Submit
    try {
      duplicateEstimate({
        variables: {
          id: estimateObj?.id,
          input: {
            number: values.number,
          },
        },
        onCompleted({ duplicateEstimate }: any = {}) {
          setIsNavigating(true)
          messageApi.success('Success: Estimate duplicated successfully!')

          if (duplicateEstimate?.data?.id) {
            onDuplicateSuccess(duplicateEstimate?.data?.id)
            setTimeout(() => {
              navigate(`/estimates/${duplicateEstimate.data.id}`, {
                replace: true,
              })
              setIsNavigating(false)
            }, 1500)
          }
        },
        onError(error, clientOptions) {
          if (error?.graphQLErrors?.[0]?.message) {
            messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
          } else {
            messageApi.error('An error occurred. Please try again.')
          }

          setApiFormErrors(error.graphQLErrors, form)
        },
      })
    } catch (errInfo: any) {
      // GraphQL validation errors will catch here
      if (errInfo && errInfo?.errorFields?.length) {
        messageApi.error(
          `An error occurred: ${errInfo.errorFields[0]?.errors[0]}`
        )
      }
    }
  }

  return (
    <EstimateDuplicateModalStyled>
      {contextHolder}
      <Modal
        title={`Duplicate Estimate #${estimateObj?.number}`}
        okText='Duplicate'
        open={open}
        onOk={handleOnOk}
        onCancel={onCancel}
        confirmLoading={isDuplicatingEstimate || isNavigating}
      >
        <Form
          form={form}
          initialValues={{
            number: `${estimateObj?.number}-COPY` || null,
          }}
          name='dynamic_form_complex'
          layout='vertical'
        >
          <Row style={{ marginTop: '2em' }}>
            <Col span={24}>
              <Form.Item
                name={'number'}
                label='Estimate Number'
                rules={[
                  {
                    required: true,
                    message: 'Missing estimate number',
                  },
                ]}
              >
                <Input placeholder={'estimate123'} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </EstimateDuplicateModalStyled>
  )
}

EstimateDuplicateModal.displayName = 'EstimateDuplicateModal'

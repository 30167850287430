import { rem, tint } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const CustomerListViewStyled = styled.div`
  font-size: ${rem(16)};

  .create-btn-container {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }

  .selectable {
    cursor: pointer;
  }

  .ant-list {
    background: #fff;
  }

  .ant-list-item {
    cursor: pointer;

    &:hover {
      background-color: ${(props) => tint(0.9, props.theme.colors.primary)};
    }
  }

  .ant-list-item .ant-list-item-meta-title {
    margin: 0px !important;
  }

  .selected {
    background-color: ${(props) => tint(0.9, props.theme.colors.primary)};

    &:hover td {
      background-color: ${(props) =>
        tint(0.9, props.theme.colors.primary)} !important;
    }
  }
`

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const TaxFormDrawerStyled = styled.div`
  font-size: ${rem(16)};

  display: flex;
  align-items: center;
`

import ImgCrop from 'antd-img-crop'
import { Upload } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import React, { useMemo, useRef, useState } from 'react'
import { useAuth } from '@clerk/clerk-react'
import debounce from 'lodash/debounce'

import { UploadLogoStyled } from './styles'
import { MAIN_CURRENCIES, OTHER_CURRENCIES } from '../../config/default'

export interface UploadLogoProps {
  uploadUrl?: string
  logo?: string | null
  sasToken?: string | null
  onRemove?: () => void
}

const { NODE_ENV, REACT_APP_API_URL } = process.env

export const UploadLogo = ({
  uploadUrl = `${REACT_APP_API_URL}/api/logo/upload`,
  logo,
  sasToken,
  onRemove = () => {},
}: UploadLogoProps) => {
  const { sessionId, isLoaded, getToken } = useAuth()
  const [uploadHeaders, setUploadHeaders] = React.useState<any>({})
  const [fileList, setFileList] = useState<UploadFile[]>([])

  React.useEffect(() => {
    if (!logo) return
    setFileList([
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: `${logo}?${sasToken}`,
      },
    ])
  }, [logo])

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }
  const beforeUpload: UploadProps['beforeUpload'] = async (file) => {
    await getToken({ template: 'volt-jwt-template' }).then(async (token) => {
      setUploadHeaders({
        authorization: `Bearer ${token}`,
      })

      // Hacky af - need to wait for state to be set
      await timeout(200)
    })

    return true
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as RcFile)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  return (
    <UploadLogoStyled>
      {/* <ImgCrop showGrid> */}
      <Upload
        name='logo_upload'
        listType='picture-card'
        action={uploadUrl}
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        headers={uploadHeaders}
        accept='image/png, image/jpeg, image/jpg, image/gif'
      >
        {fileList.length < 1 && '+ Upload'}
      </Upload>
      {/* </ImgCrop> */}
    </UploadLogoStyled>
  )
}

UploadLogo.displayName = 'UploadLogo'

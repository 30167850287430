import { Alert, Tag, Badge } from 'antd'
import * as React from 'react'

import { PageBannerStyled } from './styles'

export interface PageBannerProps {
  icon?: React.ReactNode
  message?: string
  description?: string
  type?: 'success' | 'info' | 'warning' | 'error'
  action?: React.ReactNode
}

export const PageBanner = ({
  icon,
  message,
  description,
  type = 'warning',
  action,
}: PageBannerProps) => {
  return (
    <PageBannerStyled>
      <Alert
        className='hidden-print'
        showIcon={!!icon}
        icon={icon}
        message={message}
        description={description}
        type={type}
        action={<div className='action-container'>{action}</div>}
      />
    </PageBannerStyled>
  )
}

PageBanner.displayName = 'PageBanner'

import { ThunderboltFilled } from '@ant-design/icons'
import * as React from 'react'

import { motion, useAnimation, Variants } from 'framer-motion'

interface AnimatedComponentProps {
  children?: React.ReactNode
  customVariants?: Variants
}

const defaultVariants: Variants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
}

export const AnimatedComponent = ({
  children,
  customVariants,
  ...props
}: AnimatedComponentProps) => {
  const controls = useAnimation()

  React.useEffect(() => {
    controls.start('visible')
  }, [controls])

  const variants = customVariants || defaultVariants

  return (
    <motion.div initial='hidden' animate={controls} variants={variants}>
      {children}
    </motion.div>
  )
}

AnimatedComponent.displayName = 'AnimatedComponent'

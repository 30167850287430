import { FileText, List, Layers, Users, Lock } from 'react-feather'
import { LockOutlined } from '@ant-design/icons'
import { Tag, Tooltip, Button } from 'antd'
import type { ButtonProps } from 'antd'
import * as React from 'react'

import { LockableButtonStyled } from './styles'
import { capitalizeString } from '../../utils'

export interface LockableButtonProps extends ButtonProps {
  locked?: boolean
  reason?: string
  children?: React.ReactNode
}

export const LockableButton = ({
  locked,
  reason,
  children,
  ...props
}: LockableButtonProps) => {
  return (
    <LockableButtonStyled>
      {locked && (
        <Tooltip title={reason}>
          <Button {...props} disabled={locked}>
            <LockOutlined />
            {children}
          </Button>
        </Tooltip>
      )}

      {!locked && <Button {...props}>{children}</Button>}
    </LockableButtonStyled>
  )
}

LockableButton.displayName = 'LockableButton'

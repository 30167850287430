import {
  CheckCircleOutlined,
  StopOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Table, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import * as React from 'react'

interface DataType {
  key: React.Key
  name: string
  description: string
  created: boolean
  skipped: boolean
  saved_price_to: string
}

import { MultiStepMaterialDrawerSummaryStyled } from './styles'
export interface MultiStepMaterialDrawerSummaryProps {
  items?: any[]
  onRowClick?: (record: any, rowIndex: number | undefined) => void
}

export const MultiStepMaterialDrawerSummary = ({
  items = [],
  onRowClick = () => {},
}: MultiStepMaterialDrawerSummaryProps) => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'Item',
      dataIndex: ['properties', 'description', 'value'],
      ellipsis: true,
    },
    {
      title: 'Unit Cost',
      dataIndex: ['properties', 'unitPrice', 'value', 'amount'],
      align: 'right',
      render: (text: any) => {
        return text ? `$${text?.toFixed(2)}` : ''
      },
    },
    {
      title: 'Status',
      align: 'center',
      render: (_, record) => {
        let tagComponent = (
          <Tag color='default' icon={<StopOutlined />}>
            skipped
          </Tag>
        )

        if (record.saved_price_to) {
          tagComponent = (
            <Tag color='cyan' icon={<SaveOutlined />}>
              price saved
            </Tag>
          )
        } else if (record.created) {
          tagComponent = (
            <Tag color='green' icon={<CheckCircleOutlined />}>
              item added
            </Tag>
          )
        }

        return tagComponent
      },
    },
  ]

  return (
    <MultiStepMaterialDrawerSummaryStyled>
      <Table
        columns={columns}
        dataSource={items}
        size='small'
        pagination={false}
        rowKey={(record) => {
          return `summary_item_${Math.random().toString()}`
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowClick(record, rowIndex)
            },
          }
        }}
      />
    </MultiStepMaterialDrawerSummaryStyled>
  )
}

MultiStepMaterialDrawerSummary.displayName = 'MultiStepMaterialDrawerSummary'

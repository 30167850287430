import { getDecimalPortionOfNumber, roundNumber } from './index'

export const calculateAreaMultiple = (
  materialListArea: number,
  itemCoverageRate: number
) => {
  if (itemCoverageRate && !isNaN(itemCoverageRate) && itemCoverageRate !== 0) {
    return roundNumber(materialListArea / itemCoverageRate, 2)
  } else {
    return 1
  }
}

const calculateWastageQuantity = (itemCalculatedQuantity: number) => {
  // Just take the decimal portion of the number and subtract it from 1 whole quantity to get the difference
  const quantityWasted = roundNumber(
    1 - getDecimalPortionOfNumber(itemCalculatedQuantity)
  )

  return quantityWasted === 1 ? 0 : quantityWasted // No wastage if qty is 1
}

const calculateWastageTotalPercentage = (
  wastageQuantity: number,
  totalQuantityNeeded: number
) => {
  return roundNumber(wastageQuantity / totalQuantityNeeded, 4)
}

export const calculateAreaLineItemQuantity = (
  materialListArea: number,
  itemCoverageRate: number,
  itemQuantity: number,
  isSingleUseItem?: boolean
) => {
  let returnObj: any = {
    wastage_amount: null,
    wastage_percentage: null,
    quantity: 0,
  }
  let itemMultiple = calculateAreaMultiple(materialListArea, itemCoverageRate)
  let itemCalculatedQuantity = roundNumber(itemQuantity * itemMultiple, 2)

  if (isSingleUseItem) {
    let wastageQuantity = calculateWastageQuantity(itemCalculatedQuantity)
    let totalQuantityNeeded = Math.ceil(itemCalculatedQuantity)
    // Calculate wastage here
    returnObj.wastage_amount = wastageQuantity
    returnObj.wastage_percentage = calculateWastageTotalPercentage(
      wastageQuantity,
      totalQuantityNeeded
    )
    returnObj.quantity = totalQuantityNeeded
  } else {
    returnObj.quantity = itemCalculatedQuantity
  }

  return returnObj
}

export const calculateMaterialListQtyForEstimateForm = (
  lineItems: any,
  materialListQty: number, // Should be either qty (1, 2) or unit of measure (sqft, sqm, etc.)
  unit_type: 'area' | 'each'
) => {
  switch (unit_type) {
    case 'each':
      return lineItems?.map((lineItem: any) => {
        return {
          ...lineItem,
          quantity: roundNumber(lineItem.quantity * materialListQty, 2),
        }
      })
    case 'area':
      return lineItems?.map((lineItem: any) => {
        const isSingleUseItem = lineItem.products?.[0]?.single_use
        const { quantity, wastage_amount, wastage_percentage } =
          calculateAreaLineItemQuantity(
            materialListQty,
            lineItem.coverage_rate,
            lineItem.quantity,
            isSingleUseItem
          )
        return {
          ...lineItem,
          quantity,
          ...(wastage_amount && {
            wastage_amount,
          }),
          ...(wastage_percentage && {
            wastage_percentage,
          }),
        }
      })
  }
}

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components'
import {
  CustomerDetailFragment,
  useGetCustomersQuery,
} from '../../graphql/_generated-hooks'
import { Button, Form, Typography, message } from 'antd'
import type { FormInstance } from 'antd/es/form'
import React, { useMemo, useRef, useState } from 'react'

import { CustomerSelectStyled } from './styles'
import { SearchSelect } from '../SearchSelect'

export interface CustomerSelectProps {
  onClose?: () => void
  onAddClick?: () => void
  onCustomerChange?: (
    id: string,
    customerObject: CustomerDetailFragment | undefined | null
  ) => void
  showForm?: boolean
  parentForm?: FormInstance
  createButtonVisible?: boolean
  createButtonText?: string
  createdCustomerObj?: any
  initialCustomerObj?: CustomerDetailFragment | null
}

export interface CustomerSelectSchema {
  id?: string
  name: string
  email?: string
  phone?: string
  address_city?: string
  address_country?: string
  address_line1?: string
  address_line2?: string
  address_postal_code?: string
  address_state?: string
}

export const CustomerSelect = ({
  onClose,
  onCustomerChange = () => {},
  showForm = false,
  parentForm = {} as FormInstance,
  createdCustomerObj,
  initialCustomerObj,
  ...props
}: CustomerSelectProps) => {
  const [customersList, setCustomersList] = React.useState<
    CustomerDetailFragment[]
  >(initialCustomerObj ? [initialCustomerObj] : [])

  const [recentlyCreatedCustomer, setRecentlyCreatedCustomer] = React.useState<
    any[]
  >([])

  React.useEffect(() => {
    if (createdCustomerObj) {
      let foundExistingCustomerOption = customersList?.find(
        (item: any) => item.id === createdCustomerObj.id
      )
      if (!foundExistingCustomerOption) {
        setCustomersList([...customersList, createdCustomerObj])
        setRecentlyCreatedCustomer([
          {
            value: createdCustomerObj.id,
            label: createdCustomerObj.name,
          },
        ])
      }
    }
  }, [createdCustomerObj])

  const {
    data: { customers = [] } = { customers: [] },
    loading: isLoadingCustomers,
    refetch: refetchCustomers,
    updateQuery: updateCustomers,
  } = useGetCustomersQuery({
    variables: { input: { search: '', limit: 100 } },
    onCompleted: ({ customers }) => {
      if (customers && customers.data?.length) {
        //@ts-ignore
        setCustomersList(customers.data)
      }
    },
  })

  const handleFetchCustomers = async (value: string): Promise<any> => {
    return refetchCustomers({ input: { search: value } }).then(
      ({ data }: any) => {
        if (data && data.customers && data.customers.data?.length) {
          return data.customers.data.map((item: any) => ({
            value: item.id,
            label: item.name,
          }))
        } else {
          return []
        }
      }
    )
  }

  return (
    <CustomerSelectStyled>
      <SearchSelect
        data-testid='customer-select-input'
        onSelect={(value: any) => {
          onCustomerChange(
            value,
            customersList?.find((item: any) => item.id === value)
          )
        }}
        placeholder='Select customer'
        fetchOptions={handleFetchCustomers}
        createdOptions={recentlyCreatedCustomer}
        initialOptions={customersList?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))}
        {...props}
      />
    </CustomerSelectStyled>
  )
}

CustomerSelect.displayName = 'CustomerSelect'

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const AutoSavingLabelStyled = styled.div`
  .error-text,
  .error-text .ant-typography {
    color: ${(props) => props.theme.colors.error};
  }
`

import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Tag, Space } from 'antd'
import * as React from 'react'

import { LogoDisplayStyled } from './styles'
export interface LogoDisplayProps {
  width?: number
  height?: number
  logo?: string | null
  sasToken?: string | null
  emptyText?: string
  emptyDescription?: React.ReactNode
}

export const LogoDisplay = ({
  width = 160,
  height = 160,
  logo,
  sasToken,
  emptyText = 'No logo',
  emptyDescription,
  ...props
}: LogoDisplayProps) => {
  let url
  if (logo && sasToken) {
    url = `${logo}?${sasToken}`
  } else if (logo) {
    url = logo
  }

  return (
    <LogoDisplayStyled {...props}>
      {url && (
        <img
          style={{ maxWidth: width, maxHeight: height }}
          src={url}
          alt='logo'
        />
      )}

      {!url && (
        <div className='logo-display-placeholder'>
          <Space direction='vertical'>
            <Typography.Text type='secondary'>{emptyText}</Typography.Text>
            {emptyDescription && emptyDescription}
          </Space>
        </div>
      )}
    </LogoDisplayStyled>
  )
}

LogoDisplay.displayName = 'LogoDisplay'

import {
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Form,
  InputNumber,
  Input,
} from 'antd'
import * as React from 'react'

import { useGetProductListsQuery } from '../../graphql/_generated-hooks'

import { QUANTITY_PRECISION, MONEY_PRECISION } from '../../config/default'
import {
  roundNumber,
  calculateMaterialListQtyForEstimateForm,
} from '../../utils'
import { SearchSelect } from '../SearchSelect'
import { MaterialListSelectorModalStyled } from './styles'
export interface MaterialListSelectorModalProps {
  open?: boolean
  onListSelect?: (template?: any, customName?: string) => void
  onCancel?: () => void
}

export const MaterialListSelectorModal = ({
  open,
  onListSelect = () => {},
  onCancel,
}: MaterialListSelectorModalProps) => {
  const [form] = Form.useForm<any>()
  const [selectedTemplate, setSelectedTemplate] = React.useState<any>({})

  const {
    data: { productLists = { data: [], count: 0 } } = {
      productLists: { data: [], count: 0 },
    },
    loading: isLoadingProductList,
    refetch: refetchProductList,
    updateQuery: updateProductList,
  } = useGetProductListsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        limit: 25,
      },
    },
  })

  const handleChange = (value: any) => {
    let foundList = productLists?.data?.find((item: any) => item.id === value)

    if (foundList) {
      form.setFieldsValue({ quantity: foundList.unit_type == 'area' ? 500 : 1 })
      setSelectedTemplate(foundList)
    }
  }

  const handleFetchProductLists = async (value: string): Promise<any> => {
    return refetchProductList({ input: { search: value, limit: 100 } }).then(
      ({ data }: any) => {
        return data?.productLists?.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      }
    )
  }
  const handleOnOk = async () => {
    try {
      const { quantity } = await form.validateFields()

      onListSelect(
        {
          ...selectedTemplate,
          line_items: calculateMaterialListQtyForEstimateForm(
            selectedTemplate.line_items,
            quantity,
            selectedTemplate.unit_type
          ),
        },
        `${selectedTemplate.name} x ${quantity}${
          selectedTemplate.unit_type == 'area' ? 'sqft' : ''
        }` // TODO Temporary until org setting
      )
    } catch (error) {}
  }

  return (
    <MaterialListSelectorModalStyled>
      <Modal
        title='Add Assembly'
        okText='Add'
        open={open}
        onOk={handleOnOk}
        onCancel={onCancel}
      >
        <Form form={form}>
          <Row style={{ marginTop: '2em' }}>
            <Col span={24}>
              <Form.Item
                name={'product_list'}
                rules={[
                  {
                    required: true,
                    message: 'Please select assembly',
                  },
                ]}
              >
                <SearchSelect
                  style={{ width: '100%' }}
                  defaultActiveFirstOption
                  placeholder='Select assembly'
                  fetchOptions={handleFetchProductLists}
                  createButtonVisible={false} // This currently breaks the list and doesn't use the correct product create path
                  initialOptions={productLists?.data?.map((item: any) => {
                    return {
                      value: item.id,
                      label: item.name,
                    }
                  })}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {selectedTemplate && (
            <Row style={{ marginTop: '1em' }}>
              <Col span={24}>
                <Form.Item
                  name={'quantity'}
                  label={
                    selectedTemplate.unit_type == 'area'
                      ? 'Total area'
                      : 'Number of assemblies'
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Missing quantity',
                    },
                  ]}
                  initialValue={1}
                >
                  <InputNumber
                    precision={0}
                    placeholder='Quantity'
                    style={{ width: '100%' }}
                    min={
                      selectedTemplate.unit_type == 'area'
                        ? selectedTemplate.unit_amount_per_assembly
                        : 1
                    }
                    step={selectedTemplate.unit_type == 'area' ? 10 : 1}
                    suffix={
                      selectedTemplate.unit_type == 'area' ? 'sqft' : '' // TODO Temporary until org setting
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedTemplate?.description && (
            <Row style={{ marginTop: '1em' }}>
              <Col span={24}>
                <Space direction='vertical'>
                  <Typography.Text type='secondary'>
                    {selectedTemplate.description}
                  </Typography.Text>
                </Space>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </MaterialListSelectorModalStyled>
  )
}

MaterialListSelectorModal.displayName = 'MaterialListSelectorModal'

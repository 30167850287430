import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const ListButtonBarStyled = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 2em;
`

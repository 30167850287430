import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const CurrentPlanInfoBoxStyled = styled.div<{ $addmargin?: boolean }>`
  > .content {
    text-align: center;
    margin: ${(props) => (props.$addmargin ? '24px' : '0px')};
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 5px;
    padding: ${rem(10)};
  }

  .text-gradient {
    background: linear-gradient(
      45deg,
      ${(props) => props.theme.colors.success},
      ${(props) => props.theme.colors.accent}
    );
    -webkit-background-clip: text; /* Apply background-clip to text for WebKit browsers */
    background-clip: text; /* Apply background-clip to text */
    color: transparent; /* Make text transparent */
  }
`

import { Award, ArrowUpCircle } from 'react-feather'
import { Tag, Popover, Typography, Progress, Space, Tooltip, Flex } from 'antd'
import * as React from 'react'

import type { ProgressProps } from 'antd'

import { theme } from '../../styles/themes/default'
import { CurrentPlanInfoBoxStyled } from './styles'
import { calculatePercentage } from '../../utils'
import { m } from 'framer-motion'

export interface CurrentPlanInfoBoxProps {
  popoverMode?: boolean
  plan?: string | null
  materialCount?: number
  materialTotal?: number
  estimateCount?: number
  estimateTotal?: number
  upgradeButton?: React.ReactNode
}

export const CurrentPlanInfoBox = ({
  popoverMode,
  plan,
  upgradeButton,
  materialCount = 0,
  materialTotal = 50,
  estimateCount = 0,
  estimateTotal = 1,
}: CurrentPlanInfoBoxProps) => {
  const getColorsObj = (percentage: number) => {
    let colorsObj: ProgressProps['strokeColor'] = {
      '0%': theme.colors.success,
      '100%': theme.colors.success,
    }

    if (percentage >= 100) {
      colorsObj = theme.colors.accent
    } else if (percentage > 50) {
      colorsObj['0%'] = theme.colors.success
      colorsObj['100%'] = theme.colors.accent
    }

    return colorsObj
  }

  const getEstimateCount = () => {
    const estimatePercentage = calculatePercentage(estimateCount, estimateTotal)
    return (
      <div>
        <Typography.Text style={{ color: theme.colors.lightGrey }}>
          {estimateCount}/{estimateTotal} Estimate
          {estimateTotal > 1 ? 's' : ''}
        </Typography.Text>
        <Progress
          size='small'
          percent={estimatePercentage}
          strokeColor={getColorsObj(estimatePercentage)}
          showInfo={false}
        />
      </div>
    )
  }
  const getMaterialCount = () => {
    let materialPercentage = calculatePercentage(materialCount, materialTotal)
    return (
      <div>
        <Typography.Text style={{ color: theme.colors.lightGrey }}>
          {materialCount}/{materialTotal} Material
          {materialTotal > 1 ? 's' : ''}
        </Typography.Text>
        <Progress
          size='small'
          percent={materialPercentage}
          strokeColor={getColorsObj(materialPercentage)}
          showInfo={false}
        />
      </div>
    )
  }
  const getContent = (showTooltips: boolean) => {
    return (
      <CurrentPlanInfoBoxStyled $addmargin={showTooltips}>
        <div className='content'>
          {plan && (
            <div>
              <Typography.Text type='secondary'>
                You are on the:
              </Typography.Text>
            </div>
          )}
          <Typography.Text strong className={plan ? 'text-gradient' : ''}>
            {plan ? 'Basic plan' : 'Free plan'}
          </Typography.Text>

          {!plan && (
            <div style={{ marginTop: '10px' }}>
              {showTooltips ? (
                <Tooltip
                  title={`The free plan includes ${estimateTotal} free estimate${
                    estimateTotal > 1 ? 's' : ''
                  } per month`}
                  placement='right'
                >
                  {getEstimateCount()}
                </Tooltip>
              ) : (
                getEstimateCount()
              )}
              {showTooltips ? (
                <Tooltip
                  title={`The free plan includes ${materialTotal} free materials total`}
                  placement='right'
                >
                  {getMaterialCount()}
                </Tooltip>
              ) : (
                getMaterialCount()
              )}

              {upgradeButton && upgradeButton}
            </div>
          )}
        </div>
      </CurrentPlanInfoBoxStyled>
    )
  }

  return (
    <>
      {!popoverMode && getContent(!popoverMode)}
      {popoverMode && (
        <Popover content={getContent(!popoverMode)} placement='right'>
          {plan ? (
            <Award color={theme.colors.success} size={16} />
          ) : (
            <ArrowUpCircle color={theme.colors.accent} size={16} />
          )}
        </Popover>
      )}
    </>
  )
}

CurrentPlanInfoBox.displayName = 'CurrentPlanInfoBox'

import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Tag } from 'antd'
import * as React from 'react'

import { PageTitleStyled } from './styles'
export interface PageTitleProps {
  title: string
  subtitle?: string
  badge?: React.ReactNode
}

export const PageTitle = ({ title, subtitle, badge }: PageTitleProps) => {
  return (
    <PageTitleStyled>
      <Typography.Title
        level={4}
        style={{ margin: 0 }}
        data-testid='page-detail-title'
      >
        {title} {badge && badge}
      </Typography.Title>
      {subtitle && (
        <Typography.Text
          style={{ marginTop: '0.5em' }}
          type='secondary'
          data-testid='page-detail-subtitle'
        >
          {subtitle}
        </Typography.Text>
      )}
    </PageTitleStyled>
  )
}

PageTitle.displayName = 'PageTitle'

import {
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Typography, Space, Button, Divider, Input } from 'antd'
import * as React from 'react'

import { MaterialFormSectionRowTitleStyled } from './styles'
import { padding } from 'polished'

const DEFAULT_TITLE_TEXT = 'New Section'

export interface MaterialFormSectionRowTitleProps {
  sectionTitle?: string
  viewOnly?: boolean
  onRemove?: () => void
  onAdd?: () => void
  onChange?: (value: string) => void
}

export const MaterialFormSectionRowTitle = ({
  sectionTitle = DEFAULT_TITLE_TEXT,
  viewOnly,
  onRemove = () => {},
  onAdd,
  onChange = () => {},
}: MaterialFormSectionRowTitleProps) => {
  const [showActionMenu, setShowActionMenu] = React.useState<any>(false)
  const [editingMode, setEditingMode] = React.useState<any>(false)
  const [sectionTitleContent, setSectionTitleContent] =
    React.useState<any>(sectionTitle)

  React.useEffect(() => {
    // Recognize initial state as an update
    onChange(sectionTitle)
  }, [])

  let backspaceCount = 0

  return (
    <MaterialFormSectionRowTitleStyled>
      {!viewOnly && (
        <span
          style={{ paddingLeft: '1em' }}
          onMouseOver={() => setShowActionMenu(true)}
          onMouseOut={() => setShowActionMenu(false)}
        >
          {editingMode && (
            <Input
              autoFocus
              defaultValue={sectionTitleContent}
              placeholder='New Section'
              bordered={false}
              onKeyUp={(e) => {
                if (e.key === 'Enter' || e.key === 'Escape') {
                  setEditingMode(false)
                  setShowActionMenu(false)
                } else if (
                  e.key === 'Backspace' &&
                  sectionTitleContent === ''
                ) {
                  backspaceCount++
                  if (backspaceCount === 2) {
                    backspaceCount = 0
                    onRemove()
                  }
                }
              }}
              onBlur={() => {
                setEditingMode(false)
                setShowActionMenu(false)
              }}
              onChange={(e) => {
                setSectionTitleContent(e.target.value)
                onChange(e.target.value)
              }}
              style={{ paddingLeft: 0 }}
            />
          )}
          {!editingMode && (
            <Button
              type='link'
              onClick={() => setEditingMode(true)}
              style={{ paddingLeft: 0 }}
            >
              {sectionTitleContent}
              <EditOutlined />
            </Button>
          )}
          {showActionMenu && !editingMode && (
            <Space>
              {onAdd && (
                <Button
                  type='link'
                  size='small'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    onAdd()
                  }}
                />
              )}
              {onRemove && (
                <Button
                  type='link'
                  size='small'
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onRemove()
                  }}
                />
              )}
            </Space>
          )}
        </span>
      )}
      {viewOnly && (
        <>
          <Typography.Text type='secondary' style={{ paddingLeft: 0 }}>
            {sectionTitleContent}
          </Typography.Text>
        </>
      )}
      <Divider />
    </MaterialFormSectionRowTitleStyled>
  )
}

MaterialFormSectionRowTitle.displayName = 'MaterialFormSectionRowTitle'

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const AppContentStyled = styled.div`
  font-size: ${rem(16)};

  grid-area: content;

  display: flex;
  height: 100%;
  justify-content: center;

  overflow: auto;
`

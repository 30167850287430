import { LoadingOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons'
import { Select, Button, Divider, Space } from 'antd'
import React, { useMemo, useRef, useState } from 'react'
import type { SelectProps } from 'antd'
import { debounce } from 'lodash'

import { CategorySelectStyled } from './styles'
import { filterArrayByObjId } from '../../utils'
import { useGetCategoriesQuery } from '../../graphql/_generated-hooks'

export interface CategorySelectProps {
  status?: string
}

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  // fetchOptions?: (search: string) => Promise<ValueType[]>
  onAddClick?: () => void
  debounceTimeout?: number
  createButtonVisible?: boolean
  createButtonText?: string
  emptyText?: string
  recentlyCreatedCategory?: ValueType[]
}

export const CategorySelect = <
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any
>({
  // fetchOptions,
  debounceTimeout = 300,
  createButtonVisible,
  createButtonText,
  emptyText = 'No results found',
  recentlyCreatedCategory = [],
  onAddClick = () => {},
  ...props
}: DebounceSelectProps<ValueType>) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  React.useEffect(() => {
    if (recentlyCreatedCategory && recentlyCreatedCategory.length) {
      setOptions(
        filterArrayByObjId(options.concat(recentlyCreatedCategory), 'value')
      )
    }
  }, [recentlyCreatedCategory])

  const formatCategoryOptions = (categories: any) => {
    if (!categories || !categories.length) return []

    return categories.map((category: any) => ({
      key: category.id,
      label: category.name,
      value: category.id,
    }))
  }

  const {
    data: { categories = { data: [] } } = { categories: { data: [] } },
    refetch: refetchCategories,
  } = useGetCategoriesQuery({
    variables: { input: { limit: 50 } },
    onCompleted: ({ categories }) => {
      if (categories && categories.data && categories.data.length) {
        setOptions(formatCategoryOptions(categories.data))
      }
    },
  })

  const handleSearchCategories = async (value: string): Promise<any> => {
    return refetchCategories({ input: { search: value, limit: 100 } }).then(
      ({ data }) => {
        return formatCategoryOptions(data?.categories?.data)
      }
    )
  }

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      handleSearchCategories(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [debounceTimeout])

  return (
    <CategorySelectStyled>
      <Select
        labelInValue
        mode='multiple'
        placeholder='Select category'
        maxCount={5} // Needs V5
        filterOption={false}
        showSearch={true}
        onSearch={debounceFetcher}
        options={options}
        dropdownRender={(menu) => {
          return (
            <>
              {menu}
              {createButtonVisible && !fetching && (
                <>
                  <Divider style={{ margin: '8px 0' }} />
                  <Button
                    style={{ width: '100%' }}
                    type='text'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      onAddClick && onAddClick()
                    }}
                  >
                    {createButtonText || 'Create'}
                  </Button>
                </>
              )}
            </>
          )
        }}
        notFoundContent={
          <div
            style={{ width: '100%', textAlign: 'center', padding: '2em 1em' }}
          >
            <Space>
              {fetching ? <LoadingOutlined /> : <div>{emptyText}</div>}
            </Space>
          </div>
        }
        {...props}
      />
    </CategorySelectStyled>
  )
}

CategorySelect.displayName = 'CategorySelect'

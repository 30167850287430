import { HolderOutlined } from '@ant-design/icons'
import { Form, GetRef, Button } from 'antd'
import * as React from 'react'
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { EditableContext } from '../EditableContext'

const RowContext = React.createContext<RowContextProps>({})

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  listeners?: SyntheticListenerMap
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
  className?: string
}

export const FormTableRowDragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = React.useContext(RowContext)
  return (
    <Button
      type='text'
      size='small'
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      tabIndex={-1}
      {...listeners}
    />
  )
}

export const FormTableRow = (props: RowProps) => {
  const [form] = Form.useForm()

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  }

  const contextValue = React.useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  )

  return (
    <RowContext.Provider value={contextValue}>
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr
            {...props}
            ref={setNodeRef}
            style={style}
            className={props.className}
            {...attributes}
            tabIndex={-1}
          />
        </EditableContext.Provider>
      </Form>
    </RowContext.Provider>
  )
}

FormTableRow.displayName = 'FormTableRow'

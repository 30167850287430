import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const LoadingOverlayStyled = styled.div`
  z-index: 99999;
  background: #afafaf;
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .loading-overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;

    h1 {
      margin-top: ${rem(26)};
      font-size: ${rem(24)};
    }
  }
`

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const UserAvatarStyled = styled.div`
  font-size: ${rem(16)};

  .avatar-icon {
    background: ${(props) => props.theme.colors.primary};
  }
`

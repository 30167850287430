import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialFormSectionRowTitleStyled = styled.div`
  margin-top: 20px;

  .ant-divider {
    margin: ${rem(8)} 0;
  }
`

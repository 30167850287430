import {
  CheckCircleOutlined,
  StopOutlined,
  SaveOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { Divider, Table, Tag, Tooltip, Typography } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import * as React from 'react'

import { formatDate } from '../../utils'

interface DataType {
  key: React.Key
  name: string
  description: string
  created: boolean
  skipped: boolean
  file_url: string
}

import { RecentUploadsTableStyled } from './styles'
export interface RecentUploadsTableProps {
  items?: any[] | null
  sas_token?: string | null
  onRowClick?: (record: any, rowIndex: number | undefined) => void
}

export const RecentUploadsTable = ({
  items = [],
  sas_token = '',
  onRowClick = () => {},
}: RecentUploadsTableProps) => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'File name',
      dataIndex: 'file_name',
      ellipsis: true,
      render(value, record, index) {
        let regex = /([^\/]+$)/g
        return (
          <Tooltip placement='topLeft' title={value?.match(regex)}>
            <a
              href={`${record.file_url}?${sas_token}`}
              target='_blank'
              rel='noreferrer'
            >
              <DownloadOutlined /> {value?.match(regex)}
            </a>
          </Tooltip>
        )
      },
    },
    {
      title: 'Uploaded',
      dataIndex: 'createdAt',
      align: 'right',
      render(value, record, index) {
        return <p>{formatDate(value, {}, true)}</p>
      },
    },
  ]

  return (
    <RecentUploadsTableStyled>
      <Divider />
      <Typography.Title level={4}>Recent uploads</Typography.Title>
      <Table
        columns={columns}
        dataSource={items || []}
        size='small'
        pagination={false}
        rowKey={(record) => {
          return `file_${Math.random().toString()}`
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowClick(record, rowIndex)
            },
          }
        }}
      />
    </RecentUploadsTableStyled>
  )
}

RecentUploadsTable.displayName = 'RecentUploadsTable'

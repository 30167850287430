import {
  FileTextOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EditOutlined,
} from '@ant-design/icons'
import {
  ConfigProvider,
  Button,
  Popconfirm,
  Progress,
  Space,
  Table,
  Tooltip,
  Tag,
  Empty,
  Typography,
  Dropdown,
  Modal,
  message,
} from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import * as React from 'react'

import {
  PriceDetailFragment,
  useDeletePriceMutation,
} from '../../graphql/_generated-hooks'
import { PriceTableStyled } from './styles'
import { StatusTag } from '../StatusTag'
import { memoryStore, formatMoney, formatDate } from '../../utils'

const { Title, Paragraph } = Typography

export interface PriceTableProps {
  data: any // TODO: PriceDetailFragment[]
  tableProps: TableProps<PriceDetailFragment>
  selectedId?: string
  onPriceClick?: (price: PriceDetailFragment, event: any) => void
  onPriceEdit?: (price: PriceDetailFragment | undefined) => void
  onPriceDelete?: (price: PriceDetailFragment) => void
  onViewDocument?: (file_url: string, mime_type: string) => void
}

export const PriceTable = ({
  data = [],
  tableProps: { loading } = {},
  selectedId,
  onPriceClick = () => undefined,
  onPriceDelete = () => undefined,
  onPriceEdit = () => undefined,
  onViewDocument = () => undefined,
}: PriceTableProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [deletePriceMutation, { loading: isDeletingPrice, error }] =
    useDeletePriceMutation()

  const [tableData, setTableData] = React.useState<PriceDetailFragment[]>([])

  React.useEffect(() => {
    setTableData(
      data.map((price: any) => ({
        key: price.id,
        onViewDocument,
        ...price,
      }))
    )
  }, [data])

  const rowActions = [
    {
      key: 'edit',
      label: 'Edit Price',
      icon: <EditOutlined />,
      danger: false,
      'data-testid': 'edit-price-button',
    },
    {
      key: 'delete',
      label: 'Delete Price',
      icon: <DeleteOutlined />,
      danger: true,
      'data-testid': 'delete-price-button',
    },
  ]

  const onActionMenuClick = (e: any, id: string) => {
    const info = e.key
    switch (info) {
      case 'edit':
        onPriceEdit(tableData.find((price) => price.id === id))
        break
      case 'delete':
        Modal.confirm({
          title: `Delete Price?`,
          content:
            'Are you sure you want to delete the price? This action cannot be undone.',
          okText: 'Confirm',
          okType: 'danger',
          cancelText: 'Cancel',
          okButtonProps: {
            loading: isDeletingPrice,
          },
          onOk() {
            deletePriceMutation({
              variables: {
                id,
              },
            }).then((res) => {
              messageApi.success('Price deleted')
              setTableData([
                ...tableData.filter((price: any) => price.id !== id),
              ])
            })
          },
        })
        break
    }
  }

  const tableColumns: ColumnsType<PriceDetailFragment> = [
    {
      title: 'Invoice Date',
      dataIndex: 'invoicedAt',
      key: 'invoicedAt',
      sorter: (a, b) => a.invoicedAt - b.invoicedAt,
      render: (_, { invoicedAt }) => invoicedAt && formatDate(invoicedAt),
    },
    {
      title: 'Distributor',
      dataIndex: 'distributor',
      key: 'distributor',
      render: (_, { distributor }) =>
        (distributor && distributor.name && (
          <Typography>
            <Paragraph style={{ marginBottom: 0 }} ellipsis>
              {/* @ts-ignore */}
              {distributor.name}
            </Paragraph>
          </Typography>
        )) ||
        'N/A',
    },
    // {
    //   title: 'Purchase Order',
    //   dataIndex: 'purchase_order_number',
    //   key: 'purchase_order_number',
    //   render: (_, { uploadedDocument }) =>
    //     uploadedDocument?.purchase_order_number || 'N/A',
    // },
    // {
    //   title: 'Invoice',
    //   dataIndex: 'invoice_number',
    //   key: 'invoice_number',
    //   render: (_, { uploadedDocument }) =>
    //     uploadedDocument?.invoice_number || 'N/A',
    // },
    {
      title: 'Price',
      dataIndex: 'unit_amount',
      key: 'unit_amount',
      render: (_, { unit_amount }) => <b>{formatMoney(unit_amount)}</b>,
      align: 'right',
    },
    {
      title: '',
      key: 'view_document',
      width: 50,
      render: ({ uploadedDocument, onViewDocument }) => {
        return (
          uploadedDocument &&
          uploadedDocument.file_url && (
            <div onClick={(e) => e.stopPropagation()}>
              <Space>
                <Tooltip title='View document'>
                  <Button
                    icon={<FileTextOutlined />}
                    onClick={() =>
                      onViewDocument(
                        uploadedDocument.file_url,
                        uploadedDocument.mime_type
                      )
                    }
                  />
                </Tooltip>
              </Space>
            </div>
          )
        )
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: 50,
      // eslint-disable-next-line react/display-name
      render: ({ id, uploadedDocument, onViewDocument }) => {
        return (
          <>
            <Dropdown
              menu={{
                items: rowActions,
                onClick: (e) => onActionMenuClick(e, id),
              }}
            >
              <Typography.Title
                data-testid='price-table-more-button'
                onClick={(e) => e.preventDefault()}
                level={3}
                style={{ cursor: 'pointer', margin: 0 }}
              >
                <EllipsisOutlined />
              </Typography.Title>
            </Dropdown>
          </>
        )
      },
    },
  ]

  return (
    <>
      {contextHolder}
      <PriceTableStyled>
        <Table<any>
          dataSource={tableData}
          showHeader={true}
          pagination={false}
          columns={tableColumns}
          data-testid='price-table'
        />
      </PriceTableStyled>
    </>
  )
}

PriceTable.displayName = 'PriceTable'

import {
  EyeOutlined,
  EyeInvisibleOutlined,
  FileDoneOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons'
import {
  Col,
  Row,
  Spin,
  Layout,
  Checkbox,
  Button,
  Space,
  Typography,
  Radio,
  Table,
  Tooltip,
  Select,
  notification,
  message,
} from 'antd'
import type { TableColumnsType } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'

import {
  SortOrderInput,
  EstimateStatus,
  EstimateSortFieldInput,
  useGetJobLazyQuery,
  useGetEstimatesQuery,
  useGetEstimateLazyQuery,
  useGetDraftEstimateLazyQuery,
  useDeleteEstimateDraftMutation,
  useGetEstimateQuery,
} from '../../graphql/_generated-hooks'
import { formatMoney, getMostRecentPrice } from '../../utils'
import { PageTitle } from '../../components/PageTitle'
import { EstimatesOrderSheetsViewStyled } from './styles'
import { set } from 'lodash'

export interface EstimatesOrderSheetsViewProps {
  me?: any
  navigationCollapsed?: boolean
}

export const EstimatesOrderSheetsView = (
  props: EstimatesOrderSheetsViewProps
) => {
  const { me, ...rest } = props

  let { id } = useParams()
  const navigate = useNavigate()
  const [messageApi, messageContextHolder] = message.useMessage()
  const [ordersheetViewType, setOrdersheetViewType] = React.useState<
    'all' | 'vendor'
  >('all')
  const [selectedVendorKey, setSelectedVendorKey] = React.useState<any>(null)
  const [estimateItems, setEstimateItems] = React.useState<any>([])
  const [estimateItemsByVendor, setEstimateItemsByVendor] = React.useState<any>(
    {}
  )
  const [hiddenRowKeys, setHiddenRowKeys] = React.useState(new Set())

  const toggleRowVisibility = (key: number) => {
    setHiddenRowKeys((prev: any) => {
      const newHidden = new Set(prev)
      if (newHidden.has(key)) {
        newHidden.delete(key)
      } else {
        newHidden.add(key)
      }
      return newHidden
    })
  }

  const [tableColumns, setTableColumns] = React.useState<any>([
    {
      title: 'Item',
      dataIndex: ['prices', '0', 'product', 'name'],
      key: 'name',
      width: 'auto',
      render: (text: any, record: any) => {
        return record?.prices?.[0]?.product?.name || record?.name
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a: any, b: any) => a.quantity - b.quantity,
      width: '15%',
      align: 'center',
    },
    {
      title: 'SKU',
      dataIndex: ['prices', '0', 'product', 'sku'],
      key: 'sku',
      hidden: true,
      width: '20%',
    },
    {
      title: 'Vendor',
      dataIndex: ['prices', '0', 'distributor', 'name'],
      key: 'vendor',
      hidden: true,
      width: '15%',
    },
    {
      title: 'Amount',
      dataIndex: ['unit_amount'],
      key: 'unit_amount',
      hidden: true,
      width: '10%',
      align: 'right',
      render: (text: any, record: any) => {
        return formatMoney(text)
      },
    },
  ])

  if (!id) {
    // Redirect
    navigate('/estimates')
  }

  const {
    data: estimateData,
    loading: loadingEstimate,
    refetch: refetchEstimate,
    updateQuery: updateEstimate,
  } = useGetEstimateQuery({
    variables: {
      id: id || '',
    },
    onCompleted({ estimate }) {
      const filteredItems =
        estimate?.line_items
          ?.filter((item) => {
            return (
              item?.type == 'good' // && item?.prices && item?.prices?.length > 0
            )
          })
          .map((item, index) => ({
            key: item?.id,
            productId: item?.prices?.[0]?.product?.id,
            latestPrice:
              getMostRecentPrice(item?.prices || []) || item?.unit_amount,
            ...item,
          })) || []

      let consolidatedItems: any = {}

      filteredItems.forEach((item) => {
        if (!item.id) {
          return
        }
        if (consolidatedItems[item.id]) {
          // If this id is already in the accumulator, add the quantity
          consolidatedItems[item.id].quantity += item.quantity
        } else {
          // Otherwise, add the item to the accumulator
          consolidatedItems[item.id] = { ...item }
        }
      })

      const consolidatedItemsArray = Object.values(consolidatedItems)

      // @ts-ignore
      const groupedItemsByVendor = Object.groupBy(
        [...consolidatedItemsArray],
        (item: any) => item?.latestPrice?.distributor?.name || '_unknown'
      )

      setEstimateItems(consolidatedItemsArray)
      setEstimateItemsByVendor(groupedItemsByVendor)
    },
  })

  const handleSelectBreakdown = (event: any) => {
    switch (event.target.value) {
      case 'all':
        setOrdersheetViewType('all')
        break
      case 'vendor':
        setOrdersheetViewType('vendor')
        setSelectedVendorKey(Object.keys(estimateItemsByVendor)[0])
        break
      default:
        break
    }
  }
  const handleShowColumn = (checkedColumns: any) => {
    let columnlist = ['name', 'quantity', 'action', ...checkedColumns]
    let updatedTableColumns = tableColumns.map((column: any) => {
      if (columnlist.includes(column.key)) {
        column.hidden = false
      } else {
        column.hidden = true
      }
      return column
    })
    setTableColumns(updatedTableColumns)
  }

  const handlePrintPage = () => {
    window.print()
  }

  const actionTableColumn = {
    title: '',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 50,
    className: 'hidden-print',
    // eslint-disable-next-line react/display-name
    render: (text: any, data: any, index: any) => {
      return (
        <Button
          type='text'
          icon={
            hiddenRowKeys.has(data.key) ? (
              <EyeInvisibleOutlined />
            ) : (
              <EyeOutlined />
            )
          }
          onClick={(e) => {
            e.stopPropagation()
            toggleRowVisibility(data.key)
          }}
        >
          {data.invisible}
        </Button>
      )
    },
  }

  return (
    <EstimatesOrderSheetsViewStyled>
      {messageContextHolder}
      <Layout style={{ padding: '0 2em' }}>
        <Content style={{ padding: '2em 0' }} className='hidden-print'>
          <Row>
            <Col span={12}>
              <PageTitle
                title={'Estimate Order Sheet'}
                subtitle='Download a list of materials that need to be ordered for this estimate.'
              />
            </Col>
            <Col flex='1 0' style={{ textAlign: 'right' }}>
              <Button
                onClick={handlePrintPage}
                data-testid='print-order-sheets-button'
              >
                <FileDoneOutlined />
                Save Order Sheet
              </Button>
            </Col>
          </Row>
        </Content>
        <Content style={{ background: '#fff', padding: '24px' }}>
          <Spin spinning={loadingEstimate}>
            <Row
              style={{ marginBottom: '2em' }}
              className='hidden-print'
              gutter={24}
            >
              <Col span={8}>
                <Typography.Title level={5}>Breakdown</Typography.Title>
                <Space>
                  <Radio.Group
                    defaultValue='all'
                    buttonStyle='solid'
                    onChange={handleSelectBreakdown}
                  >
                    <Radio.Button value='all'>All</Radio.Button>
                    <Radio.Button value='vendor'>
                      <Tooltip title='Split materials by the last vendor the material was purchased from.'>
                        By Vendor{' '}
                      </Tooltip>
                    </Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
              <Col span={8}>
                <Typography.Title level={5}>Add fields</Typography.Title>
                <Checkbox.Group
                  style={{ width: '100%', marginTop: '5px' }}
                  onChange={handleShowColumn}
                >
                  <Checkbox value={'sku'}>SKU</Checkbox>
                  <Checkbox value={'vendor'}>Vendor</Checkbox>
                  <Checkbox value={'unit_amount'}>Amount</Checkbox>
                </Checkbox.Group>
              </Col>
              {ordersheetViewType == 'vendor' && (
                <Col span={8}>
                  <Typography.Title level={5}>Select vendor</Typography.Title>
                  <Select
                    style={{ width: '100%' }}
                    placeholder='Select a vendor'
                    onChange={(value) => {
                      setSelectedVendorKey(value)
                    }}
                    options={Object.keys(estimateItemsByVendor).map((key) => ({
                      label: key == '_unknown' ? 'No Vendor' : key,
                      value: key,
                    }))}
                    defaultValue={Object.keys(estimateItemsByVendor)[0]}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24}>
                {ordersheetViewType == 'all' && (
                  <Table
                    key={id}
                    columns={[...tableColumns, actionTableColumn]}
                    dataSource={estimateItems}
                    rowClassName={(record) =>
                      hiddenRowKeys.has(record.key) ? 'hidden-print' : ''
                    }
                    size='small'
                    pagination={false}
                  />
                )}

                {ordersheetViewType == 'vendor' && (
                  <div className='full-page-div'>
                    <Typography.Title level={5}>
                      {selectedVendorKey == '_unknown'
                        ? 'No Vendor'
                        : selectedVendorKey}
                    </Typography.Title>
                    <Table
                      key={id}
                      columns={[...tableColumns, actionTableColumn]}
                      dataSource={estimateItemsByVendor[selectedVendorKey]}
                      rowClassName={(record) =>
                        hiddenRowKeys.has(record.key) ? 'hidden-print' : ''
                      }
                      size='small'
                      pagination={false}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Spin>
        </Content>
      </Layout>
    </EstimatesOrderSheetsViewStyled>
  )
}

EstimatesOrderSheetsView.displayName = 'EstimatesOrderSheetsView'

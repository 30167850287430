import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const TotalSummaryRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  color: ${(props) => props.theme.colors.textPrimary};

  &:last-child {
    border-bottom: none;
  }
`

export const RowLabelStyled = styled.div<{
  bold?: string
  size?: number
}>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: ${(props) => props.size || 14}px;
`

export const RowValueStyled = styled.div<{
  bold?: string
  size?: number
}>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: ${(props) => props.size || 14}px;
`

import { Typography } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { MaterialNameDescriptionStyled } from './styles'
import { capitalizeString } from '../../utils'

const { Title, Paragraph } = Typography

export interface MaterialNameDescriptionProps {
  name: string
  description?: string | null
  productLinkId?: string | null
  copyName?: boolean
  boldName?: boolean
}

export const MaterialNameDescription = ({
  name,
  description,
  productLinkId,
  copyName = true,
  boldName = true,
  ...props
}: MaterialNameDescriptionProps) => {
  const renderNameDescription = () => {
    return (
      <Typography>
        <Paragraph
          strong={boldName}
          copyable={!!name && copyName}
          style={{ marginBottom: 0 }}
          ellipsis
          data-testid='material-name'
        >
          {name || '-'}
        </Paragraph>
        <Paragraph type='secondary' ellipsis data-testid='material-trade-name'>
          {description || ''}
        </Paragraph>
      </Typography>
    )
  }

  return (
    <MaterialNameDescriptionStyled>
      {productLinkId ? (
        <a
          href={`/materials/${productLinkId}`}
          target='_blank'
          rel='noreferrer'
          style={{ color: theme.colors.primary }}
        >
          {renderNameDescription()}
        </a>
      ) : (
        renderNameDescription()
      )}
    </MaterialNameDescriptionStyled>
  )
}

MaterialNameDescription.displayName = 'MaterialNameDescription'

import { from } from '@apollo/client'
import moment from 'moment'

export const isObjectEmpty = (objectName: any) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  )
}

export let memoryStore = {
  data: { jwt_token: '', sas_token: '' },
  get: (key: 'jwt_token' | 'sas_token') => memoryStore.data[key],
  set: (key: 'jwt_token' | 'sas_token', value: any) => {
    memoryStore.data[key] = value
  },
  remove: (key: 'jwt_token' | 'sas_token') => {
    memoryStore.data[key] = ''
  },
}

export const capitalizeString = function capitalize(string: string) {
  if (typeof string !== 'string') return ''
  return string[0].toUpperCase() + string.slice(1)
}

export const getFullname = (
  user: {
    preferred_name?: string
    given_name?: string
    family_name?: string
  } = {
    preferred_name: '',
    given_name: '',
    family_name: '',
  }
): string => {
  return `${user?.given_name || ''} ${user?.family_name || ''} ${
    user?.preferred_name ? '(' + user?.preferred_name + ')' : ''
  }`.trim()
}

export const getNameInitials = (
  user: {
    given_name?: string
    family_name?: string
  } = {
    given_name: '',
    family_name: '',
  }
): string => {
  return `${user?.given_name?.[0] || ''} ${user?.family_name?.[0] || ''}`.trim()
}

export const isLimitReached = (
  count: number = 0,
  limit: number = 1,
  plan: string
) => {
  return !plan && count >= limit
}

export const getPathnameByLevel = (pathname: any, level = 1) => {
  return pathname
    .split('/')
    .slice(0, level + 1)
    .join('/')
}
export const getDistributorSlug = (distributorName: string) => {
  if (!distributorName || typeof distributorName !== 'string') return ''

  return distributorName.trim().replace(/\s/g, '').replace('/n', '-')
}

export const multiply = (arr: number[]) => arr.reduce((a, b) => a * b)

export const formatCents = (value: number | null | undefined) => {
  return value ? value * 100 : 0
}
export const formatCentsToDollars = (value: number | null | undefined) => {
  if (!value || isNaN(value)) value = 0
  return roundNumber(value / 100)
}
export const formatMoney = (
  value: number | null | undefined,
  denominator = 100
) => {
  if (!value) value = 0
  return `$${(value / denominator).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`
}
export const roundMoney = (value: number | null | undefined) => {
  if (!value) value = 0
  return Math.round(value * 100) / 100
}
export const roundNumber = (
  value: number | null | undefined,
  places?: number
) => {
  if (!value || isNaN(value)) value = 0
  return parseFloat(value.toFixed(places || 3))
}
export const trucateNumber = (value: number | null | undefined) => {
  if (!value || isNaN(value)) value = 0
  return Math.trunc(value)
}
export const calculateLaborTotal = (
  price: number | undefined,
  quantity: number
) => {
  return roundNumber(price) * roundNumber(quantity)
}
export const calculateLineItemTotal = (
  price: number | undefined,
  quantity: number = 1,
  markup?: number,
  discount?: number
) => {
  if (!price) price = 0

  if (markup) {
    price = price + multiply([price, markup / 100])
  }

  if (discount) {
    price = price - multiply([price, discount / 100])
  }

  return price * roundNumber(quantity)
}
export const calculateLineItemProfit = (
  price: number | undefined,
  quantity: number = 1,
  markup?: number,
  discount?: number
) => {
  if (!markup || !price) return 0

  return multiply([price, markup / 100]) * roundNumber(quantity)
}
export const calculateAverage = (arr: number[]) => {
  if (!arr.length) return 0
  const sum = arr.reduce((a, b) => a + b, 0)
  return sum / arr.length || 0
}
export const dollarInputFormatter = (value: any) => `${value / 100}` // .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
export const dollarInputParser = (value: any) => `${value * 100}` //!.replace(/\$\s?|(,*)/g, '') // `${parseFloat(value).toFixed(2) * 100}`

export const calculatePercentage = (value: number = 0, total: number = 1) => {
  return (value / total) * 100
}

export const formatDate = (
  date: number | null = 0,
  options: any = {},
  includeTime?: boolean
) => {
  if (!date) date = 0
  if (includeTime) {
    return new Date(date).toLocaleString('en', options)
  }
  return new Date(date).toLocaleDateString('en', options)
}
export const formatTime = (date: number | null = 0, options: any) => {
  if (!date) date = 0
  return new Date(date).toLocaleTimeString('en', options)
}

export const formatVendorName = (vendorName: string) => {
  // Replace newlines with spaces
  return vendorName.replace(/\n/g, ' ')
}

export const getPriceAge = (date: number | null = 0) => {
  if (!date) return 0
  const differenceInTime = new Date().getTime() - new Date(date).getTime()
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))
  return differenceInDays
}

export const getPriceAgeStatus = (date: number | null = 0) => {
  if (!date) return 'N/A'
  const days = getPriceAge(date)

  if (days < 180) {
    return 'new'
  } else if (days > 180 && days < 365) {
    return 'recent'
  } else {
    return 'stale'
  }
}

export const filterArrayByObjId = (arr: any[], key?: string) => {
  const defaultKey = 'id'
  return arr.reduce((acc, current) => {
    const x = acc.find(
      (item: any) => item[key || defaultKey] === current[key || defaultKey]
    )
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])
}

export const buildRedirectString = (redirectObj: {
  referrer?: string
  mode?: string
  id?: string
}) => {
  let redirectString = ''
  if (redirectObj.referrer) {
    redirectString += `/${redirectObj.referrer}`
  }
  if (redirectObj.id) {
    redirectString += `/${redirectObj.id}`
  }
  if (redirectObj.mode) {
    redirectString += `/${redirectObj.mode}`
  }
  return redirectString
}

export const filterAttributes = (
  objectsArray: any[],
  attributesList: string[]
) => {
  if (!objectsArray) return
  if (!objectsArray.length) return []
  return objectsArray.map((obj) => {
    const filteredObject: any = {}
    attributesList.forEach((attr) => {
      if (obj.hasOwnProperty(attr)) {
        filteredObject[attr] = obj[attr]
      }
    })
    return filteredObject
  })
}

export const getMostRecentPrice = (prices: any[] = []) => {
  if (!prices.length) return null // Return null if the prices array is empty

  let mostRecentPrice = prices[0] // Start by assuming the first price is the most recent

  for (let i = 1; i < prices.length; i++) {
    if (prices[i].createdAt > mostRecentPrice.createdAt) {
      mostRecentPrice = prices[i] // Update if a more recent price is found
    }
  }

  return mostRecentPrice
}

export const getDecimalPortionOfNumber = (num: number) => {
  return roundNumber(num - Math.floor(num))
}

export * from './setApiFormErrors'
export * from './formatEstimateFormObj'
export * from './calculateMaterialListQtyForEstimateForm'
// export * from './formatPhoneNumber'

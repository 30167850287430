import { PlusOutlined } from '@ant-design/icons'
import { Col, Input, Row, Space, Pagination } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { useNavigate, Link } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'
import { isLimitReached } from '../../utils'

import { useGetEstimatesQuery } from '../../graphql/_generated-hooks'
import { PageTitle } from '../../components/PageTitle'
import { EstimatesTable } from '../../components/EstimatesTable'
import { LockableButton } from '../../components/LockableButton'
import { EstimateDuplicateModal } from '../../components/EstimateDuplicateModal'
import { EstimateListViewStyled } from './styles'

const { Search } = Input
const defaultPageSize = 10

export interface EstimateListViewProps {
  me?: any
}

export const EstimateListView = ({ me, ...props }: EstimateListViewProps) => {
  const [searchQuery, setSearchQuery] = React.useState<any>(null)
  const [estimateData, setEstimateData] = React.useState<any>([])
  const [showDuplicateModal, setShowDuplicateModal] = React.useState<any>(false)
  const [estimateToDuplicate, setEstimateToDuplicate] =
    React.useState<any>(false)

  let navigate = useNavigate()
  const estimateUsage = useAppStore((state: any) => state.estimates)
  const activePlan = useAppStore((state: any) => state.plan)

  const {
    data: { estimates = { data: [], count: 0 } } = {
      estimates: { data: [], count: 0 },
    },
    loading: isLoadingEstimates,
    refetch: refetchEstimates,
    updateQuery: updateEstimates,
  } = useGetEstimatesQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: { search: '' },
    },
    onCompleted: ({ estimates }) => {
      setEstimateData(estimates?.data || [])
    },
  })

  const handleAddEstimate = () => {
    navigate(`/estimates/create`, {})
  }

  const onSearch = (value: string) => {
    setSearchQuery(value)
    refetchEstimates({
      input: {
        limit: defaultPageSize,
        search: value,
      },
    })
  }
  const onSort = (field: string, order: string) => {
    refetchEstimates({
      // @ts-ignore
      input: {
        limit: defaultPageSize,
        ...(order && {
          sort: {
            field,
            order: 'ascend' == order ? 'desc' : 'asc',
          },
        }),
      },
    })
  }
  const onPageChange = (page: number, pageSize?: number) => {
    refetchEstimates({
      input: {
        limit: pageSize,
        offset: page * pageSize! - pageSize!,
        ...(searchQuery && { search: searchQuery }),
      },
    })
  }

  return (
    <EstimateListViewStyled>
      <Content style={{ padding: '2em' }}>
        <Row>
          <Col span={12}>
            <PageTitle title='Estimates' subtitle='Manage estimates' />
          </Col>
          <Col span={12} className='create-btn-container'>
            <LockableButton
              locked={isLimitReached(
                estimateUsage,
                me?.organization?.estimateLimit,
                activePlan
              )}
              reason={
                'You have reached your monthly estimate limit on this plan. Upgrade to create more estimates.'
              }
              onClick={handleAddEstimate}
            >
              <PlusOutlined />
              New Estimate
            </LockableButton>
          </Col>
        </Row>
      </Content>
      <Content style={{ padding: '0 2em' }}>
        <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
          <Search
            placeholder='Search'
            onSearch={onSearch}
            onChange={debounce((e) => {
              const value = e.target.value
              if (value && value.length > 1) {
                onSearch(value)
              } else if (value === '') {
                onSearch('')
              }
            }, 300)}
            allowClear={true}
            style={{ width: 350, float: 'right' }}
          />
          <EstimatesTable
            data={estimateData}
            tableProps={{ loading: isLoadingEstimates }}
            allowDuplicate={
              !isLimitReached(
                estimateUsage,
                me?.organization?.estimateLimit,
                activePlan
              )
            }
            onEstimateClick={() => {}}
            onEstimateDuplicate={(estimate) => {
              setEstimateToDuplicate(estimate)
              setShowDuplicateModal(true)
            }}
            onEstimateDelete={() => {}}
            onChange={debounce((pagination, filters, sorter) => {
              onSort(sorter.field, sorter.order)
            }, 400)}
            selectedId={''}
          />
          <Pagination
            defaultCurrent={1}
            defaultPageSize={defaultPageSize}
            showSizeChanger={false}
            total={estimates?.count}
            onChange={onPageChange}
            style={{ float: 'right' }}
          />
        </Space>

        <EstimateDuplicateModal
          open={showDuplicateModal}
          onCancel={() => {
            setShowDuplicateModal(false)
            setEstimateToDuplicate({})
          }}
          estimateObj={estimateToDuplicate}
        />
      </Content>
    </EstimateListViewStyled>
  )
}

EstimateListView.displayName = 'EstimateListView'

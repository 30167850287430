const getErrorMessage = (error_type_key: string, field_name: string) => {
  switch (error_type_key) {
    case 'not_unique':
      return `Another record with the same ${field_name} already exists`
  }
}

export const setApiFormErrors = (
  formErrors: readonly any[] = [],
  form: any,
  nestedPath?: string
) => {
  if (!formErrors.length) return

  formErrors.forEach((error: any) => {
    if (error?.extensions?.exception?.errors?.length) {
      error?.extensions?.exception?.errors.forEach((error: any) => {
        const path = nestedPath ? [nestedPath, error.path] : error.path
        form.setFields([
          {
            name: path,
            errors: [
              getErrorMessage(error.validatorKey, error.path) || error.message,
            ],
          },
        ])
        form.scrollToField(path)
      })
    }
  })
}

export const setGraphQLFormErrors = (
  formErrors: readonly any[] = [],
  form: any,
  nestedPath?: string
) => {
  if (!formErrors.length) return

  formErrors.forEach((error: any) => {
    if (error?.name?.length) {
      error?.name.forEach((pathArr: any) => {
        // const path = nestedPath ? [nestedPath, error.path] : error.path
        form.setFields([
          {
            name: pathArr,
            errors: error?.errors || ['Invalid Value'],
          },
        ])
        form.scrollToField(pathArr)
      })
    }
  })
}

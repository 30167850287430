import {
  CopyOutlined,
  DeleteOutlined,
  SendOutlined,
  LockOutlined,
  SnippetsOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import type { ActionType, ProColumns } from '@ant-design/pro-components'
import { ProTable } from '@ant-design/pro-components'
import {
  Progress,
  Space,
  Tooltip,
  Tag,
  Typography,
  Row,
  Col,
  Avatar,
  Table,
  Dropdown,
  Button,
} from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useGetEstimatesQuery,
  EstimateDetailFragment,
} from '../../graphql/_generated-hooks'
import { formatMoney, formatDate } from '../../utils'
import { DemoItemTag } from '../DemoItemTag'
import { EstimatesTableStyled } from './styles'

export interface EstimatesTableProps {
  data?: any // TODO: EstimateDetailFragment[]
  tableProps: TableProps<EstimateDetailFragment>
  selectedId?: string
  allowDuplicate?: boolean
  onEstimateClick?: (estimate: EstimateDetailFragment) => void
  onEstimateDuplicate?: (estimate: EstimateDetailFragment) => void
  onEstimateDelete?: (estimate: EstimateDetailFragment) => void
  onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void
}

const { Title, Paragraph } = Typography

const calculateEstimateTotal = (estimate: EstimateDetailFragment) => {
  if (!estimate) return 0

  const { line_items = [] } = estimate

  return (
    line_items &&
    line_items.length &&
    line_items.reduce((acc, item) => {
      return acc + (item?.amount || 0)
    }, 0)
  )
}

export const EstimatesTable = ({
  data = [],
  tableProps: { loading } = {},
  selectedId,
  allowDuplicate,
  onEstimateClick = () => undefined,
  onEstimateDuplicate = () => undefined,
  onEstimateDelete = () => undefined,
  onChange = () => undefined,
}: EstimatesTableProps) => {
  let navigate = useNavigate()

  const columns: ColumnsType<EstimateDetailFragment> = [
    // {
    //   dataIndex: 'index',
    //   valueType: 'indexBorder',
    //   width: 48,
    // },
    // {
    //   title: 'Description',
    //   key: 'description',
    //   dataIndex: 'description',
    //   sorter: true,
    //   render: (_, { description = '' }) => (
    //     <Row align='middle' gutter={16}>
    //       <Col>
    //         <Avatar size={36}>{description?.[0]}</Avatar>
    //       </Col>
    //       <Col>
    //         <Typography>
    //           <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
    //             {description}
    //           </Title>
    //           {/* {customer_email && (
    //             <Paragraph style={{ marginBottom: 0 }} ellipsis>
    //               {customer_email}
    //             </Paragraph>
    //           )} */}
    //         </Typography>
    //       </Col>
    //     </Row>
    //   ),
    // },
    {
      title: 'Estimate Number',
      dataIndex: 'number',
      ellipsis: true,
      sorter: true,
      render: (_, { id, number, description }) => (
        <Typography>
          <Paragraph
            // level={5}
            strong={true}
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {number ? number : 'Draft Estimate'}
          </Paragraph>
          {description && (
            <Paragraph style={{ marginBottom: 0 }} ellipsis>
              {description}
            </Paragraph>
          )}
        </Typography>
      ),
    },
    {
      title: 'Estimate Name',
      dataIndex: 'name',
      ellipsis: true,
      sorter: true,
      render: (name) => (
        <Typography>
          <Paragraph style={{ marginBottom: 0 }} ellipsis>
            {name || '-'}
          </Paragraph>
        </Typography>
      ),
    },
    // {
    //   title: 'Customer',
    //   dataIndex: ['job', 'customers'],
    //   ellipsis: true,
    //   sorter: true,
    //   render: (_, { job }) => (
    //     <Typography>
    //       <Paragraph
    //         // level={5}
    //         style={{ marginTop: 0, marginBottom: 0 }}
    //       >
    //         {job?.customers?.[0]?.name}
    //       </Paragraph>
    //     </Typography>
    //   ),
    // },
    {
      title: 'Prepared for',
      dataIndex: 'contact_name',
      key: 'contact_name',
      sorter: true,
      render: (_, { id, contact_name, contact_email }) => (
        <Typography>
          <Paragraph
            // level={5}
            strong={true}
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {contact_name || '-'}
          </Paragraph>
          {contact_email && (
            <Paragraph style={{ marginBottom: 0 }} ellipsis copyable>
              {contact_email}
            </Paragraph>
          )}
        </Typography>
      ),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   align: 'center',
    //   sorter: true,
    //   render: (_, { status }) => (
    //     <Space>
    //       <StatusTag status={status || ''} />
    //     </Space>
    //   ),
    // },
    {
      width: 150,
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      align: 'right',
      sorter: true,
      render: (text: any, { total }) => <>{formatMoney(total)}</>,
    },
    {
      width: 150,
      title: 'Created',
      dataIndex: 'createdAt',
      align: 'right',
      sorter: true,
      render: (createdAt, { metadata }) => {
        return metadata?.demo ? <DemoItemTag /> : <p>{formatDate(createdAt)}</p>
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (data, { metadata }) => {
        return metadata?.demo ? (
          <></>
        ) : (
          <Dropdown
            menu={{
              items: [
                {
                  key: '1',
                  icon: allowDuplicate ? (
                    <SnippetsOutlined />
                  ) : (
                    <LockOutlined />
                  ),
                  label: 'Duplicate',
                  disabled: !allowDuplicate,
                  onClick: (e) => {
                    e.domEvent.preventDefault()
                    e.domEvent.stopPropagation()
                    onEstimateDuplicate(data)
                  },
                },
              ],
            }}
          >
            <Button
              type='text'
              icon={<MoreOutlined />}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            />
          </Dropdown>
        )
      },
    },
  ]

  const handleRowClick = (row: any, event: any) => {
    event.preventDefault()
    navigate(`/estimates/${row.id}`, {})
  }

  return (
    <EstimatesTableStyled>
      <Table<EstimateDetailFragment>
        columns={columns}
        dataSource={data || []}
        onRow={(row, rowIndex) => {
          return {
            onClick: (event) => {
              handleRowClick(row, event)
            },
          }
        }}
        onChange={onChange}
        rowKey='id'
        loading={loading}
        pagination={false}
      />
    </EstimatesTableStyled>
  )
}

EstimatesTable.displayName = 'EstimatesTable'

import {
  CheckCircleFilled,
  RedoOutlined,
  LinkOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormMoney,
  ProFormDatePicker,
} from '@ant-design/pro-components'
import {
  useCreateProductMutation,
  useUpdateProductMutation,
  ProductTypeFieldInput,
  useCreateDistributorMutation,
  useCreatePriceMutation,
  useUpdateUploadedDocumentMutation,
  useGetDistributorsQuery,
  useValidateProductSkuLazyQuery,
} from '../../graphql/_generated-hooks'
import {
  Button,
  Form,
  message,
  Alert,
  Row,
  Col,
  Select,
  Space,
  Typography,
  Divider,
} from 'antd'
import React, { useMemo, useRef, useState, memo } from 'react'
import debounce from 'lodash/debounce'
import { useAppStore } from '../../stores/appStore'

import { MaterialServiceFormDrawerStyled } from './styles'
import { formatVendorName, roundMoney, setApiFormErrors } from '../../utils'
import {
  DistributorSelectOrCreate,
  DistributorSelectOrCreateSchema,
} from '../DistributorSelectOrCreate'
import { PDFViewer } from '../PDFViewer'
import { MultiStepMaterialDrawerSummary } from '../MultiStepMaterialDrawerSummary'
import { CategorySelect } from '../CategorySelect'

const DRAWER_WIDTH = 450
export interface MaterialServiceFormDrawerProps {
  onMaterialServiceSave?: (data: any) => void
  onCreateNewCategory?: () => void
  onClose?: () => void
  open?: boolean
  edit?: boolean
  prefillData?: any
  editItem?: any
  createPresetItem?: any
  newCategory?: any
}

export interface MaterialServiceFormSchema {
  id: string
  name: string
  description: string
  unit_amount: number
  unit_type: string
  invoicedAt: Date
  sku: string
  categories: any[]
  distributor: DistributorSelectOrCreateSchema
}

const calculateDocPosition = (drawerWidth: number) => {
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight
  const remainingWidth = viewportWidth - drawerWidth
  const xPadding = remainingWidth * 0.1
  const yPadding = remainingWidth * 0.1
  const docMaxWidth = remainingWidth - xPadding * 2
  const docMaxHeight = viewportHeight - yPadding * 2

  return {
    left: (remainingWidth - docMaxWidth) / 2,
    width: docMaxWidth,
    height: docMaxHeight,
  }
}

export const MaterialServiceFormDrawer = ({
  onMaterialServiceSave = (data: any) => {},
  onCreateNewCategory = () => {},
  onClose = () => {},
  open,
  edit,
  editItem,
  createPresetItem,
  newCategory,
  prefillData = {
    file_url: '',
    items: [],
    date: new Date(),
    vendor: '',
    mime_type: '',
    invoice_number: '',
    purchase_order_number: '',
    existingDistributor: '',
    matchedExistingProducts: {},
    IS_DEMO: false,
  },
}: MaterialServiceFormDrawerProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()
  const [itemsToCreate, setItemsToCreate] = React.useState<any[]>(
    prefillData?.items || []
  )
  const [currentItemIndex, setCurrentItemIndex] = React.useState(0)
  const [activeItem, setActiveItem] = React.useState<any>(null)
  const [foundDuplicateSkuItem, setFoundDuplicateSkuItem] =
    React.useState<any>(null)
  const [uploadedDocumentId, setUploadedDocumentId] = React.useState(
    prefillData?.id || ''
  )
  const [fileUrl, setFileUrl] = React.useState(prefillData?.file_url || '')
  const [fileMimeType, setFileMimeType] = React.useState(
    prefillData?.mime_type || ''
  )
  const [activeDistributor, setactiveDistributor] = React.useState<any>({})
  const [showSummary, setShowSummary] = React.useState(false)
  const [validatingProductSku, setValidatingProductSku] = React.useState(false)

  // Mutations
  const [createMaterial, { data, loading: createMutationLoading, error }] =
    useCreateProductMutation()
  const [updateProduct, { loading: updateMutationLoading }] =
    useUpdateProductMutation()
  const [updateUploadedDocument, { loading: updateDocumentMutationLoading }] =
    useUpdateUploadedDocumentMutation()
  const [createDistributor, { loading: createDistributorLoading }] =
    useCreateDistributorMutation()
  const [createPrice, { loading: creatingPrice }] = useCreatePriceMutation()
  const [validateProductSku] = useValidateProductSkuLazyQuery()

  // State
  const addMaterial = useAppStore((state: any) => state.addMaterial)

  // Refs
  const lastCategoryRef = React.useRef(null)

  // Queries
  const { updateQuery: updateDistributorsQuery } = useGetDistributorsQuery({
    variables: { input: { search: '' } },
  })

  let loading = edit
    ? updateMutationLoading
    : createMutationLoading || creatingPrice

  const mergeExistingProduct = (item: any, matchedProducts: any) => {
    let productCode = item.properties?.productCode?.value

    if (productCode && matchedProducts && matchedProducts[productCode]) {
      item.exists = matchedProducts[productCode]
    }
    return item
  }

  React.useEffect(() => {
    if (prefillData?.items.length > 0) {
      setItemsToCreate(prefillData?.items || [])
      setActiveItem(
        mergeExistingProduct(
          prefillData?.items[0],
          prefillData?.matchedExistingProducts
        )
      )
      handleSetForm(prefillData?.items[0].properties)
    }

    // TODO can likely consolidate into a single state obj
    if (prefillData?.id) {
      setUploadedDocumentId(prefillData?.id)
    }
    if (prefillData?.file_url) {
      setFileUrl(prefillData?.file_url)
    }
    if (prefillData?.mime_type) {
      setFileMimeType(prefillData?.mime_type)
    }
    if (prefillData?.invoice_number) {
      form.setFieldValue('invoice_number', prefillData?.invoice_number)
    }
    if (prefillData?.purchase_order_number) {
      form.setFieldValue(
        'purchase_order_number',
        prefillData?.purchase_order_number
      )
    }
    if (prefillData?.existingDistributor) {
      // Don't set active distributor or else it will skip the pre-step
      form.setFieldValue('distributor', {
        id: prefillData?.existingDistributor,
      })
    }

    if (editItem) {
      const { name, unit_type, sku, description, notes } = editItem
      form.setFieldsValue({
        name,
        sku,
        description,
        notes,
        unit_type,
      })
    }

    if (createPresetItem) {
      const { name, description, unit_amount, invoicedAt } = createPresetItem
      form.setFieldsValue({
        name,
        description,
        unit_amount,
        invoicedAt,
      })
    }
  }, [prefillData, editItem, createPresetItem])

  // Separating this out so on create it doesn't trigger other code
  // in the above effect.
  React.useEffect(() => {
    if (
      newCategory &&
      newCategory.id &&
      lastCategoryRef.current !== newCategory.id
    ) {
      const currentCategories = form.getFieldValue('categories') || []
      form.setFieldValue('categories', [
        ...currentCategories,
        ...[
          {
            value: newCategory.id,
            label: newCategory.name,
          },
        ],
      ])

      lastCategoryRef.current = newCategory.id
    }
  }, [newCategory])

  const handleSetForm = (values: any) => {
    const {
      amount,
      description,
      orderQuantity,
      productCode,
      unitPrice,
      unit,
      categories,
    } = values

    // Clear previous values first
    form.setFieldsValue({
      name: '',
      unit_amount: '',
      sku: '',
      invoicedAt: '',
      description: '',
      categories: [],
      notes: '',
    })

    form.setFieldsValue({
      // name: activeItem.name,
      // @ts-ignore
      name: description?.value,
      // @ts-ignore
      unit_amount: roundMoney(unitPrice?.value?.amount),
      sku: productCode?.value,
      distributor: formatVendorName(prefillData?.vendor),
      invoicedAt: prefillData.date,
      unit_type: unit?.value || 'ea',
      ...(categories && {
        categories: categories.map((c: any) => ({
          value: c.id,
          label: c.name,
        })),
      }),
      // description: '',
      // notes: '',
    })
  }
  const handleResetForm = () => {
    setShowSummary(false)
    setActiveItem(null)
    setFileUrl('')
    setCurrentItemIndex(0)
    setItemsToCreate([])
    setactiveDistributor(null)
    onMaterialServiceSave({})
    prefillData = {
      file_url: '',
      items: [],
      date: new Date(),
      vendor: '',
      mime_type: '',
      invoice_number: '',
      purchase_order_number: '',
      existingDistributor: '',
      matchedExistingProducts: {},
    }
  }

  const handleProceedToNextItem = () => {
    setFoundDuplicateSkuItem(null)
    if (currentItemIndex + 1 >= itemsToCreate.length) {
      setShowSummary(true)
      setActiveItem(null)
      setCurrentItemIndex(currentItemIndex + 1) // So the back button goes to the last item
      // handleResetForm()
    } else {
      const nextItem = itemsToCreate[currentItemIndex + 1]

      setActiveItem(
        mergeExistingProduct(nextItem, prefillData?.matchedExistingProducts)
      )
      setCurrentItemIndex(currentItemIndex + 1)
      return handleSetForm(nextItem.properties)
    }
  }

  const handleOnFinishPreForm = async (values: MaterialServiceFormSchema) => {
    if (!prefillData.IS_DEMO) {
      await handleUpdateUploadedDocument(values)
    }
    await handleCreateDistributor(values)
  }

  const handleOnFinish = async (values: MaterialServiceFormSchema) => {
    if (edit) {
      handleEditMaterialService(values)
    } else {
      if (activeItem && activeItem.created) {
        handleProceedToNextItem()
      }

      // TODO make set state a promise and await it
      const distributorObject = await handleCreateDistributor(values)

      // @ts-ignore
      if (distributorObject?.errors) {
        return messageApi.error('Error creating distributor')
      }

      if (activeItem && activeItem.exists) {
        await handleAddPriceToExistingProduct(
          {
            ...values,
            distributor: distributorObject || activeDistributor,
          },
          activeItem.exists
        )
      } else {
        await handleCreateMaterialService({
          ...values,
          distributor: distributorObject || activeDistributor,
        })
      }
    }
  }

  const handleUpdateUploadedDocument = async (values: any) => {
    const { invoice_number, purchase_order_number } = values

    if (invoice_number || purchase_order_number) {
      try {
        const updatedUploadedDocument = await updateUploadedDocument({
          variables: {
            id: uploadedDocumentId,
            input: {
              ...(invoice_number && { invoice_number }),
              ...(purchase_order_number && { purchase_order_number }),
            },
          },
        })

        messageApi.success('Success: Information saved successfully!')
      } catch (error) {
        messageApi.error('Error updating uploaded document')
      }
    }
  }

  const handleCreateDistributor = async (values: any) => {
    let distributorId = values?.distributor?.selectValue?.value
    const { distributor = { name: '', email: '', phone: '' }, ...rest } = values

    if (!distributorId && distributor.name) {
      try {
        const newDistributor = await createDistributor({
          variables: {
            input: {
              name: distributor.name,
              ...(distributor.email && { email: distributor.email }),
              ...(distributor.phone && { phone: distributor?.phone }),
            },
          },
          onError(error, clientOptions) {
            setApiFormErrors(error.graphQLErrors, form, 'distributor')
          },
        })

        if (newDistributor?.data) {
          setactiveDistributor(newDistributor.data?.createDistributor?.data)

          // Update the distributors cache
          updateDistributorsQuery((data: any) => {
            return {
              distributors: [
                ...data.distributors,
                newDistributor.data?.createDistributor?.data,
              ],
            }
          })

          return newDistributor.data?.createDistributor?.data
        } else {
          return newDistributor
        }
      } catch (error) {
        messageApi.error('Error creating distributor')
      }
    } else if (distributorId) {
      setactiveDistributor({ id: distributorId })

      return { id: distributorId }
    }
  }

  const handleEditMaterialService = async (
    values: MaterialServiceFormSchema
  ) => {
    updateProduct({
      variables: {
        id: editItem.id,
        input: {
          ...values,
        },
      },
    }).then(({ data }: any) => {
      const { updateProduct } = data
      onMaterialServiceSave(updateProduct.data)
      messageApi.success('Success: Material updated successfully!')
    })
  }
  const handleAddPriceToExistingProduct = async (
    values: MaterialServiceFormSchema,
    product: any
  ) => {
    const { distributor, unit_amount, invoicedAt } = values

    createPrice({
      variables: {
        input: {
          productId: product.id,
          // @ts-ignore
          unit_amount: Math.round(parseFloat(unit_amount) * 100),
          ...(uploadedDocumentId && { uploadedDocumentId }),
          ...(distributor?.id && { distributorId: distributor.id }),
          ...(invoicedAt && {
            invoicedAt: new Date(invoicedAt).toUTCString(),
          }),
        },
      },
    }).then(({ data }: any) => {
      const { createPrice } = data
      if (itemsToCreate && itemsToCreate.length > 0) {
        itemsToCreate[currentItemIndex].created = true
        itemsToCreate[currentItemIndex].saved_price_to = product.id
        handleProceedToNextItem()
      } else {
        onMaterialServiceSave(createPrice.data)
      }
      messageApi.success('Success: Material updated successfully!')
    })
  }

  const handleCreateMaterialService = async (
    values: MaterialServiceFormSchema
  ) => {
    let distributorId = activeDistributor?.id || values?.distributor?.id
    const { description, unit_amount, distributor, sku, categories, ...rest } =
      values

    createMaterial({
      variables: {
        input: {
          uploadedDocumentId,
          // @ts-ignore
          unit_amount: Math.round(parseFloat(unit_amount) * 100),
          type: ProductTypeFieldInput.Good,
          ...(description && { description }),
          ...(distributorId && { distributorId }),
          ...(sku && { sku }),
          ...(categories && { categories: categories.map((c) => c.value) }),
          // type: createTypeIsMaterial
          //   ? ProductTypeFieldInput.Good
          //   : ProductTypeFieldInput.Service,
          ...(prefillData?.IS_DEMO && { metadata: { demo: true } }),
          ...rest,
        },
      },
      onCompleted(data: any = {}) {
        const { createProduct } = data

        if (itemsToCreate && itemsToCreate.length > 0) {
          itemsToCreate[currentItemIndex].created = true

          if (itemsToCreate[currentItemIndex].properties) {
            itemsToCreate[currentItemIndex].properties.categories =
              createProduct?.data?.categories
          }
          handleProceedToNextItem()
        } else {
          onMaterialServiceSave(createProduct.data)
        }
        messageApi.success('Success: Material created successfully!')

        // Update store
        addMaterial()
      },
      onError(error, clientOptions) {
        setApiFormErrors(error.graphQLErrors, form)
        messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      },
    })
  }

  const isMultiPartForm = useMemo(() => {
    return itemsToCreate.length > 1 && fileUrl
  }, [itemsToCreate, fileUrl])

  const handlePreviousItemClick = () => {
    if (currentItemIndex - 1 < 0) {
      return
    }

    const prevItem = itemsToCreate[currentItemIndex - 1]
    setActiveItem(prevItem)
    showSummary && setShowSummary(false)
    handleSetForm(prevItem.properties)
    setCurrentItemIndex(currentItemIndex - 1)
  }

  const getSubmitButtonText = () => {
    if (isMultiPartForm) {
      if (currentItemIndex + 1 >= itemsToCreate.length) {
        return 'Create'
      } else {
        return 'Create & Continue'
      }
    } else if (edit) {
      return 'Save'
    } else {
      return 'Create'
    }
  }
  const getDrawerTitle = () => {
    let title = 'Create New Material'

    if (edit) {
      title = 'Edit Material Details'
    } else if (showSummary) {
      title = 'Summary'
    } else if (isMultiPartForm) {
      title = `Create Material (${currentItemIndex + 1} / ${
        itemsToCreate.length
      })`
    }

    return title
  }

  const showRegularForm = () => {
    return !fileUrl || (fileUrl && activeDistributor?.id)
  }
  const showSkipButton = () => {
    return (
      !activeItem?.skipped &&
      !edit &&
      fileUrl &&
      activeDistributor?.id &&
      !showSummary
    )
  }

  const handleCheckForPossibleDuplicate = () => {
    let i = 0
    while (i < currentItemIndex) {
      if (
        itemsToCreate[i].created &&
        !itemsToCreate[i].priceSavedToDifferentSku &&
        activeItem?.properties?.productCode?.value &&
        activeItem?.properties?.productCode?.value ===
          itemsToCreate[i]?.properties?.productCode?.value
      ) {
        return true
      }
      i++
    }
    return false
  }

  const handleSkipItem = () => {
    itemsToCreate[currentItemIndex].skipped = true
    handleProceedToNextItem()
    messageApi.info('Skipped: Material creation has been skipped')
  }
  const handleUnskipItem = () => {
    itemsToCreate[currentItemIndex].skipped = false
    setActiveItem({ ...activeItem, skipped: false })
    messageApi.info('Unskipped: Material creation has been unskipped')
  }
  const handleGetPreviousDescription = () => {
    let prevIndex = currentItemIndex - 1
    if (itemsToCreate[prevIndex] && itemsToCreate[prevIndex].created) {
      return itemsToCreate[prevIndex].properties?.description?.value
    }
  }
  const handleSetPreviousDescription = () => {
    let prevIndex = currentItemIndex - 1
    if (itemsToCreate[prevIndex] && itemsToCreate[prevIndex].created) {
      form.setFieldValue(
        'description',
        itemsToCreate[prevIndex].properties?.description?.value
      )
    }
  }
  const handleSetPriceUpdate = () => {
    if (!itemsToCreate[currentItemIndex]) {
      return
    }
    itemsToCreate[currentItemIndex].exists = foundDuplicateSkuItem
    itemsToCreate[currentItemIndex].priceSavedToDifferentSku = true
    setActiveItem({
      ...activeItem,
      exists: { ...foundDuplicateSkuItem },
    })
  }

  const validateSkuField = React.useCallback(
    debounce(async (sku: any) => {
      if (sku) {
        setValidatingProductSku(true)
        const { data } = await validateProductSku({
          fetchPolicy: 'no-cache',
          variables: {
            input: { sku },
          },
          onCompleted(data) {
            setValidatingProductSku(false)
          },
        })

        if (
          data?.validateProductSku &&
          !data?.validateProductSku?.valid &&
          data?.validateProductSku?.data
        ) {
          setFoundDuplicateSkuItem(data.validateProductSku.data)
        }
      }
    }, 1500),
    []
  )

  // https://github.com/ant-design/ant-design/issues/24599
  const handleFormValuesChange = (values: any) => {
    if (!edit && values?.sku) {
      validateSkuField(values.sku)
    }

    // handleClearServerSideErrors
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field)
      if (!error.length) {
        return
      }
      // Clear error message of field
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ])
    })
  }

  return (
    <MaterialServiceFormDrawerStyled>
      {contextHolder}
      <DrawerForm<MaterialServiceFormSchema>
        title={getDrawerTitle()}
        form={form}
        open={open}
        autoFocusFirstInput
        drawerProps={{
          getContainer: false, // To supress form render warning(https://stackoverflow.com/questions/61056421/warning-instance-created-by-useform-is-not-connect-to-any-form-element)
          destroyOnClose: true,
          maskClosable: false,
          onClose: () => {
            handleResetForm()
            onClose()
          },
          width: DRAWER_WIDTH,
        }}
        onFinish={handleOnFinish}
        onValuesChange={debounce(handleFormValuesChange, 400)}
        submitter={{
          searchConfig: {
            submitText: getSubmitButtonText(),
          },
          submitButtonProps: {
            loading,
          },
          resetButtonProps: {},
          render: (props, [reset, submit]) => {
            return [
              isMultiPartForm && (
                <span key={'submitter-row'}>
                  <Space>
                    <Button
                      style={{ float: 'left' }}
                      key='back'
                      disabled={isMultiPartForm && currentItemIndex === 0}
                      onClick={() => {
                        handlePreviousItemClick()
                      }}
                    >
                      Back
                    </Button>
                    {showSkipButton() && (
                      <Button
                        type='dashed'
                        style={{ float: 'left' }}
                        key='skip'
                        disabled={activeItem?.created}
                        onClick={() => {
                          handleSkipItem()
                        }}
                      >
                        Skip
                      </Button>
                    )}
                  </Space>
                </span>
              ),
              !activeItem?.skipped &&
                !activeItem?.created &&
                !showSummary &&
                submit,
              (activeItem?.skipped || activeItem?.created) && !showSummary && (
                <Button
                  type='primary'
                  style={{ float: 'right' }}
                  key='next'
                  onClick={() => {
                    handleProceedToNextItem()
                  }}
                >
                  Next
                </Button>
              ),
              showSummary && (
                <Button
                  type='primary'
                  style={{ float: 'right' }}
                  key='done'
                  onClick={() => {
                    handleResetForm()
                    onClose()
                  }}
                >
                  Done
                </Button>
              ),
            ]
          },
        }}
      >
        {/* <>
          <div>Skipped: {activeItem?.skipped?.toString()}</div>
          <div>Exists: {activeItem?.exists?.id}</div>
          <div>Created: {activeItem?.created?.toString()}</div>
        </> */}
        {/* Alerts and notifications */}
        {activeItem?.created && (
          <Alert
            message='Material created successfully'
            type='success'
            showIcon
            style={{ marginBottom: '2em' }}
          />
        )}
        {activeItem?.skipped && (
          <>
            <Space
              direction='vertical'
              size='middle'
              style={{ display: 'flex', marginBottom: '2em' }}
            >
              <Alert
                message='Material creation skipped '
                type='info'
                showIcon
              />
              <Button
                type='primary'
                style={{ float: 'right' }}
                key='skip'
                onClick={() => {
                  handleUnskipItem()
                }}
              >
                Unskip
              </Button>
            </Space>
          </>
        )}
        {handleCheckForPossibleDuplicate() &&
          !activeItem?.created &&
          !foundDuplicateSkuItem && (
            <>
              <Space direction='vertical' style={{ marginBottom: '2em' }}>
                <Alert
                  message='This may be a duplicate'
                  description={`A product with the item number ${activeItem?.properties?.productCode?.value} has already been created. Skip creating this product to avoid duplicates.`}
                  type='warning'
                  showIcon
                />
                <Button
                  type='primary'
                  style={{ float: 'right' }}
                  key='skip'
                  disabled={isMultiPartForm && currentItemIndex === 0}
                  onClick={() => {
                    handleSkipItem()
                  }}
                >
                  Skip
                </Button>
              </Space>
            </>
          )}
        {activeItem?.exists &&
          activeDistributor?.id &&
          !activeItem?.created &&
          !activeItem?.skipped &&
          !foundDuplicateSkuItem &&
          !handleCheckForPossibleDuplicate() && (
            <>
              <Space direction='vertical' style={{ marginBottom: '2em' }}>
                <Alert
                  message='Update pricing'
                  description={`An item with sku:#${activeItem?.properties?.productCode?.value} already exists. Hit create to add a price entry and improve price accuracy.`}
                  type='success'
                  showIcon
                />
                <a
                  style={{ float: 'right' }}
                  href={`/materials/${activeItem?.exists.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <LinkOutlined /> View material
                </a>
              </Space>
            </>
          )}
        {isMultiPartForm &&
          foundDuplicateSkuItem &&
          !activeItem?.created &&
          !activeItem?.exists && (
            <>
              <Space direction='vertical' style={{ marginBottom: '2em' }}>
                <Alert
                  message='Update pricing'
                  description={`A product with the item number ${activeItem?.properties?.productCode?.value} already exists. Would you like to update that item's the price information?`}
                  type='info'
                  showIcon
                />
                <Button
                  type='primary'
                  style={{ float: 'right' }}
                  key='save_price'
                  onClick={() => {
                    handleSetPriceUpdate()
                  }}
                >
                  Update Price
                </Button>
              </Space>
            </>
          )}
        {/* 
          Pre-step: Form for saving the distributor 
          and metdata like PO number etc. It sets state 
          for use when saving line items 
        */}
        {!edit && fileUrl && !activeDistributor?.id && (
          <>
            <ProForm.Group>
              <ProFormText
                disabled={activeItem?.created || activeItem?.skipped}
                name='invoice_number'
                width='xl'
                rules={[
                  {
                    required: false,
                  },
                ]}
                label='Invoice Number'
                placeholder='INV_000000'
              />
            </ProForm.Group>
            <ProForm.Group>
              <ProFormText
                disabled={activeItem?.created || activeItem?.skipped}
                name='purchase_order_number'
                width='xl'
                rules={[
                  {
                    required: false,
                  },
                ]}
                label='Purchase Order Number'
                placeholder='PO_000000'
              />
            </ProForm.Group>
            <ProForm.Group>
              <DistributorSelectOrCreate
                parentForm={form}
                onValuesChange={debounce(handleFormValuesChange, 400)}
                // A little hacky since the internal form now has a
                // dependency on the parent form and handles submitter
                // @ts-ignore
                onFinish={handleOnFinishPreForm}
              />
            </ProForm.Group>
          </>
        )}
        {/* 
          Regular-step: Normal form when no file_url is uploaded OR 
          the prestep is completed (distributorId set). 
        */}
        {showRegularForm() && (
          <>
            {!showSummary && (
              <>
                {!activeItem?.exists && (
                  <>
                    <ProForm.Group>
                      <ProFormText
                        disabled={activeItem?.created || activeItem?.skipped}
                        fieldProps={{
                          'data-1p-ignore': '',
                        }}
                        name='name'
                        width='xl'
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        label='Name'
                        placeholder='GOUTR200R'
                      />
                      <ProFormText
                        disabled={activeItem?.created || activeItem?.skipped}
                        name='description'
                        width='xl'
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                        label={
                          <Space
                            direction='horizontal'
                            size={'small'}
                            split={<Divider type='vertical' />}
                          >
                            Description
                            {handleGetPreviousDescription() &&
                              !activeItem?.created &&
                              !activeItem?.skipped && (
                                <a>
                                  <Typography.Text
                                    type='secondary'
                                    italic
                                    ellipsis
                                    onClick={handleSetPreviousDescription}
                                    style={{ maxWidth: '275px' }}
                                  >
                                    <RedoOutlined
                                      style={{ marginRight: '0.25em' }}
                                    />
                                    {handleGetPreviousDescription()}
                                  </Typography.Text>
                                </a>
                              )}
                          </Space>
                        }
                        placeholder='Fuse CL RK5 Time Delay 200A'
                      />
                    </ProForm.Group>
                    <ProForm.Group>
                      <ProFormText
                        disabled={activeItem?.created || activeItem?.skipped}
                        name='sku'
                        width='xl'
                        label={
                          <Space>
                            Item Number / SKU
                            {validatingProductSku && <LoadingOutlined />}
                          </Space>
                        }
                        placeholder='xxxx-xxxx-xxxx'
                      />
                    </ProForm.Group>
                  </>
                )}
                {!edit && (
                  <>
                    <ProForm.Group>
                      <ProFormDatePicker
                        disabled={activeItem?.created || activeItem?.skipped}
                        name='invoicedAt'
                        label='Invoice Date'
                        placeholder='00/00/0000'
                        width='xl'
                      />
                    </ProForm.Group>
                  </>
                )}
                <Row gutter={16}>
                  {!edit && (
                    <Col span={12}>
                      <ProForm.Group>
                        <ProFormMoney
                          disabled={activeItem?.created || activeItem?.skipped}
                          name='unit_amount'
                          width='xl'
                          label='Unit Cost'
                          placeholder='0.00'
                        />
                      </ProForm.Group>
                    </Col>
                  )}

                  <Col span={edit ? 24 : 12}>
                    <Form.Item label='Unit Type' name='unit_type'>
                      <Select
                        style={{ width: '500px', maxWidth: '100%' }}
                        disabled={activeItem?.created || activeItem?.skipped}
                        options={[
                          { value: 'E', label: 'E' },
                          { value: 'C', label: 'C' },
                          { value: 'M', label: 'M' },
                          { value: 'ea', label: 'ea' },
                          { value: 'ft', label: 'ft' },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {!activeItem?.exists && (
                  <>
                    <Form.Item label='Categories' name='categories'>
                      <CategorySelect
                        disabled={activeItem?.created || activeItem?.skipped}
                        onAddClick={onCreateNewCategory}
                        createButtonVisible={true}
                        createButtonText='New category'
                        recentlyCreatedCategory={
                          newCategory
                            ? [
                                {
                                  value: newCategory.id,
                                  label: newCategory.name,
                                },
                              ]
                            : []
                        }
                      />
                    </Form.Item>
                    <ProForm.Group>
                      <ProFormTextArea
                        disabled={activeItem?.created || activeItem?.skipped}
                        width='xl'
                        name='notes'
                        label='Notes'
                        placeholder='Additional notes'
                      />
                    </ProForm.Group>
                  </>
                )}
                {((!fileUrl && !activeDistributor?.id && !edit) ||
                  (!isMultiPartForm && !edit)) && (
                  <ProForm.Group>
                    <DistributorSelectOrCreate
                      parentForm={form}
                      onValuesChange={handleFormValuesChange}
                      // A little hacky since the internal form now has a
                      // dependency on the parent form and handles submitter
                      // @ts-ignore
                      onFinish={handleOnFinish}
                    />
                  </ProForm.Group>
                )}
              </>
            )}

            {showSummary && (
              <>
                <MultiStepMaterialDrawerSummary items={itemsToCreate} />
              </>
            )}
          </>
        )}
        {fileUrl && (
          <div
            style={{
              position: 'fixed',
              left: calculateDocPosition(DRAWER_WIDTH).left,
              width: calculateDocPosition(DRAWER_WIDTH).width,
              maxHeight: calculateDocPosition(DRAWER_WIDTH).height,
              top: '10%',
              overflow: 'scroll',
              textAlign: 'center',
            }}
          >
            {fileMimeType == 'application/pdf' && (
              <PDFViewer
                fileUrl={fileUrl}
                pageWidth={calculateDocPosition(DRAWER_WIDTH).width}
              />
            )}
            {fileMimeType != 'application/pdf' && <img src={fileUrl} />}
            {/* <Document file='https://voltappteststorage.blob.core.windows.net/test-container/gescan-invoice-1.png?sp=r&st=2023-09-14T00:56:45Z&se=2023-09-14T08:56:45Z&spr=https&sv=2022-11-02&sr=b&sig=eIz7X1B6asHsiSA2MzwfQL4hMTJVq63Y5EReIhd6jic%3D'> */}
          </div>
        )}
      </DrawerForm>
    </MaterialServiceFormDrawerStyled>
  )
}

MaterialServiceFormDrawer.displayName = 'MaterialServiceFormDrawer'

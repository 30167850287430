import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const EstimatePDFPreviewStyled = styled.div`
  font-size: ${rem(16)};
  width: 100%;
  height: 100%;
  margin-bottom: 2em;

  .pdf-preview-container {
    background: #fff;
    padding: 12px 24px;
    border: 1px solid ${(props) => props.theme.colors.border};
  }

  .amounts-row {
    padding: 12px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }
  .signature-block {
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    margin: 30px 0 12px 0;
  }
`

import { Form, Typography } from 'antd'
import type { FormInstance } from 'antd/es/form'
import * as React from 'react'

import { formatMoney } from '../../../utils'
import { useAppStore } from '../../../stores/appStore'
import { EstimatesFormTotalDescriptionStyled } from './styles'

export interface EstimatesFormTotalDescriptionProps {
  form?: FormInstance
  currency?: string | null
  showBreakdown?: boolean
  allowTaxRemove?: boolean
  onTaxRemove?: (tax: any) => void
}

export const EstimatesFormTotalDescription = ({
  form = {} as FormInstance,
  currency,
  ...props
}: EstimatesFormTotalDescriptionProps) => {
  const getTotal = useAppStore((state: any) => state.estimateTotal)

  return (
    <EstimatesFormTotalDescriptionStyled>
      <Typography.Text>Estimate Total:</Typography.Text>
      <Typography.Text
        style={{ marginRight: '2em', fontWeight: 'bold' }}
        data-testid='estimate-total-submit-bar'
      >
        {formatMoney(getTotal)} {currency}
      </Typography.Text>
    </EstimatesFormTotalDescriptionStyled>
  )
}

EstimatesFormTotalDescription.displayName = 'EstimatesFormTotalDescription'

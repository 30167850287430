import {
  PlusOutlined,
  UserAddOutlined,
  EyeOutlined,
  DownloadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  RightCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Select,
  Table,
  Typography,
  Empty,
  ConfigProvider,
  Skeleton,
  Layout,
  Descriptions,
  List,
  Avatar,
  Statistic,
  Modal,
  Dropdown,
  message,
} from 'antd'
import { FormInstance, useForm } from 'antd/lib/form/Form'
import { Content } from 'antd/lib/layout/layout'
import { ColumnsType } from 'antd/lib/table'
import * as React from 'react'
import { useParams, redirect, useNavigate } from 'react-router-dom'
import CountUp from 'react-countup'

import {
  useGetCustomerQuery,
  useGetEstimatesQuery,
  useGetEstimatesLazyQuery,
  useDeleteCustomerMutation,
  CustomerDetailFragment,
  EstimateDetailFragment,
} from '../../graphql/_generated-hooks'
import { formatMoney, formatDate } from '../../utils'
import { PageTitle } from '../../components/PageTitle'
import { CustomerFormDrawer } from '../../components/CustomerFormDrawer'
import { DemoItemTag } from '../../components/DemoItemTag'
import { EstimatesTable } from '../../components/EstimatesTable'
import { SkeletonLoader } from '../../components/SkeletonLoader'
import { AddressDisplay } from '../../components/AddressDisplay'
import { CustomerDetailViewStyled } from './styles'
import { theme } from '../../styles/themes/default'

export interface CustomerDetailViewProps {}

const customerActions = [
  {
    id: 'delete-customer-button',
    key: 'delete',
    label: 'Delete Customer',
    icon: <DeleteOutlined />,
    danger: true,
  },
]

const formatter = (value: any) => <CountUp end={value} separator=',' />

export const CustomerDetailView = ({ ...props }: CustomerDetailViewProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [activeCustomer, setActiveCustomer] =
    React.useState<CustomerDetailFragment>({ id: '', name: '' })
  const [estimateData, setEstimateData] = React.useState<
    EstimateDetailFragment[]
  >([])
  const [editCustomer, setEditCustomer] = React.useState(false)

  let navigate = useNavigate()
  let { id } = useParams()

  if (!id) {
    redirect('/customers')
  }

  const [getEstimates, { loading: isLoadingEstimates, error }] =
    useGetEstimatesLazyQuery()
  const [deleteCustomerMutation, { loading: isDeletingCustomer }] =
    useDeleteCustomerMutation()

  // Get Customer
  const {
    data,
    loading: isLoadingCustomer,
    refetch: refetchCustomer,
    updateQuery: updateCustomerQuery,
  } = useGetCustomerQuery({
    variables: { id: id || '' },
    onCompleted({ customer }) {
      if (customer) {
        setActiveCustomer(customer)

        const jobId = customer?.jobs?.[0]?.id || ''

        getEstimates({
          variables: {
            input: {
              ...(jobId && { jobId }),
            },
          },
          onCompleted({ estimates }) {
            if (estimates && estimates.data) {
              // @ts-ignore
              setEstimateData(estimates.data)
            }
          },
        })
      }
    },
  })

  const handleJobLink = (item: any) => {
    navigate(`/projects/${item.id || item.external_id}`, {})
  }
  const handleCustomerFormClose = () => {
    setEditCustomer(false)
  }
  const handleCreateEstimate = () => {
    if (activeCustomer?.jobs?.length) {
      // @ts-ignore
      navigate(`/estimates/create?jobId=${activeCustomer?.jobs[0].id}`, {})
    }
  }

  const onActionMenuClick = (e: any) => {
    const info = e.key
    switch (info) {
      case 'delete':
        Modal.confirm({
          title: `Delete ${activeCustomer.name}?`,
          content:
            'Are you sure you want to delete the customer? This action cannot be undone.',
          okText: 'Confirm',
          okType: 'danger',
          cancelText: 'Cancel',
          okButtonProps: {
            // loading: isDeletingMaterial,
          },
          onOk() {
            deleteCustomerMutation({
              variables: {
                id: activeCustomer.id,
              },
            }).then(() => {
              navigate(
                `/customers?message=${activeCustomer.name} has been deleted&status=success`
              )
            })
          },
        })
        break
    }
  }

  return (
    <CustomerDetailViewStyled>
      {contextHolder}
      <Content style={{ padding: '2em' }}>
        <Row align='middle'>
          <Col flex='1 0'>
            <PageTitle
              title={activeCustomer.name}
              subtitle={'Customer details'}
            />
          </Col>
          <Col flex='1 0' style={{ textAlign: 'right' }}>
            <Space>
              {activeCustomer?.metadata?.demo ? (
                <DemoItemTag />
              ) : (
                <Dropdown.Button
                  data-testid='edit-customer-more-button'
                  onClick={() => setEditCustomer(true)}
                  menu={{ items: customerActions, onClick: onActionMenuClick }}
                >
                  <EditOutlined />
                  Edit Customer
                </Dropdown.Button>
              )}
            </Space>
          </Col>
        </Row>
      </Content>
      <Layout style={{ padding: '0 2em' }}>
        <Content
          style={{ marginBottom: '2em', background: '#fff', padding: '24px' }}
        >
          {isLoadingCustomer && <SkeletonLoader pageType='detail' rows={10} />}

          {!isLoadingCustomer && (
            <>
              <Row
                gutter={[16, 24]}
                justify='space-between'
                style={{ marginBottom: '4em' }}
              >
                <Col span={6}>
                  <Descriptions layout='vertical'>
                    <Descriptions.Item label='Billing Address'>
                      <Space direction='vertical'>
                        <Typography.Text strong={true}>
                          {activeCustomer?.name}
                        </Typography.Text>
                        <AddressDisplay address={activeCustomer} />
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={6}>
                  {activeCustomer?.email && (
                    <Descriptions layout='vertical'>
                      <Descriptions.Item label='Contact info'>
                        <Space direction='vertical' size={'small'}>
                          <Typography.Text
                            strong={true}
                            copyable={true}
                            data-testid='customer-email-display'
                          >
                            {activeCustomer?.email}
                          </Typography.Text>
                          {activeCustomer?.phone && (
                            <Typography.Text
                              copyable={true}
                              data-testid='customer-phone-display'
                            >
                              {activeCustomer?.phone}
                            </Typography.Text>
                          )}
                        </Space>
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </Col>
                <Col span={9} offset={3} />
              </Row>

              <Row gutter={[16, 24]} style={{ marginBottom: '4em' }}>
                <Col span={12}>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    Estimates
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  {activeCustomer?.jobs && activeCustomer?.jobs?.length > 0 && (
                    <Button
                      type='text'
                      style={{ float: 'right' }}
                      onClick={handleCreateEstimate}
                      data-testid='new-estimate-button'
                    >
                      + New Estimate
                    </Button>
                  )}
                </Col>
                <Col span={24}>
                  <EstimatesTable
                    data={estimateData || []}
                    tableProps={{ loading: isLoadingEstimates }}
                    onEstimateClick={() => {}}
                    onEstimateDelete={() => {}}
                  />
                </Col>
              </Row>
            </>
          )}
        </Content>
      </Layout>

      <CustomerFormDrawer
        customer={activeCustomer}
        open={editCustomer}
        onClose={handleCustomerFormClose}
        onCustomerSave={({ data }: any) => {
          setActiveCustomer(data)
          handleCustomerFormClose()
        }}
      />
    </CustomerDetailViewStyled>
  )
}

CustomerDetailView.displayName = 'CustomerDetailView'

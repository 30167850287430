import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Tag } from 'antd'
import * as React from 'react'

import { MaterialSelectItemRenderStyled } from './styles'
export interface MaterialSelectItemRenderProps {
  title: string
  subtitle?: string
}

export const MaterialSelectItemRender = ({
  title = 'N/A',
  subtitle,
}: MaterialSelectItemRenderProps) => {
  return (
    <MaterialSelectItemRenderStyled>
      <Typography.Text>{title}</Typography.Text>
      {subtitle && (
        <>
          {' '}
          - <Typography.Text type='secondary'>{subtitle}</Typography.Text>
        </>
      )}
    </MaterialSelectItemRenderStyled>
  )
}

MaterialSelectItemRender.displayName = 'MaterialSelectItemRender'

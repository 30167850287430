import { PlusOutlined, UserAddOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Select,
  Table,
  Typography,
  Empty,
  Modal,
  Skeleton,
  Tabs,
  Pagination,
} from 'antd'
import { FormInstance, useForm } from 'antd/lib/form/Form'
import { Content } from 'antd/lib/layout/layout'
import { ColumnsType } from 'antd/lib/table'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { isValid } from 'date-fns'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'

import {
  useGetMeQuery,
  useGetProductsQuery,
  useGetProductListsQuery,
  useGetUploadedDocumentsQuery,
  useDeleteProductListMutation,
  ProductDetailFragment,
  ProductListDetailFragment,
} from '../../graphql/_generated-hooks'
import { memoryStore, getDistributorSlug } from '../../utils'
import { PageTitle } from '../../components/PageTitle'
import { MaterialsTable } from '../../components/MaterialsTable'
import { MaterialListsTable } from '../../components/MaterialListsTable'
import { MaterialServiceFormDrawer } from '../../components/MaterialServiceFormDrawer'
import { MaterialListFormDrawer } from '../../components/MaterialListFormDrawer'
import { UploadEmptyState } from '../../components/UploadEmptyState'
import { LoadingOverlay } from '../../components/LoadingOverlay'
import { SkeletonLoader } from '../../components/SkeletonLoader'
import { RecentUploadsTable } from '../../components/RecentUploadsTable'
import { TemplatesViewStyled } from './styles'
import { on } from 'events'

const { Search } = Input
const defaultPageSize = 15

export interface TemplatesViewProps {}

export interface ItemSchema {
  name?: string
  type?: string
  sku?: string
  quantity?: number
  price?: number
  total?: 0
}
export interface MaterialFormSchema {
  /** if `id` exist, we are editing */
  id?: string
  title?: string
  number?: string
  customer?: string
  items?: ItemSchema[]
}

export const TemplatesView = React.forwardRef(
  ({ ...props }: TemplatesViewProps, onboardingStepRef: any) => {
    const [createMaterialList, setCreateMaterialList] = React.useState(false)
    const [materialListToEdit, setMaterialListToEdit] =
      React.useState<ProductListDetailFragment | null>()
    const [searchQuery, setSearchQuery] = React.useState<any>(null)
    const [currentOffset, setCurrentOffset] = React.useState<any>(null)

    const [deleteProductListMutation, { loading: isDeletingProductList }] =
      useDeleteProductListMutation()

    const navigate = useNavigate()
    const onboardingStepRefUpload = React.useRef(null)
    const onboardingStepRefCreate = React.useRef(null)

    React.useImperativeHandle(onboardingStepRef, () => ({
      get upload() {
        return onboardingStepRefUpload.current
      },
      get create() {
        return onboardingStepRefCreate.current
      },
    }))

    // Get Product Lists
    // TODO refactor lists into their own gql file on api -- key clashing since it is the same file
    const {
      data: { productLists = { data: [], count: 0 } } = {
        productLists: { data: [], count: 0 },
      },
      loading: isLoadingProductLists,
      refetch: refetchProductLists,
      updateQuery: updateProductLists,
    } = useGetProductListsQuery({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          limit: defaultPageSize,
          // offset: 0,
        },
      },
    })

    const handleAddNewMaterialList = () => {
      setCreateMaterialList(true)
    }
    const handleMaterialListFormClose = () => {
      setCreateMaterialList(false)
      setMaterialListToEdit(null)
    }

    const onSearch = (value: string) => {
      setSearchQuery(value)
      refetchProductLists({
        input: {
          limit: defaultPageSize,
          search: value,
        },
      })
    }
    const onSort = (field: string, order: string) => {
      refetchProductLists({
        // @ts-ignore
        input: {
          limit: defaultPageSize,
          ...(order && {
            sort: {
              field,
              order: 'ascend' == order ? 'desc' : 'asc',
            },
          }),
        },
      })
    }
    const onPageChange = (page: number, pageSize?: number) => {
      let offset = page * pageSize! - pageSize!
      setCurrentOffset(offset)
      refetchProductLists({
        input: {
          limit: pageSize,
          offset,
          ...(searchQuery && { search: searchQuery }),
        },
      })
    }

    const onMaterialListCreated = () => {
      setCreateMaterialList(false)
      setMaterialListToEdit(null)
      refetchProductLists()
    }

    const onMaterialListDelete = (data: any) => {
      Modal.confirm({
        title: `Delete ${data.name}?`,
        content:
          'Are you sure you want to delete the template? This action cannot be undone.',
        okText: 'Confirm',
        okType: 'danger',
        cancelText: 'Cancel',
        okButtonProps: {
          loading: isDeletingProductList,
        },
        onOk() {
          deleteProductListMutation({
            variables: {
              id: data.id,
            },
          }).then(() => {
            refetchProductLists({
              input: {
                limit: defaultPageSize,
                offset: currentOffset,
              },
            })
          })
        },
      })
    }

    return (
      <TemplatesViewStyled>
        <Content style={{ padding: '2em' }}>
          <Row align='middle'>
            <Col flex='1 0'>
              <PageTitle
                title='Assemblies'
                subtitle='Manage assemblies that are used to quickly build estimates'
              />
            </Col>
            <Col flex='1 0' style={{ textAlign: 'right' }}>
              <Space>
                <Button
                  ref={onboardingStepRefCreate}
                  onClick={handleAddNewMaterialList}
                >
                  <PlusOutlined />
                  Add Assembly
                </Button>
              </Space>
            </Col>
          </Row>
        </Content>
        <Content style={{ padding: '0 2em 2em 2em' }}>
          <Search
            placeholder='Search'
            onSearch={onSearch}
            onChange={debounce((e) => {
              const value = e.target.value
              if (value && value.length > 1) {
                onSearch(value)
              } else if (value === '') {
                onSearch('')
              }
            }, 300)}
            allowClear={true}
            style={{ width: 350, float: 'right' }}
          />
          <MaterialListsTable
            data={productLists?.data || []}
            tableProps={{ loading: isLoadingProductLists }}
            onMaterialListClick={(data) => {
              setMaterialListToEdit(data)
              setCreateMaterialList(true)
            }}
            onMaterialListDelete={onMaterialListDelete}
            onChange={debounce((pagination, filters, sorter) => {
              onSort(sorter.field, sorter.order)
            }, 400)}
            selectedId={''}
          />
          <Pagination
            defaultCurrent={1}
            defaultPageSize={defaultPageSize}
            showSizeChanger={false}
            total={productLists?.count}
            onChange={onPageChange}
            style={{ float: 'right' }}
          />
        </Content>

        <MaterialListFormDrawer
          open={createMaterialList}
          onClose={handleMaterialListFormClose}
          onMaterialListCreated={onMaterialListCreated}
          edit={materialListToEdit ? true : false}
          initialValues={materialListToEdit}
        />

        {/* TODO move to own component */}
      </TemplatesViewStyled>
    )
  }
)

TemplatesView.displayName = 'TemplatesView'

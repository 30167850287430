import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const LogoDisplayStyled = styled.div`
  padding: ${rem(16)};

  .logo-display-placeholder {
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: ${rem(2)} dashed #ccc;
    border-radius: ${rem(4)};
  }
`

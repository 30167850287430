import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const LockableButtonStyled = styled.div`
  font-size: ${rem(16)};

  > span.ant-tag {
    text-align: center;
    min-width: 75px;
  }
`

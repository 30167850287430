import { FilterOutlined } from '@ant-design/icons'
import { Button, Popover, Divider, Space } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { TableFilterButtonStyled } from './styles'
import { capitalizeString } from '../../utils'

export interface TableFilterButtonProps {
  title?: string
  buttonLabel?: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  content?: React.ReactNode
  activeFilterCount?: number
}

export const TableFilterButton = ({
  title = 'Filters',
  buttonLabel = 'Filter',
  placement = 'bottom',
  content,
  activeFilterCount,
}: TableFilterButtonProps) => {
  const [open, setOpen] = React.useState(false)
  const [appliedFilterCount, setAppliedFilterCount] = React.useState<number>(0)

  React.useEffect(() => {
    setAppliedFilterCount(activeFilterCount || 0)
    setOpen(false)
  }, [activeFilterCount])

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  return (
    <TableFilterButtonStyled>
      <Popover
        content={
          <>
            <Divider />
            <Space
              direction='vertical'
              style={{ width: '100%', paddingBottom: '20px' }}
            >
              {content}
            </Space>
          </>
        }
        title={title}
        trigger='click'
        placement={placement}
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button icon={<FilterOutlined />}>
          {buttonLabel} {appliedFilterCount > 0 && `(${appliedFilterCount})`}
        </Button>
      </Popover>
    </TableFilterButtonStyled>
  )
}

TableFilterButton.displayName = 'TableFilterButton'

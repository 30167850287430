import { LoadingOutlined } from '@ant-design/icons'
import { message, Form } from 'antd'
import { DrawerForm, ProForm, ProFormText } from '@ant-design/pro-components'
import * as React from 'react'
import debounce from 'lodash/debounce'

import { theme } from '../../styles/themes/default'
import { DistributorCreateFormDrawerStyled } from './styles'
import { capitalizeString, setApiFormErrors } from '../../utils'
import { useCreateDistributorMutation } from '../../graphql/_generated-hooks'

const DRAWER_WIDTH = 450

export interface DistributorCreateFormDrawerProps {
  onClose?: () => void
  onSave?: (data: any) => void
  open?: boolean
  mode?: 'create' | 'edit'
  isDemo?: boolean
  width?: number
}

export interface DistributorFormSchema {
  id: string
  name: string
  email: string
  phone: string
}
export const DistributorCreateFormDrawer = ({
  onSave = (data: any) => {},
  onClose = () => {},
  open,
  mode = 'create',
  isDemo = false,
  width = DRAWER_WIDTH,
}: DistributorCreateFormDrawerProps) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [form] = Form.useForm<any>()

  const [createDistributor, { loading: createDistributorLoading }] =
    useCreateDistributorMutation()

  const isCreateMode = () => mode === 'create'

  const handleOnFinish = async (values: DistributorFormSchema) => {
    createDistributor({
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted({ createDistributor }: any = {}) {
        messageApi.success('Success: Distributor created successfully!')

        onSave(createDistributor?.data)
      },
      onError(error, clientOptions) {
        setApiFormErrors(error.graphQLErrors, form)
        messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
      },
    })
  }

  return (
    <DistributorCreateFormDrawerStyled>
      {contextHolder}
      <DrawerForm<DistributorFormSchema>
        id='distributor-form'
        title={`${capitalizeString(mode)} distributor`}
        open={open}
        form={form}
        autoFocusFirstInput
        drawerProps={{
          getContainer: false, // To supress form render warning(https://stackoverflow.com/questions/61056421/warning-instance-created-by-useform-is-not-connect-to-any-form-element)
          destroyOnClose: true,
          maskClosable: false,
          onClose: () => {
            onClose()
          },
          width,
        }}
        submitter={{
          searchConfig: {
            submitText: 'Create',
            resetText: 'Cancel',
          },
          submitButtonProps: {
            loading: createDistributorLoading,
            id: 'distributor-form-submit-button',
          },
        }}
        onFinish={handleOnFinish}
      >
        <ProForm.Group>
          <ProFormText
            name={'name'}
            width='xl'
            rules={[
              {
                required: true,
              },
            ]}
            label='Name'
            placeholder='Company ltd.'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText
            name={'email'}
            width='xl'
            rules={[
              {
                required: true,
              },
            ]}
            label='Email'
            placeholder='example@company.com'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormText
            name={'phone'}
            width='xl'
            label='Phone'
            placeholder='555 520 4239'
          />
        </ProForm.Group>
      </DrawerForm>
    </DistributorCreateFormDrawerStyled>
  )
}

DistributorCreateFormDrawer.displayName = 'DistributorCreateFormDrawer'

import * as React from 'react'

export interface StripePricingTableProps {
  // email?: any
  clientSecret: any
}

// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

const pricingTableID = process.env.REACT_APP_PRICING_TABLE_ID
const pricingTablePubKey = process.env.REACT_APP_PRICING_TABLE_PUBLISHABLE_KEY

export const StripePricingTable = ({
  // email,
  clientSecret,
}: StripePricingTableProps) => {
  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableID}
      publishable-key={pricingTablePubKey}
      // customer-email={email}
      customer-session-client-secret={clientSecret}
    />
  )
}

StripePricingTable.displayName = 'StripePricingTable'

import { Info } from 'react-feather'
import type { TableColumnsType } from 'antd'
import { Table, Typography, Tag, Popover, Space } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import * as React from 'react'

import { ProductDetailFragment } from '../../graphql/_generated-hooks'
import { MaterialsTableStyled } from './styles'
import { PriceAgeTag } from '../PriceAgeTag'
import { DemoItemTag } from '../DemoItemTag'
import { MaterialNameDescription } from '../MaterialNameDescription'
import { ServiceItemBadge } from '../ServiceItemBadge'
import { formatDate, formatMoney } from '../../utils'

const { Title, Paragraph } = Typography

interface ExtendedProductDetailFragment extends ProductDetailFragment {
  latest_price: any
}

export interface MaterialsTableProps {
  data: any // TODO: ProductDetailFragment[]
  tableProps?: TableProps<ExtendedProductDetailFragment>
  selectedId?: string
  controlledSort?: any
  onMaterialClick?: (user: ExtendedProductDetailFragment, event: any) => void
  onMaterialDelete?: (user: ExtendedProductDetailFragment) => void
  onChange?: (pagination: any, filters: any, sorter: any, extra: any) => void
  onAddToList?: (user: ExtendedProductDetailFragment) => void
}

const findLatestInvoicedPrice = (prices: any) => {
  if (!prices || prices.length === 0) prices = []

  // TODO hacky because groupBy on the product query
  // is not working and will mess up our counts. Multiple
  // order by clauses will throw a SQL error.
  prices.sort((x: any, y: any) => {
    return y?.invoicedAt - x?.invoicedAt
  })

  return prices[0] || {}
}

export const MaterialsTable = ({
  data = [],
  tableProps: { loading } = {},
  selectedId,
  controlledSort,
  onMaterialClick = () => undefined,
  onMaterialDelete = () => undefined,
  onAddToList = () => undefined,
  onChange = () => undefined,
}: MaterialsTableProps) => {
  const [sortInfo, setSortInfo] = React.useState<any>({
    columnKey: 'name',
    order: 'ascend',
  })

  React.useEffect(() => {
    if (
      controlledSort &&
      controlledSort.field == sortInfo.field &&
      !controlledSort.order
    ) {
      setSortInfo({
        columnKey: sortInfo.field,
        order: null,
      })
    }
  }, [controlledSort])

  const tableColumns: ColumnsType<ExtendedProductDetailFragment> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
      sortOrder: sortInfo.columnKey === 'name' ? sortInfo.order : null,
      ellipsis: true,
      render: (_, { id, name, description, sku }) => (
        <MaterialNameDescription name={name} description={description} />
      ),
    },
    // {
    //   title: 'Material name',
    //   dataIndex: 'name',
    //   key: 'name',
    //   sorter: true,
    // },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      width: 200,
      render: (_, { id, name, sku }) => (
        <Typography>
          <Paragraph
            style={{ marginBottom: 0 }}
            ellipsis
            copyable={sku ? true : false}
          >
            {sku || '-'}
          </Paragraph>
        </Typography>
      ),
    },
    {
      title: 'Purchased from',
      dataIndex: 'distributor',
      ellipsis: true,
      render: (_, { prices, latest_price }) => {
        const { distributor } = latest_price

        return (
          <Typography>
            <Paragraph style={{ marginBottom: 0 }} ellipsis>
              {distributor?.name || '-'}
            </Paragraph>
          </Typography>
        )
      },
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      ellipsis: true,
      align: 'center',
      render: (_, { categories }) => {
        if (!categories || !categories.length) return ' - '

        const categoryNames = categories?.map((category) => category?.name)
        return (
          <>
            <Popover
              placement='topLeft'
              content={() => {
                return (
                  <Space direction='vertical'>
                    {categoryNames.map((categoryName, $index) => (
                      <span key={$index}>{categoryName || ''}</span>
                    ))}
                  </Space>
                )
              }}
              title='Categories'
            >
              {categoryNames.map((categoryName, $index) => (
                <Tag key={$index}>{categoryName || ''}</Tag>
              ))}
            </Popover>
          </>
        )
      },
    },
    {
      title: 'Lastest price',
      dataIndex: 'prices',
      key: 'prices',
      align: 'left',
      width: 200,
      render: (_, { id, name, prices, latest_price }) => {
        if (latest_price) {
          const { unit_amount } = latest_price
          return (
            <Typography>
              <Paragraph style={{ marginBottom: 0 }} ellipsis copyable={true}>
                {formatMoney(unit_amount)}
              </Paragraph>
            </Typography>
          )
        }
      },
    },
    // {
    //   title: 'Unit Type',
    //   dataIndex: 'unit_type',
    //   key: 'unit_type',
    //   align: 'center',
    //   sorter: true,
    // },
    {
      width: 150,
      title: 'Status',
      dataIndex: 'status',
      render: (_, { prices, latest_price, type, metadata }) => {
        const { invoicedAt } = latest_price

        return metadata?.demo ? (
          // <Badge count={<Info size={16} style={{ color: '#f5222d' }} />} />
          <DemoItemTag />
        ) : type === 'service' ? (
          <ServiceItemBadge />
        ) : (
          <PriceAgeTag latestPriceDate={invoicedAt} showTooltip={true} />
        )
      },
    },
    // {
    //   title: '',
    //   key: 'action',
    //   fixed: 'right',
    //   width: 100,
    //   // eslint-disable-next-line react/display-name
    //   render: (data) => {
    //     return (
    //       <div onClick={(e) => e.stopPropagation()}>
    //         <Space>
    //           <Popconfirm
    //             title='Delete this plan？'
    //             okText='Yes'
    //             cancelText='No'
    //             onConfirm={() => data.onDelete(data)}
    //           >
    //             <Button shape='circle' icon={<DeleteOutlined />} />
    //           </Popconfirm>
    //           {/*
    //           <Tooltip title='Send to Hire'>
    //             <Button
    //               shape='circle'
    //               icon={<SendOutlined />}
    //               onClick={() => data.onAddToList(data)}
    //             />
    //           </Tooltip> */}

    //           {/* <Tooltip title='Duplicate plan'>
    //             <Button
    //               shape='circle'
    //               icon={<CopyOutlined />}
    //               onClick={() => data.onDuplicatePlan(data)}
    //             />
    //           </Tooltip> */}
    //         </Space>
    //       </div>
    //     )
    //   },
    // },
  ]

  return (
    <MaterialsTableStyled>
      <Table<ExtendedProductDetailFragment>
        dataSource={data?.map((product: any) => ({
          key: product.id,
          onDelete: onMaterialDelete,
          onAddToList,
          latest_price: findLatestInvoicedPrice(product?.prices),
          ...product,
        }))}
        loading={loading}
        columns={tableColumns}
        onRow={(row, rowIndex) => {
          return {
            onClick: (event) => {
              onMaterialClick(row, event)
            },
          }
        }}
        onChange={(pagination: any, filters: any, sorter: any, extra: any) => {
          setSortInfo(sorter)
          onChange(pagination, filters, sorter, extra)
        }}
        rowClassName={(item) =>
          `selectable ${item.id === selectedId ? 'selected' : ''}`
        }
        pagination={false}
        // locale={{
        //   emptyText: (
        //     <Empty
        //       image={Empty.PRESENTED_IMAGE_SIMPLE}
        //       description='No products found'
        //     />
        //   ),
        // }}
      />
    </MaterialsTableStyled>
  )
}

MaterialsTable.displayName = 'MaterialsTable'

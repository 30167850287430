import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  StrikethroughOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Space, Divider } from 'antd'
import * as React from 'react'
import {
  Remirror,
  EditorComponent,
  useRemirror,
  useCommands,
  useActive,
  useHelpers,
  useEditorEvent,
  useDocChanged,
  OnChangeJSON,
  OnChangeHTML,
} from '@remirror/react'

import { RichTextEditorMenuStyled } from './styles'
import { RichTextEditorButton } from '../RichTextEditorButton'

export interface RichTextEditorMenuProps {
  icon?: any
  isActive?: boolean
  isDisabled?: boolean
  onClick?: () => void
}

export const RichTextEditorMenu = ({
  icon,
  isActive,
  isDisabled,
  onClick = () => {},
  ...props
}: RichTextEditorMenuProps) => {
  const {
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleStrike,
    toggleBulletList,
    toggleOrderedList,
    focus,
  } = useCommands()
  const active = useActive()

  return (
    <RichTextEditorMenuStyled>
      <Space>
        <RichTextEditorButton
          icon={<BoldOutlined />}
          isActive={active.bold()}
          isDisabled={toggleBold.enabled() === false}
          onClick={() => {
            toggleBold()
            focus()
          }}
        />
        <RichTextEditorButton
          icon={<ItalicOutlined />}
          isActive={active.italic()}
          isDisabled={toggleItalic.enabled() === false}
          onClick={() => {
            toggleItalic()
            focus()
          }}
        />
        <RichTextEditorButton
          icon={<UnderlineOutlined />}
          isActive={active.underline()}
          isDisabled={toggleUnderline.enabled() === false}
          onClick={() => {
            toggleUnderline()
            focus()
          }}
        />
        <RichTextEditorButton
          icon={<StrikethroughOutlined />}
          isActive={active.strike()}
          isDisabled={toggleStrike.enabled() === false}
          onClick={() => {
            toggleStrike()
            focus()
          }}
        />
        <Divider type='vertical' />
        <RichTextEditorButton
          icon={<OrderedListOutlined />}
          isActive={active.orderedList()}
          isDisabled={toggleOrderedList.enabled() === false}
          onClick={() => {
            toggleOrderedList()
            focus()
          }}
        />
        <RichTextEditorButton
          icon={<UnorderedListOutlined />}
          isActive={active.bulletList()}
          isDisabled={toggleBulletList.enabled() === false}
          onClick={() => {
            toggleBulletList()
            focus()
          }}
        />
      </Space>
    </RichTextEditorMenuStyled>
  )
}

RichTextEditorMenu.displayName = 'RichTextEditorMenu'

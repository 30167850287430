import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { SelectProps, Select, Divider, Spin, Space, Button } from 'antd'
import React, { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { SimpleSearchSelectStyled } from './styles'
import { filterArrayByObjId } from '../../utils'
import { truncate } from 'fs'

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  onAddClick?: (searchString?: string) => void
  onBlurTriggered?: (searchString?: string, e?: any) => void
  optionList?: ValueType[]
  debounceTimeout?: number
  createButtonVisible?: boolean
  createButtonText?: string
  emptyText?: any
  allowAutoFocus?: boolean
}

export const SimpleSearchSelect = React.forwardRef(
  <
    ValueType extends {
      key?: string
      label: React.ReactNode
      value: string | number
    } = any
  >(
    {
      fetchOptions,
      debounceTimeout = 500,
      createButtonVisible,
      createButtonText,
      emptyText = 'No results found',
      optionList = [],
      onAddClick,
      onBlurTriggered,
      allowAutoFocus,
      ...props
    }: DebounceSelectProps<ValueType>,
    ref: React.Ref<any>
  ) => {
    const [fetching, setFetching] = useState(false)
    const [options, setOptions] = useState<ValueType[]>([])
    const [searchStringValue, setSearchStringValue] = useState<string>()
    const fetchRef = useRef(0)

    React.useEffect(() => {
      if (optionList.length) {
        setOptions(optionList)
      }
    }, [optionList])

    const debounceFetcher = useMemo(() => {
      const loadOptions = (value: string) => {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setOptions([])
        setFetching(true)
        setSearchStringValue(value)

        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }

          setOptions(newOptions)
          setFetching(false)
        })
      }

      return debounce(loadOptions, debounceTimeout)
    }, [fetchOptions, debounceTimeout])

    return (
      <SimpleSearchSelectStyled>
        <Select
          {...props}
          ref={ref}
          showAction={['focus']}
          autoFocus={allowAutoFocus}
          showSearch
          filterOption={false}
          onSearch={debounceFetcher}
          onClear={() => debounceFetcher('')}
          notFoundContent={
            <div
              style={{ width: '100%', textAlign: 'center', padding: '2em 1em' }}
            >
              <Space>
                {fetching ? <LoadingOutlined /> : <div>{emptyText}</div>}
              </Space>
            </div>
          }
          options={options}
          onBlur={(e) =>
            onBlurTriggered && onBlurTriggered(searchStringValue, e)
          }
          dropdownRender={(menu) => {
            return (
              <>
                {menu}
                {createButtonVisible && !fetching && (
                  <>
                    <Divider style={{ margin: '8px 0' }} />
                    <Button
                      style={{ width: '100%' }}
                      type='text'
                      icon={<PlusOutlined />}
                      onClick={() => {
                        onAddClick && onAddClick(searchStringValue)
                        setSearchStringValue('')
                      }}
                    >
                      {createButtonText || 'Create'}
                    </Button>
                  </>
                )}
              </>
            )
          }}
        />
      </SimpleSearchSelectStyled>
    )
  }
)

SimpleSearchSelect.displayName = 'SimpleSearchSelect'

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const RecentUploadsTableStyled = styled.div`
  .ant-tag {
    margin: 0;
    min-width: 100px;
  }
  .ant-table-row:hover {
    // cursor: pointer;
  }
`

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const AppNavigationStyled = styled.div`
  font-size: ${rem(16)};

  background-color: ${(props) => props.theme.colors.white};

  grid-area: navigation;
  height: 100%;
`

export const createUsageSlice = (set: any) => ({
  materials: 0,
  estimates: 0,
  plan: null,
  setPlan: (plan: string) => set({ plan }),
  setMaterials: (count: number) => set({ materials: count }),
  addMaterial: () => set((state: any) => ({ materials: state.materials + 1 })),
  setEstimates: (count: number) => set({ estimates: count }),
  addEstimate: () => set((state: any) => ({ estimates: state.estimates + 1 })),
  removeMaterial: () =>
    set((state: any) => ({ materials: state.materials - 1 })),
  setMaterialsEstimatesPlan: (
    materials: number,
    estimates: number,
    plan?: string | null
  ) => set({ materials, estimates, plan }),
})

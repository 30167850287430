import { LoadingOutlined } from '@ant-design/icons'
import { Card, Space, Row, Col, Skeleton } from 'antd'
import * as React from 'react'

import { SkeletonLoaderStyled } from './styles'
export interface SkeletonLoaderProps {
  pageType?: 'detail' | 'table'
  rows?: number
}

export const SkeletonLoader = ({ pageType, rows = 5 }: SkeletonLoaderProps) => {
  const renderTableRowSkeleton = () => {
    let rowCount = rows
    let rowComponents: any = []
    while (rowCount > 0) {
      rowComponents.push(
        <Row gutter={24} key={`loading-row-${rowCount}`}>
          <Col flex='1 0'>
            <Skeleton loading={true} active paragraph={false} />
          </Col>
          <Col flex='1 0'>
            <Skeleton loading={true} active paragraph={false} />
          </Col>
          <Col flex='1 0'>
            <Skeleton loading={true} active paragraph={false} />
          </Col>
          <Col flex='1 0'>
            <Skeleton loading={true} active paragraph={false} />
          </Col>
        </Row>
      )
      rowCount--
    }
    return rowComponents
  }
  const renderTableSkeleton = () => {
    return (
      <Card size='small' title={<Skeleton loading active paragraph={false} />}>
        <Space direction='vertical' style={{ width: '100%' }}>
          {renderTableRowSkeleton()}
        </Space>
      </Card>
    )
  }
  const renderDetailSkeleton = () => {
    return (
      <>
        <Skeleton paragraph={{ rows: 4, width: [400, 400, 400, 400] }} active />
        <Skeleton active />
        <Skeleton active />

        <Skeleton.Button active block shape={'square'} />
      </>
    )
  }

  return (
    <SkeletonLoaderStyled>
      {pageType === 'table' && renderTableSkeleton()}
      {pageType === 'detail' && renderDetailSkeleton()}
    </SkeletonLoaderStyled>
  )
}

SkeletonLoader.displayName = 'SkeletonLoader'

import {
  PriceDetailFragment,
  useCreatePriceMutation,
  useGetDistributorsQuery,
  useCreateDistributorMutation,
  useUpdatePriceMutation,
} from '../../graphql/_generated-hooks'
import { Form, Drawer, DrawerProps, message } from 'antd'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormMoney,
  ProFormDatePicker,
} from '@ant-design/pro-components'
import React, { useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'

import { PriceCreateFormDrawerStyled } from './styles'
import { setApiFormErrors, roundMoney, roundNumber } from '../../utils'
import {
  DistributorSelectOrCreate,
  DistributorSelectOrCreateSchema,
} from '../DistributorSelectOrCreate'
import { DistributorSearchSelect } from '../DistributorSearchSelect'

const DRAWER_WIDTH = 450

export interface PriceCreateFormDrawerProps extends DrawerProps {
  productId: string
  price?: PriceDetailFragment
  newDistributor?: any
  onPriceSave?(price: any): void
  onCreateNewDistributor?: () => void
}

export interface PriceFormSchema {
  id: string
  name: string
  description: string
  unit_amount: number
  distributor: {
    value: string
    label: string
  }
}

export const PriceCreateFormDrawer = ({
  productId,
  price,
  newDistributor,
  onPriceSave = () => {},
  onCreateNewDistributor = () => {},
  ...props
}: PriceCreateFormDrawerProps) => {
  const [form] = Form.useForm<any>()

  const [createPrice, { data, loading: creatingPrice, error }] =
    useCreatePriceMutation()
  const [updatePrice, { loading: updatingPrice }] = useUpdatePriceMutation()
  const [createDistributor, { loading: createDistributorLoading }] =
    useCreateDistributorMutation()

  React.useEffect(() => {
    if (newDistributor && newDistributor.id) {
      form.setFieldValue('distributor', {
        value: newDistributor.id,
        label: newDistributor.name,
      })
    }

    if (price) {
      form.setFieldsValue({
        unit_amount: price?.unit_amount ? price?.unit_amount / 100 : undefined,
        ...(price?.distributor &&
          !newDistributor?.id && {
            distributor: {
              value: price?.distributor?.id,
              label: price?.distributor?.name,
            },
          }),
        ...(price?.invoicedAt && { invoicedAt: new Date(price?.invoicedAt) }),
      })
    }
  }, [price, newDistributor])

  const handleSubmitPrice = async (values: PriceFormSchema) => {
    if (price) {
      handleEditPrice(values)
    } else {
      handleCreatePrice(values)
    }
  }
  const handleEditPrice = async (values: PriceFormSchema) => {
    if (!price || !price.id) return message.error('No price id')
    const { unit_amount, distributor, ...rest } = values

    updatePrice({
      variables: {
        id: price.id,
        input: {
          // @ts-ignore
          unit_amount: roundMoney(roundNumber(unit_amount) * 100),
          ...(distributor?.value && { distributorId: distributor.value }),
          ...rest,
        },
      },
    }).then(({ data }: any) => {
      const { updatePrice } = data
      handleOnSaveSuccess(updatePrice.data)
    })
  }
  const handleCreatePrice = async (formValues: PriceFormSchema) => {
    const values = await form.validateFields()

    const { unit_amount, distributor, ...rest } = values

    createPrice({
      variables: {
        input: {
          productId,
          // @ts-ignore
          unit_amount: roundMoney(roundNumber(unit_amount) * 100),
          ...(distributor?.value && { distributorId: distributor.value }),
          ...rest,
        },
      },
    }).then(({ data }: any) => {
      const { createPrice } = data
      handleOnSaveSuccess(createPrice.data)
    })
  }
  const handleOnSaveSuccess = (data: any) => {
    onPriceSave(data)
    form.resetFields()
    message.success('Successfully saved price')
  }

  return (
    <PriceCreateFormDrawerStyled>
      <DrawerForm<PriceFormSchema>
        title={`${price ? 'Edit' : 'Create'} Price`}
        form={form}
        open={props.open}
        autoFocusFirstInput
        initialValues={{
          invoicedAt: dayjs(),
        }}
        drawerProps={{
          ...props,
          width: DRAWER_WIDTH,
          getContainer: false,
          onClose: (e) => {
            props.onClose && props.onClose(e)
            form.resetFields()
          },
        }}
        onFinish={handleSubmitPrice}
        submitter={{
          searchConfig: {
            submitText: 'Save',
            resetText: 'Cancel',
          },
          submitButtonProps: {
            loading: creatingPrice || updatingPrice || createDistributorLoading,
            'data-testid': 'price-form-submit-button',
          },
          resetButtonProps: {
            'data-testid': 'price-form-cancel-button',
          },
        }}
      >
        <ProForm.Group>
          <ProFormDatePicker
            name='invoicedAt'
            label='Invoice Date'
            placeholder='00/00/0000'
            width='xl'
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormMoney
            name='unit_amount'
            width='xl'
            label='Unit Cost'
            placeholder='0.00'
          />
        </ProForm.Group>
        <DistributorSearchSelect
          formItemProps={{
            label: 'Vendor',
            name: 'distributor',
            rules: [
              {
                required: true,
              },
            ],
          }}
          onAddClick={onCreateNewDistributor}
          recentlyCreatedDistributor={
            newDistributor
              ? [{ value: newDistributor.id, label: newDistributor.name }]
              : []
          }
        />
      </DrawerForm>
    </PriceCreateFormDrawerStyled>
  )
}

PriceCreateFormDrawer.displayName = 'PriceCreateFormDrawer'

import { ThunderboltFilled } from '@ant-design/icons'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { LogoStyled } from './styles'
export interface LogoProps {
  size?: string
}

export const Logo = ({ ...props }: LogoProps) => {
  return (
    <LogoStyled {...props}>
      {/* <ThunderboltFilled style={{ fontSize: props.size == 'sm' ? 25 : 40 }} /> */}
      <img src='/logo_medium.png' alt='logo' />
    </LogoStyled>
  )
}

Logo.displayName = 'Logo'

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const AppViewStyled = styled.div`
  font-size: ${rem(14)};
  min-height: 100vh;

  // .layout {
  //   min-height: 100vh;
  //   max-height: 100vh;
  // }

  .layout-navigation {
    overflow: auto;
    height: 100vh;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 1px 1px #e6e6e6;
  }

  // .layout-content {
  //   overflow: auto;
  //   position: absolute;
  //   left: 175px;
  //   right: 0;
  //   width: auto !important;
  //   top: 0;
  //   bottom: 0;
  // }
`

import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const AppViewContainerStyled = styled.div`
  font-size: ${rem(16)};
  position: relative;

  width: 100vw;
  height: 100vh;

  display: grid;

  grid-template-areas: 'navigation content';
  grid-template-columns: 70px auto;

  overflow: hidden;
`

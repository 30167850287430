import { rem, tint } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const NavigationStyled = styled.div`
  font-size: ${rem(16)};

  width: 100%;

  display: flex;
  flex-direction: column;
  height: 100%;

  .nav-item {
    width: 175px;
    height: 60px;
    padding: 0 16px;

    display: flex;

    align-items: center;
    justify-content: left;

    color: ${(props) => props.theme.colors.textPrimary};

    &.active {
      color: ${(props) => props.theme.colors.accent};
    }
  }

  .nav-centered {
    display: flex;

    &:hover {
      .ant-typography {
        opacity: 0.8;
      }
    }
  }

  .ant-menu-title-content {
    font-size: ${rem(16)};
  }
`

import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Pagination } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import debounce from 'lodash/debounce'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'

import { useGetCustomersQuery } from '../../graphql/_generated-hooks'
import { PageTitle } from '../../components/PageTitle'
import { CustomersTable } from '../../components/CustomersTable'
import { CustomerFormDrawer } from '../../components/CustomerFormDrawer'
import { CustomerListViewStyled } from './styles'

const { Search } = Input
const defaultPageSize = 10

export interface CustomerListViewProps {}

export const CustomerListView = ({ ...props }: CustomerListViewProps) => {
  const [createCustomer, setCreateCustomer] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState<any>(null)
  const [customerData, setCustomerData] = React.useState<any>([])

  let navigate = useNavigate()

  const {
    data: { customers = { data: [], count: 0 } } = {
      customers: { data: [], count: 0 },
    },
    loading: isLoadingCustomers,
    refetch: refetchCustomers,
    updateQuery: updateCustomers,
  } = useGetCustomersQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: { search: '' },
    },
    onCompleted: ({ customers }) => {
      setCustomerData(customers?.data || [])
    },
  })

  const handleAddCustomer = () => {
    setCreateCustomer(true)
  }
  const handleCustomerFormClose = () => {
    setCreateCustomer(false)
  }

  const handleRowClick = (row: any, event: any) => {
    event.preventDefault()
    navigate(`/customers/${row.id}`, {})
  }

  const onSearch = (value: string) => {
    setSearchQuery(value)
    refetchCustomers({
      input: {
        limit: defaultPageSize,
        search: value,
      },
    })
  }
  const onSort = (field: string, order: string) => {
    refetchCustomers({
      // @ts-ignore
      input: {
        limit: defaultPageSize,
        ...(order && {
          sort: {
            field,
            order: 'ascend' == order ? 'desc' : 'asc',
          },
        }),
      },
    })
  }
  const onPageChange = (page: number, pageSize?: number) => {
    refetchCustomers({
      input: {
        limit: pageSize,
        offset: page * pageSize! - pageSize!,
        ...(searchQuery && { search: searchQuery }),
      },
    })
  }

  return (
    <CustomerListViewStyled>
      <Content style={{ padding: '2em' }}>
        <Row align='middle'>
          <Col flex='1 0'>
            <PageTitle title='Customers' subtitle='Manage customers' />
          </Col>
          <Col flex='1 0' style={{ textAlign: 'right' }}>
            <Button
              onClick={handleAddCustomer}
              data-testid='add-customer-button'
            >
              <PlusOutlined />
              New Customer
            </Button>
          </Col>
        </Row>
      </Content>
      <Content style={{ padding: '0 2em 2em 2em' }}>
        <Search
          placeholder='Search'
          onSearch={onSearch}
          onChange={debounce((e) => {
            const value = e.target.value
            if (value && value.length > 1) {
              onSearch(value)
            } else if (value === '') {
              onSearch('')
            }
          }, 300)}
          allowClear={true}
          style={{ width: 350, float: 'right' }}
          data-testid='customer-search-input'
        />
        <CustomersTable
          data={customerData}
          tableProps={{ loading: isLoadingCustomers }}
          onCustomerClick={handleRowClick}
          onCustomerDelete={() => {}}
          onChange={debounce((pagination, filters, sorter) => {
            onSort(sorter.field, sorter.order)
          }, 400)}
          selectedId={''}
        />
        <Pagination
          defaultCurrent={1}
          defaultPageSize={defaultPageSize}
          showSizeChanger={false}
          total={customers?.count}
          onChange={onPageChange}
          style={{ float: 'right' }}
        />
      </Content>

      <CustomerFormDrawer
        open={createCustomer}
        onClose={handleCustomerFormClose}
        onCustomerSave={() => {
          refetchCustomers()
          handleCustomerFormClose()
        }}
      />
    </CustomerListViewStyled>
  )
}

CustomerListView.displayName = 'CustomerListView'

import { UserOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  message,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd'
import { UserProfile } from '@clerk/clerk-react'
import { Content } from 'antd/lib/layout/layout'
import * as React from 'react'
import debounce from 'lodash/debounce'

// import { ImageUploader } from '../../components/ImageUploader'
// import {
//   PresignedUpload,
//   PresignedUploadProps,
// } from '../../components/PresignedUpload'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { PageTitle } from '../../components/PageTitle'
import { UserAvatar } from '../../components/UserAvatar'
import {
  useGetMeQuery,
  useUpdateMeMutation,
} from '../../graphql/_generated-hooks'
import { PreferenceUserProfileViewStyled } from './styles'

export interface PreferenceUserProfileViewProps {}

export interface UserProfileFormSchema {}

// const ProfilePictureUpload = ({
//   me,
//   value,
//   onChange,
// }: {
//   value?: string
//   onChange?: (fileUrl: string) => void
//   me: any
// }) => {
//   return (
//     <PresignedUpload value={value} onChange={onChange}>
//       {({ isUploading }) => (
//         <Space>
//           <UserAvatar size={64} user={me} loading={isUploading} />

//           {!isUploading && <Button type='link'>Update profile image</Button>}
//         </Space>
//       )}
//     </PresignedUpload>
//   )
// }

export const PreferenceUserProfileView = ({
  ...props
}: PreferenceUserProfileViewProps) => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const { data: { me } = {}, loading } = useGetMeQuery()
  const [updateMe, { loading: updateProfileLoading }] = useUpdateMeMutation()

  React.useEffect(() => {
    if (!me) return

    form.setFieldsValue({
      given_name: me.given_name,
      family_name: me.family_name,
      phone: me.phone,
    })
  }, [me])

  const initialValues = {
    given_name: '',
    family_name: '',
    phone: '',
  }

  const handleOnFinish = (formValues: any) => {
    try {
      updateMe({
        variables: {
          input: {
            ...formValues,
          },
        },
      })
      messageApi.success('Success: Information saved successfully!')
    } catch (error) {
      messageApi.error('Error updating profile information')
    }
  }

  return (
    <PreferenceUserProfileViewStyled>
      {contextHolder}
      <AnimatedComponent>
        <Content style={{ padding: '2em' }}>
          <PageTitle title='User Profile' subtitle='Update profile settings.' />

          <Row gutter={[12, 0]} style={{ marginTop: '2em' }}>
            <Col span={24}>
              <UserProfile />
            </Col>
          </Row>

          {/* <Spin spinning={loading}>
          <Card style={{ marginTop: '2em', maxWidth: '800px' }}>
            <Form
              form={form}
              initialValues={initialValues}
              layout='vertical'
              // TODO hack since we aren't properly checking dirty form state
              onFinish={debounce(handleOnFinish, 500)}
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='given_name'
                    label='First name'
                    rules={[
                      {
                        required: true,
                        message: 'First name is required.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='family_name'
                    label='Last name'
                    rules={[
                      {
                        required: true,
                        message: 'Last name is required.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='phone'
                    label='Phone number'
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Button
                type='primary'
                htmlType='submit'
                loading={updateProfileLoading}
              >
                Save changes
              </Button>
            </Form>
          </Card>
        </Spin> */}
        </Content>
      </AnimatedComponent>
    </PreferenceUserProfileViewStyled>
  )
}

PreferenceUserProfileView.displayName = 'PreferenceUserProfileView'

import {
  LoadingOutlined,
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Space, Typography, Tag, Tooltip } from 'antd'
import * as React from 'react'

import { AutoSavingLabelStyled } from './styles'
export interface AutoSavingLabelProps {
  isSaving?: boolean
  errorString?: string
}

export const AutoSavingLabel = ({
  isSaving,
  errorString,
  ...props
}: AutoSavingLabelProps) => {
  const getSavingElement = () => (
    <Space>
      <LoadingOutlined />
      {/* <Typography.Text>Saving</Typography.Text> */}
    </Space>
  )
  const getSavedElement = () => (
    <Space>
      <CheckCircleFilled />
      {/* <Typography.Text>Saved</Typography.Text> */}
    </Space>
  )
  const getErrorElement = (errorString: string) => (
    // <Tooltip
    //   title='Refresh to ensure further changes are
    //     saved.'
    // >
    <Space className='error-text'>
      <ExclamationCircleOutlined />
      <Typography.Text>An error occurred saving draft.</Typography.Text>
    </Space>
    // </Tooltip>
  )
  return (
    <AutoSavingLabelStyled {...props}>
      <Space>
        {isSaving && getSavingElement()}
        {!isSaving && !errorString && getSavedElement()}
        {!isSaving && errorString && getErrorElement(errorString)}
      </Space>
    </AutoSavingLabelStyled>
  )
}

AutoSavingLabel.displayName = 'AutoSavingLabel'

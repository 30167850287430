import {
  Remirror,
  EditorComponent,
  useRemirror,
  useHelpers,
  useDocChanged,
  useRemirrorContext,
} from '@remirror/react'
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  StrikeExtension,
  OrderedListExtension,
  BulletListExtension,
  PlaceholderExtension,
} from 'remirror/extensions'
import { CountExtension } from '@remirror/extension-count'
import * as React from 'react'

import { RichTextEditorStyled } from './styles'
import { RichTextEditorMenu } from './RichTextEditorMenu'

export interface RichTextEditorProps {
  initialContent?: any
  onChange?: (value: any) => void
  onChangeHtml?: (value: any) => void
}

export interface EditorRef {
  setContent: (content: any) => void
}

const INPUT_MAXIMUM = 10000

const ImperativeHandle = React.forwardRef(
  (_: unknown, ref: React.Ref<EditorRef>) => {
    const { setContent } = useRemirrorContext({
      autoUpdate: true,
    })

    // Expose content handling to outside
    React.useImperativeHandle(ref, () => ({ setContent }))

    return <></>
  }
)
ImperativeHandle.displayName = 'ImperativeHandle'

export const RichTextEditor = ({
  initialContent = '',
  onChange = () => {},
  onChangeHtml = () => {},
  ...props
}: RichTextEditorProps) => {
  const editorRef = React.useRef<EditorRef | null>(null)
  const { manager, state, getContext } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new UnderlineExtension(),
      new StrikeExtension(),
      new OrderedListExtension(),
      new BulletListExtension(),
      new PlaceholderExtension({ placeholder: 'Enter work scope information' }),
      new CountExtension({
        maximum: INPUT_MAXIMUM,
      }),
    ],
    content: initialContent,
    selection: 'start',
    stringHandler: 'html',
  })

  React.useEffect(() => {
    if (initialContent) {
      editorRef.current!.setContent(initialContent)
    }
  }, [initialContent])

  // const handleEditorChange = React.useCallback(({ state }: any) => {
  //   const count = state.doc.content.size
  //   if (count > INPUT_MAXIMUM) {
  //     // Here you can handle the logic when the count exceeds the maximum
  //     // E.g., showing a notification or preventing further input
  //     console.log('Maximum character count reached.')
  //   }
  // }, [])

  const hooks = [
    () => {
      const { getJSON, getHTML } = useHelpers()
      const handleSaveShortcut = React.useCallback(
        ({ state }: any) => {
          const stateObj = { ...getJSON(state), html: '' }
          stateObj.html = getHTML(state)

          onChange(stateObj)
          return true // Prevents any further key handlers from being run.
        },
        [getJSON, getHTML]
      )

      useDocChanged(handleSaveShortcut)
    },
  ]

  return (
    <RichTextEditorStyled>
      <Remirror
        manager={manager}
        initialContent={state}
        hooks={hooks}
        // onChange={handleEditorChange}
      >
        <RichTextEditorMenu />
        <EditorComponent />
        <ImperativeHandle ref={editorRef} />
      </Remirror>
    </RichTextEditorStyled>
  )
}

RichTextEditor.displayName = 'RichTextEditor'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  SelectProps,
  Select,
  Divider,
  Spin,
  Space,
  Button,
  Typography,
} from 'antd'
import React, { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { TaxSelectStyled } from './styles'

export interface TaxSelectProps<ValueType = any> {
  taxOptions?: ValueType[]
  onAddClick?: () => void
  createButtonVisible?: boolean
  createButtonText?: string
  loading?: boolean
}

export const TaxSelect = <
  ValueType extends {
    key?: string
    label: React.ReactNode
    value: string | number
  } = any
>({
  taxOptions = [],
  onAddClick,
  createButtonVisible,
  createButtonText,
  ...props
}: TaxSelectProps<ValueType>) => {
  const [fetching, setFetching] = useState(false)

  return (
    <TaxSelectStyled>
      <Select
        defaultActiveFirstOption={true}
        // defaultValue={taxOptions[0]}
        {...props}
        style={{ width: '100%' }}
        options={taxOptions}
        data-testid='tax-select'
        dropdownRender={(menu) => (
          <>
            {menu}
            {createButtonVisible && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Button
                  style={{ width: '100%' }}
                  type='text'
                  icon={<PlusOutlined />}
                  onClick={onAddClick}
                >
                  {createButtonText || 'Create'}
                </Button>
              </>
            )}
          </>
        )}
      />
    </TaxSelectStyled>
  )
}

TaxSelect.displayName = 'TaxSelect'

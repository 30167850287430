import {
  CloseOutlined,
  ProfileOutlined,
  MoreOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import {
  ConfigProvider,
  Button,
  Typography,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Dropdown,
  Modal,
} from 'antd'
import type { FormInstance } from 'antd/es/form'
import * as React from 'react'

import { EstimatesFormLaborStyled } from './styles'
import {
  calculateLaborTotal,
  formatMoney,
  roundMoney,
  dollarInputFormatter,
  dollarInputParser,
  roundNumber,
} from '../../../utils'
import { useAppStore } from '../../../stores/appStore'
import { QUANTITY_PRECISION } from '../../../config/default'
import { get } from 'lodash'

import { ListButtonBar } from '../../ListButtonBar'

const defaultLaborBreakdown = [
  {
    label: '',
    quantity: 1,
    rate: 0,
  },
]

export interface EstimatesFormLaborProps {
  form?: FormInstance
  initialValues?: any
  onChange?: (value: string | null) => void
}

export const EstimatesFormLabor = ({
  form = {} as FormInstance,
  initialValues = [],
  onChange = () => {},
  ...props
}: EstimatesFormLaborProps) => {
  const [showLaborBreakdown, setShowLaborBreakdown] = React.useState<any>(
    initialValues?.length >= 1
  )

  const laborTotal = useAppStore((state: any) => state.estimateLaborTotal)

  React.useEffect(() => {
    if (initialValues?.length >= 1) {
      setShowLaborBreakdown(true)
    }
  }, [initialValues])

  const handleBreakdownItemChange = (value: any, fields: any) => {
    let values = form.getFieldValue('labor_breakdown')
    let rate = form.getFieldValue('labor_rate')
    let totalQuantity = 0
    // let totalRate: number = 0

    let updatedValues = values.map((item: any, index: number) => {
      let total = calculateLaborTotal(rate, item.quantity)

      totalQuantity += item.quantity
      // totalRate += item.rate
      return {
        ...item,
        rate: roundNumber(rate), // remove long decimals if quantity is decimals
        total,
      }
    })

    form.setFieldValue('labor_breakdown', updatedValues)
    form.setFieldValue('labor_quantity', totalQuantity)
    // form.setFieldValue(
    //   'labor_rate',
    //   roundMoney(roundNumber(totalRate / totalQuantity))
    // )

    onChange(values)
  }

  const calculateRowTotal = (field: any) => {
    const labor_breakdown = form.getFieldValue('labor_breakdown')
    if (
      !labor_breakdown ||
      !labor_breakdown.length ||
      !labor_breakdown[field.name]
    )
      return formatMoney(0)

    const { total } = labor_breakdown[field.name]
    return formatMoney(total)
  }

  const getNewRowData = (quantity: number = 0) => {
    return {
      ...defaultLaborBreakdown[0],
      quantity,
      rate: form.getFieldValue('labor_rate'),
    }
  }
  const handleShowLaborBreakdown = () => {
    setShowLaborBreakdown(!showLaborBreakdown)
    form.setFieldValue('labor_breakdown', [
      getNewRowData(form.getFieldValue('labor_quantity')),
    ])
    handleBreakdownItemChange(null, null)
  }

  const handleRemoveAll = () => {
    form.setFieldValue('labor_breakdown', null)
    setShowLaborBreakdown(false)
    onChange(null)
  }
  const handleReset = () => {
    Modal.confirm({
      title: `Reset All?`,
      content: 'Are you sure you want to reset the labor breakdown?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRemoveAll()
      },
    })
  }

  return (
    <EstimatesFormLaborStyled {...props} className='simple-form-input-styles'>
      <ConfigProvider
        theme={{
          token: {
            controlHeight: 24,
          },
        }}
      >
        <Row gutter={16}>
          <Col span={11}>
            <p className='list-header'>Label</p>
          </Col>
          <Col span={3}>
            <p className='list-header'>Quantity</p>
          </Col>
          <Col span={3}>
            <p className='list-header'>Rate</p>
          </Col>
          <Col span={3} offset={3}>
            <p className='list-header'>Total</p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={11}>
            <Form.Item name={'labor_label'}>
              <Input placeholder={'Labor'} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'labor_quantity'}>
              <InputNumber
                precision={QUANTITY_PRECISION}
                placeholder={'1'}
                style={{ width: '100%' }}
                onChange={onChange}
                disabled={showLaborBreakdown}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={'labor_rate'}>
              <InputNumber
                placeholder={'1'}
                decimalSeparator='.'
                style={{ width: '100%' }}
                onChange={onChange}
                formatter={dollarInputFormatter}
                parser={dollarInputParser}
                disabled={showLaborBreakdown}
              />
            </Form.Item>
          </Col>
          <Col span={3} offset={3}>
            <Typography.Text>{formatMoney(laborTotal)}</Typography.Text>
          </Col>
          <Col span={1}>
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    danger: showLaborBreakdown,
                    icon: showLaborBreakdown ? (
                      <CloseOutlined />
                    ) : (
                      <ProfileOutlined />
                    ),
                    label: showLaborBreakdown
                      ? 'Remove Breakdown'
                      : 'Breakdown Labor',
                    onClick: () => {
                      handleShowLaborBreakdown()
                    },
                  },
                ],
              }}
            >
              <Button type='text' icon={<MoreOutlined />} />
            </Dropdown>
          </Col>
        </Row>
        {showLaborBreakdown && (
          <>
            <Form.List
              initialValue={initialValues || [...defaultLaborBreakdown]}
              name='labor_breakdown'
            >
              {(fields, { add, move, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <Row gutter={16} key={field.key}>
                        <Col span={10} offset={1}>
                          <Form.Item
                            {...field}
                            key={field.key}
                            name={[field.name, 'label']}
                          >
                            <Input placeholder={'Labor'} />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item name={[field.name, 'quantity']}>
                            <InputNumber
                              precision={QUANTITY_PRECISION}
                              placeholder={'1'}
                              style={{ width: '100%' }}
                              onChange={(value) =>
                                handleBreakdownItemChange(value, fields)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          {/* <Form.Item name={[field.name, 'rate']}>
                            <InputNumber
                              placeholder={'1'}
                              decimalSeparator='.'
                              style={{ width: '100%' }}
                              formatter={dollarInputFormatter}
                              parser={dollarInputParser}
                              onChange={(value) =>
                                handleBreakdownItemChange(value, fields)
                              }
                            />
                          </Form.Item> */}
                          {formatMoney(form.getFieldValue('labor_rate'))}
                        </Col>
                        <Col span={3} offset={3}>
                          <Typography.Text>
                            {calculateRowTotal(field)}
                          </Typography.Text>
                        </Col>
                        <Col span={1}>
                          <Button
                            type='text'
                            danger
                            icon={<CloseOutlined />}
                            onClick={() => {
                              remove(index)

                              if (fields.length === 1) {
                                handleRemoveAll()
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <ListButtonBar
                        onAddClick={() => {
                          add({ ...getNewRowData(0) })
                          handleBreakdownItemChange(null, null)
                        }}
                        onClearAllClick={() => handleReset()}
                      />
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
          </>
        )}
      </ConfigProvider>
    </EstimatesFormLaborStyled>
  )
}

EstimatesFormLabor.displayName = 'EstimatesFormLabor'

import {
  LoadingOutlined,
  PlusOutlined,
  ToolOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  LockOutlined,
  LockFilled,
  UnlockFilled,
} from '@ant-design/icons'
import {
  DrawerForm,
  ProForm,
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormDigit,
  ProFormDependency,
  ProFormList,
} from '@ant-design/pro-components'
import {
  useCreateProductListMutation,
  useUpdateProductListMutation,
  ProductListDetailFragment,
  ProductTypeFieldInput,
  useGetProductsQuery,
} from '../../graphql/_generated-hooks'
import {
  Button,
  Form,
  message,
  Radio,
  Row,
  Col,
  Input,
  Space,
  Modal,
  InputNumber,
  Checkbox,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import React, { useMemo, useRef, useState } from 'react'

import { QUANTITY_PRECISION, MONEY_PRECISION } from '../../config/default'
import { setApiFormErrors } from '../../utils'
import { MaterialListFormDrawerStyled } from './styles'

import { MaterialListBuilder } from '../../components/MaterialListBuilder'
import { ListButtonBar } from '../../components/ListButtonBar'
import { SimpleSearchSelect } from '../../components/SimpleSearchSelect'
import { ServiceItemBadge } from '../../components/ServiceItemBadge'
import { MaterialSelectItemRender } from '../../components/MaterialSelectItemRender'
import { StringDecoder } from 'string_decoder'

export interface MaterialListFormDrawerProps {
  onMaterialListCreated?: (data: any) => void
  onClose?: () => void
  open?: boolean
  edit?: boolean
  initialValues?: Partial<ProductListDetailFragment> | null
}

export interface MaterialListFormSchema {
  name: string
  description: string
  unit_label?: string | null
  unit_amount_per_assembly?: number | null
  line_items?: any[]
}

const DEFAULT_LIST_ITEM = [{ type: 'good' }]

const getMaterialSelectItemRenderComponent = (item: any) => {
  return (
    <MaterialSelectItemRender title={item.name} subtitle={item.description} />
  )
}

export const MaterialListFormDrawer = ({
  onMaterialListCreated = (data: any) => {},
  onClose,
  open,
  edit,
  initialValues,
}: MaterialListFormDrawerProps) => {
  const [form] = Form.useForm<Partial<MaterialListFormSchema>>()
  const [messageApi, contextHolder] = message.useMessage()
  const [formLoading, setFormLoading] = React.useState(false)
  const [unitType, setUnitType] = React.useState<any>('')
  const [unitTypeLabel, setUnitTypeLabel] = React.useState('sqft')
  const [initialProductList, setInitialProductList] = React.useState<any>([])
  const [selectedCoverageItems, setSelectedCoverageItems] = React.useState<any>(
    {}
  )
  const [createMaterialList, { loading: createMaterialListLoading }] =
    useCreateProductListMutation()
  const [updateMaterialList, { loading: updateMaterialListLoading }] =
    useUpdateProductListMutation()

  const {
    data: { products = { data: [] } } = { products: { data: [] } },
    refetch: refetchProducts,
  } = useGetProductsQuery({
    variables: { input: { limit: 100 } },
    onCompleted: (data) => {
      setInitialProductList(data.products?.data)
    },
  })

  React.useEffect(() => {
    if (initialValues) {
      const { name, description, unit_amount_per_assembly, line_items } =
        initialValues

      setFormLoading(true)
      setTimeout(() => {
        form.setFieldsValue({
          name,
          // @ts-ignore
          unit_type: initialValues?.unit_type,
          unit_amount_per_assembly,
          unit_label: initialValues?.unit_label,
          ...(description && { description }),
          ...(line_items && {
            line_items: line_items
              .map((item: any) => ({
                ...item,
                ...(item.products &&
                  item.products.length && {
                    product: {
                      value: item.products[0].id,
                      label: getMaterialSelectItemRenderComponent(
                        item.products[0]
                      ),
                    },
                  }),
              }))
              .sort(
                (a: any, b: any) => a.metadata?.sequence - b.metadata?.sequence
              ),
          }),
        })

        let coverageMap: any = {}
        line_items?.forEach((item: any, index: number) => {
          let product = item.products?.[0]
          if (product?.coverage_item) {
            coverageMap[item?.metadata?.sequence] = item.id
          }
        })
        setSelectedCoverageItems(coverageMap)

        setUnitType(initialValues?.unit_type)

        setFormLoading(false)
      }, 250)
    }
  }, [initialValues])

  const formatLineItemForSave = (line_items: any[]) => {
    return line_items?.map((item, $index) => ({
      ...(item.id && { id: item.id }),
      name: item.name,
      markup: item.markup,
      quantity: item.quantity,
      type: item.type,
      lock_quantity: item.lock_quantity,
      coverage_rate: item.coverage_rate,
      calculate_wastage: item.calculate_wastage,
      productId: item.product?.value,
      metadata: { ...item.metadata, sequence: $index },
    }))
  }

  const handleSaveMaterialService = async (
    formValues: MaterialListFormSchema
  ) => {
    let values = { ...formValues }
    const { line_items, ...rest } = values

    if (edit && initialValues?.id) {
      updateMaterialList({
        variables: {
          id: initialValues?.id,
          input: {
            ...rest,
            line_items: formatLineItemForSave(line_items || []),
          },
        },
        onCompleted({ updateProductList }: any = {}) {
          onMaterialListCreated(updateProductList?.data)
          message.success('Successfully updated template')
        },
        onError(error, clientOptions) {
          messageApi.error('An error occurred. Please try again.')
          setApiFormErrors(error.graphQLErrors, form)
        },
      })
    } else {
      createMaterialList({
        variables: {
          input: {
            ...rest,
            line_items: formatLineItemForSave(line_items || []),
          },
        },
        onCompleted({ createProductList }: any = {}) {
          onMaterialListCreated(createProductList?.data)
          message.success('Successfully created template')
        },
        onError(error, clientOptions) {
          messageApi.error('An error occurred. Please try again.')
          setApiFormErrors(error.graphQLErrors, form)
        },
      })
    }
  }

  const handleFetchProducts = async (value: string): Promise<any> => {
    return refetchProducts({ input: { search: value, limit: 100 } }).then(
      ({ data }: any) => {
        return data?.products?.data?.map((item: any) => ({
          value: item.id,
          data: item,
          label: getMaterialSelectItemRenderComponent(item),
        }))
      }
    )
  }

  const handleRemoveAll = () => {
    form.setFieldValue('line_items', [...DEFAULT_LIST_ITEM])
  }

  const handleIsCoverageMaterial = (index: number) => {
    return selectedCoverageItems[index] && unitType == 'area'
  }

  const handleReset = () => {
    Modal.confirm({
      title: `Reset All?`,
      content: 'Are you sure you want to clear all materials?',
      okText: 'Confirm',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return handleRemoveAll()
      },
    })
  }

  const handleSelectUnitType = (e: any) => {
    setUnitType(e.target.value)

    if ((e.target.value = 'area')) {
      form.setFieldsValue({
        unit_amount_per_assembly: 200,
        unit_label: 'sqft',
      })
    }
  }

  const handleFormatOptionsList = React.useCallback(
    (data: any) => {
      return initialProductList.map((item: any) => ({
        value: item.id,
        data: item,
        label: getMaterialSelectItemRenderComponent(item),
      }))
    },
    [initialProductList] // Only update if the initialProductList changes
  )

  const handleOnSelectMateral = (materialData: any, index: any) => {
    const line_items = form.getFieldValue('line_items')
    const item = line_items[index]
    let item_updates = {}

    if (materialData.coverage_rate) {
      item_updates = {
        coverage_rate: materialData.coverage_rate,
        quantity: 1,
      }

      setSelectedCoverageItems({
        ...selectedCoverageItems,
        [index]: materialData.id,
      })
    } else {
      item_updates = {
        coverage_rate: 500,
        quantity: 1,
      }

      setSelectedCoverageItems({
        ...selectedCoverageItems,
        [index]: null,
      })
    }

    form.setFieldValue(['line_items', index], {
      ...item,
      ...item_updates,
      type: materialData.type,
    })
  }

  return (
    <MaterialListFormDrawerStyled>
      {contextHolder}
      <DrawerForm<MaterialListFormSchema>
        title={`Create new assembly`}
        form={form}
        open={open}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          maskClosable: false,
          onClose: () => {
            setUnitType('')
            onClose && onClose()
          },
          width: 1000,
          loading: formLoading,
        }}
        submitTimeout={2000}
        onFinish={handleSaveMaterialService}
        autoComplete='off'
        submitter={{
          searchConfig: {
            submitText: edit ? 'Update' : 'Create',
            resetText: 'Cancel',
          },
          submitButtonProps: {
            loading: createMaterialListLoading || updateMaterialListLoading,
          },
        }}
        initialValues={{
          unit_label: 'sqft',
          line_items: [...DEFAULT_LIST_ITEM],
          unit_type: initialValues?.unit_amount_per_assembly ? 'area' : 'each',
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <ProForm.Group>
              <ProFormText
                fieldProps={{
                  'data-1p-ignore': '',
                }}
                name='name'
                width='xl'
                rules={[
                  {
                    required: true,
                  },
                ]}
                label='Name'
                placeholder={'Panel Upgrade Material List'}
              />
            </ProForm.Group>
          </Col>
          <Col span={12}>
            <Form.Item label='Assembly Unit Type' name='unit_type'>
              <Radio.Group buttonStyle='solid' onChange={handleSelectUnitType}>
                <Radio.Button value='each'>Per Unit</Radio.Button>
                <Radio.Button value='area'>By Area</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <ProForm.Group>
              <ProFormTextArea
                width={952}
                name='description'
                label='Description'
                placeholder={
                  'This is a description of the assembly. It can be as long as you want.'
                }
              />
            </ProForm.Group>
          </Col>
        </Row>

        {/* <MaterialListBuilder form={form} /> */}

        <Row gutter={24} style={{ marginBottom: '2em' }}>
          <Col span={unitType == 'area' ? 10 : 15}>
            <Typography.Text strong>Item</Typography.Text>
          </Col>
          <Col span={4}>
            <Space>
              <Typography.Text strong>Quantity</Typography.Text>
              {unitType != 'area' && (
                <Tooltip title='Locked quantities will not change based on the assembly amount when added to an estimate.'>
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </Space>
          </Col>
          <Col span={4}>
            <Typography.Text strong>Markup</Typography.Text>
          </Col>
          {unitType == 'area' && (
            <Col span={6}>
              <Space>
                <Typography.Text strong>Coverage Rate</Typography.Text>
                <Tooltip title='How much area will this item yield in a job.'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            </Col>
          )}
        </Row>
        <Form.List name='line_items'>
          {(fields, { add, move, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Row gutter={24} key={index}>
                    <Col span={unitType == 'area' ? 10 : 15}>
                      <ProFormDependency name={['line_items']}>
                        {({ line_items }) => {
                          return line_items[index].type === 'service' &&
                            line_items[index].custom ? (
                            <Form.Item
                              name={[field.name, 'name']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Missing labor label',
                                },
                              ]}
                            >
                              <Input
                                allowClear
                                addonBefore={'Labor'}
                                placeholder={'Label'}
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item
                              name={[field.name, 'product']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Item required',
                                },
                              ]}
                            >
                              <SimpleSearchSelect
                                labelInValue={true}
                                onSelect={(value: any, option: any) => {
                                  handleOnSelectMateral(option.data, index)
                                }}
                                placeholder='Search by item name'
                                fetchOptions={handleFetchProducts}
                                optionList={handleFormatOptionsList(products)}
                                suffixIcon={
                                  line_items[index].type === 'service' ? (
                                    <ServiceItemBadge />
                                  ) : (
                                    ''
                                  )
                                }
                              />
                            </Form.Item>
                          )
                        }}
                      </ProFormDependency>
                    </Col>
                    <Col span={4}>
                      <Space>
                        <Form.Item
                          name={[field.name, 'quantity']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing quantity',
                            },
                          ]}
                          initialValue={1}
                        >
                          <InputNumber
                            disabled={handleIsCoverageMaterial(index)}
                            precision={QUANTITY_PRECISION}
                            placeholder='Quantity'
                            style={{ width: '100%' }}
                          />
                        </Form.Item>

                        {unitType != 'area' && (
                          <Form.Item
                            name={[field.name, 'lock_quantity']}
                            valuePropName='checked'
                          >
                            <Switch
                              value={false}
                              checkedChildren={<LockFilled />}
                              unCheckedChildren={<UnlockFilled />}
                              defaultChecked
                            />
                          </Form.Item>
                        )}
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name={[field.name, 'markup']}
                        rules={[
                          {
                            required: false,
                            message: 'Missing markup',
                          },
                        ]}
                        initialValue={0}
                      >
                        <InputNumber
                          min={0}
                          placeholder='Markup'
                          formatter={(value) =>
                            `${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    {unitType == 'area' && (
                      <Tooltip
                        title={
                          handleIsCoverageMaterial(index)
                            ? 'Default coverage rate set on the material item.'
                            : null
                        }
                      >
                        <Col span={5} style={{ textAlign: 'center' }}>
                          <Form.Item name={[field.name, 'coverage_rate']}>
                            <InputNumber
                              placeholder='0'
                              min={1}
                              suffix={unitTypeLabel}
                              style={{ width: '100%' }}
                              disabled={handleIsCoverageMaterial(index)}
                            />
                          </Form.Item>
                        </Col>
                      </Tooltip>
                    )}

                    <Col span={1} offset={0}>
                      <Space>
                        {index > 0 && (
                          <Button
                            type='text'
                            danger
                            icon={<CloseOutlined />}
                            onClick={() => remove(index)}
                          />
                        )}
                      </Space>
                    </Col>
                  </Row>
                ))}

                <ProFormDependency name={['line_items']}>
                  {({ line_items }) => {
                    return (
                      !line_items ||
                      (!line_items.length && <span>No Items</span>)
                    )
                  }}
                </ProFormDependency>

                {fields.length < 25 && (
                  <Form.Item>
                    <ListButtonBar
                      showClearAll={true}
                      onAddClick={() => {
                        add({
                          type: 'good',
                        })
                      }}
                      onClearAllClick={() => handleReset()}
                    />
                  </Form.Item>
                )}
                {fields.length >= 25 && (
                  <div style={{ textAlign: 'right' }}>
                    <Typography.Text type='secondary'>
                      Max 25 items reached.
                    </Typography.Text>
                  </div>
                )}
              </>
            )
          }}
        </Form.List>
      </DrawerForm>
    </MaterialListFormDrawerStyled>
  )
}

MaterialListFormDrawer.displayName = 'MaterialListFormDrawer'

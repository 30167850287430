import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const DistributorSelectOrCreateStyled = styled.div`
  background: #f4f4f4;
  border-radius: 5px;
  padding: 1em;
  width: 100%;
`

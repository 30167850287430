import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons'
import { AnimatePresence, motion } from 'framer-motion'

export const FullpageAppLoader = ({ component }: any) => {
  return (
    <div className='page-layout'>
      <Content style={{ padding: '2em' }}>
        {/* <SkeletonLoader pageType='table' rows={10} /> */}

        <AnimatePresence>
          <motion.div
            style={{
              opacity: 1,
              position: 'fixed',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              top: 0,
              left: 0,
              zIndex: 9999,
              background: '#f9f7f5',
            }}
            exit={{ opacity: 0 }}
          >
            <LoadingOutlined />
          </motion.div>
        </AnimatePresence>
      </Content>
    </div>
  )
}
